import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Alert, Button, Col, Container, Form, ListGroup, Row } from "react-bootstrap";

import StepsHeader from "../components/StepsHeader";
import { loadStripe } from "@stripe/stripe-js";
import { AppState } from "../redux/types";
import { Elements } from "@stripe/react-stripe-js";
import OrderForm from "../components/OrderForm";
import React, { useState } from "react";
import { setCouponMethod } from "../redux/actions";
import api from "../utils/api-helper/api";
import StepsNotification from "../components/StepsNotification";
import { toast } from "react-toastify";
import { STRIPE_PUBLIC_KEY } from "../utils/constants";

const PlaceOrder = () => {
    const cart = useSelector((state: AppState) => state.cart);
    const user = useSelector((state: AppState) => state.user);
    const history = useHistory();
    const [coupon, setCoupon] = useState(cart.coupon);
    const dispatch = useDispatch();
    const [couponData, setCouponData] = useState(cart.couponData);
    const { list } = useSelector((state: AppState) => state.seniorHomes);
    const stripePromise = loadStripe(user.isLoggedIn ? user.stripePublicKey : (STRIPE_PUBLIC_KEY as string), { locale: "fi" });
    const shippingMethods = useSelector((state: AppState) => state.shippingMethods.shipping);
    const shipping_direct = shippingMethods?.find((el) => el.shippingMethod === "direct");
    const shipping_posti = shippingMethods?.find((el) => el.shippingMethod === "posti");

    // back button
    const handleClick = () => {
        history.push(`/maksutapa`);
    };

    const setCouponHandler = (value: any) => {
        api.get(`/coupons/verify/${value}`)
            .then((resp: any) => {
                console.log(resp);
                dispatch(setCouponMethod(resp?.data?.payload));
                setCoupon(coupon);
                setCouponData(resp?.data?.payload);
            })
            .catch((error) => {
                dispatch(setCouponMethod(null));
                setCoupon(null);
                setCouponData(null);
                toast.error("Alennuskoodia ei löydy", {
                    hideProgressBar: true,
                    position: "bottom-center",
                    theme: "colored",
                });
            })
            .finally(() => {});
    };

    //@ts-ignore
    cart.price = cart.inCart.reduce((acc: number, item) => acc + (couponData?.data?.period && item.priceType === "recurring" ? 0 : item.price), 0);
    let shipping_price: any = 0;
    if (cart?.shippingMethod === "direct") {
        shipping_price = shipping_direct?.price || 0;
    } else if (cart?.shippingMethod === "posti") {
        shipping_price = shipping_posti?.price || 0;
    }
    cart.shippingPrice = couponData?.data?.free_shipping ? 0 : shipping_price;
    // cart.taxPrice = addDecimals(Number((0.15 * cart.price).toFixed(2)))
    //@ts-ignore
    cart.totalPrice = Number(cart.price) + Number(cart.shippingPrice);

    // @ts-ignore
    return (
        <>
            <stepsheader step1="" step2="" step3="" step4="" user="{2}" payment="{2}" account="{1}"></stepsheader>
            <h1 className="placeorder-header text-center">Summary</h1>
            <container>
                <row>
                    <col md="{10}" className="order-info-col">
                        <listgroup variant="flush">
                            <listgroup.item>
                                <h2 className="placeorder-headers">Delivery method </h2>
                                <row>
                                    <col xs="{8}" sm="{9}">
                                        <p className="placeorder-texts selected-product">
                                            {cart.shippingMethod === "direct" && "Delivery to a senior in a home care, service home or co-operative care home"}
                                            {cart.shippingMethod === "post" && "Delivery to customer at a Post Office pick-up point"}
                                        </p>
                                    
                                    <col xs="{4}" sm="{3}">
                                        <p className="placeorder-texts selected-product selected-product-price">{cart.shippingPrice} €</p>
                                    
                                </row>
                                {cart.shippingMethod === "posti" && cart.usePickupPoint && <p className="placeorder-texts">{`${cart.pickupPoint?.name} (${cart.pickupPoint?.address1}, ${cart.pickupPoint?.city})`}</p>}
                                {cart.shippingMethod === "posti" && (
                                    <p className="placeorder-texts">
                                        <b>Osoite:</b> {cart.address}, {cart.postalCode}, {cart.city}
                                    </p>
                                )}
                                {cart.shippingMethod === "direct" && (
                                    <p className="placeorder-texts">
                                        <b>Toimituskohde:</b> {list.find((el) => el.reference === cart.nursingHome)?.name}
                                    </p>
                                )}
                            </listgroup.item>

                            <listgroup.item>
                                <h2 className="placeorder-headers">Payment</h2>
                                <p className="placeorder-texts">
                                    {cart.paymentMethod === "card" && "Debit or credit card"}
                                    {cart.paymentMethod === "email_billing" && "Email billing"}
                                    {cart.paymentMethod === "contract_billing" && "Billing contract through home health care, nursing home or service apartment"}
                                </p>
                            </listgroup.item>

                            <listgroup.item>
                                <h2 className="placeorder-headers">Order</h2>
                                {cart.inCart.length === 0 ? (
                                    <alert style="{{" background:="" "rgba(78,81,128,0.8)",="" color:="" "#ffffff",="" marginBottom:="" 42,="" }}="">
                                        Your shopping cart is empty{" "}
                                        <link to="/osta" style="{{" color:="" "#ffffff",="" }}="">
                                            Select a service
                                        
                                    </alert>
                                ) : (
                                    <listgroup variant="flush">
                                        {cart.inCart.map((item: any, index) => (
                                            <listgroup.item key="{index}" className="selected-item">
                                                <row>
                                                    <col xs="{8}" sm="{9}">
                                                        <p className="placeorder-texts selected-product">{item.name}</p>
                                                    
                                                    <col xs="{4}" sm="{3}">
                                                        <p className="placeorder-texts selected-product selected-product-price">{couponData?.data?.period && item.priceType === "recurring" ? 0 : item.price} €</p>
                                                    
                                                </row>
                                            </listgroup.item>
                                        ))}
                                    </listgroup>
                                )}
                            </listgroup.item>
                            <listgroup.item>
                                <h2 className="placeorder-headers">
                                    Code <span style="{{" fontSize:="" "0.7em"="" }}="">(discount, gift or trial code)</span>
                                </h2>
                                <row>
                                    <col xs="{12}" sm="{8}" md="{7}">
                                        <form.group controlId="member-username">
                                            <form.control type="text" placeholder="Koodi" name="coupon" value="{coupon" ||="" ""}="" @ts-ignore="" onChange="{(event)" ==""> setCoupon(event.target.value)}
                                            />
                                        </form.control></form.group>
                                    
                                    {coupon && coupon !== cart.coupon && (
                                        <col xs="{12}" sm="{4}" md="{2}">
                                            <button 0="" className="tallenna" style="{{" margin:="" }}="" onClick="{()" ==""> {
                                                    setCouponHandler(coupon);
                                                }}
                                            >
                                                Käytä
                                            </button>
                                        
                                    )}
                                    {cart.coupon && (
                                        <col xs="{2}">
                                            <button 0="" className="tallenna" style="{{" margin:="" }}="" onClick="{()" ==""> {
                                                    dispatch(setCouponMethod(null));
                                                    setCoupon(null);
                                                    setCouponData(null);
                                                }}
                                            >
                                                Poista
                                            </button>
                                        
                                    )}
                                </row>
                            </listgroup.item>
                            <listgroup.item>
                                <h2 className="placeorder-headers">Total amount</h2>
                                <row>
                                    <col xs="{9}" sm="{10}" className="placeorder-texts">
                                        <strong>Total amount</strong>
                                    
                                    <col xs="{3}" sm="{2}" className="placeorder-texts selected-product-price">
                                        <strong>{cart.totalPrice} €</strong>
                                    
                                </row>
                            </listgroup.item>

                            <row>
                                <col sm="{6}">
                                    <button type="button" className="placeorder-back-button" onClick="{handleClick}">
                                        Return
                                    </button>
                                
                                <col sm="{6}">
                                    <elements stripe="{stripePromise}">
                                        <orderform disabled="{coupon" &&="" coupon="" !="=" cart.coupon}=""></orderform>
                                    </elements>
                                
                            </row>
                        </listgroup>
                    
                </row>
            </container>
            <stepsnotification></stepsnotification>
        </>
    );
};

export default PlaceOrder;
