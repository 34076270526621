import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { AppState } from "../redux/types";
import { getAssignedSeniorRequest } from "../redux/actions";

const NurseSeniors = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: AppState) => state.user);
  const { seniorList, loading, error } = useSelector(
    (state: any) => state.nurses
  );

  useEffect(() => {
    if (user && (user.isAdmin || user.role === "it_support")) {
      dispatch(getAssignedSeniorRequest(id));
    } else {
      history.push("/kirjaudu");
    }
    return () => {};
  }, [dispatch, user, history, id]);

  return (
    <>
      <h1 className="my-3 ml-3 module-title">Associated seniors</h1>
      {loading ? (
        <loader></loader>
      ) : error ? (
        <div className="error-message">
          <message variant="danger">
            <div className="message-text">{error.message}</div>
          </message>
        </div>
      ) : seniorList.length > 0 ? (
        <table striped="" bordered="" hover="" responsive="" className="users-table">
          <thead className="user-title">
            <tr className="user">
              <th className="text-center">ID</th>
              <th className="text-center">First Name</th>
              <th className="text-center">Last Name</th>
              <th className="text-center">Home Location</th>
            </tr>
          </thead>
          <tbody>
            {seniorList?.map((item: any) => (
              <tr key="{item.id}">
                <td>{item.id}</td>
                <td>{item.firstName}</td>
                <td>{item.lastName}</td>
                <td>{item.homeCity}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <message variant="light">No Senior Assigned!!</message>
      )}
    </>
  );
};

export default NurseSeniors;
