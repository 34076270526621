import React, { CSSProperties, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ReactNode, useEffect } from "react";
import { getAlertsRequest } from "../../redux/actions";
import { AppState } from "../../redux/types";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Button from "../../components/button/Button";
import Banner from "../../components/layout/banner/Banner";
import styles from "./phone.module.css";
import { classNames } from "../../utils/classnames";

export type ServicesType = {
    content: ReactNode;
    image: string;
    credit?: string;
    alt?: string;
};
const Phone = () => {
    const dispatch = useDispatch();

    const currentDate = Date.now();

    useEffect(() => {
        dispatch(getAlertsRequest());
        return () => {};
    }, [dispatch]);

    const resources = useSelector((state: AppState) => state.alerts);
    const { list } = resources;

    const services: ServicesType[] = [
        {
            content: (
                <>
                    <div className="services-info-title">Telephone, accessories </div>
                    <ul className="services-info-text">
                        <li>
                            <a href="https://www.samsung.com/fi/smartphones/galaxy-a/galaxy-a15-5g-blue-128gb-sm-a156bzbdeub" target="_blank" rel="noreferrer">
                                <span className="global-text global-text-strong">Samsung Galaxy</span>
                            </a>{" "}
                            A15 5G, 128 GB
                        </li>
                        <li>Android 14, blue</li>
                        <li>Optional extras according to choice</li>
                    </ul>
                </>
            ),
            image: "/assets/images/phone/senoripuhelin.webp",
            alt: "Nainen pitää kädessään sinistä puhelinta",
        },
        {
            content: (
                <>
                    <div className="services-info-title">Easy-to-use home view and functions</div>
                    <ul className="services-info-text">
                        <li>Home view made easy</li>
                        <li>Functions modified for ease of use</li>
                        <li>
                            Necessary tools ready
                            <ul>
                                <li>Video call view only or calls, texts, camera, news - and the possibility to add your own apps</li>
                            </ul>
                        </li>
                        <li>If the customer later gives up</li>
                        <p>With Digihappy, your phone will revert back to a normal Samsung smartphone.</p>
                        <a href="https://www.youtube.com/watch?v=ThIlFx8pbn8" className="global-link" target="_blank" rel="noreferrer">
                            Watch the video
                        </a>
                    </ul>
                </>
            ),
            image: "/assets/images/phone/paras-senioripuhelin.webp",
            alt: "Henkilö pitelee älypuhelinta, jonka näytöllä näkyy erilaisia sovelluksia",
        },
        {
            content: (
                <>
                    <div className="services-info-title">Easy and secure video calling</div>

                    <ul className="services-info-text">
                        <li>Digihappy video app is on a senior's phone and a loved one's mobile phone</li>
                        <li>When a relative or friend calls, the call opens on the senior's tablet without touching if desired</li>
                        <li>The senior calls by touching the photo of their relative or friend</li>
                        <li>Secure, only family account members can call</li>
                    </ul>
                </>
            ),
            image: "/assets/images/phone/videopuhelu-senioripuhelimella.webp",
            alt: "Älypuhelin pöydällä näyttää videopuhelua, jossa on nainen",
        },
        {
            content: (
                <>
                    <div className="services-info-title">Family account for video calls</div>
                    <ul className="services-info-text">
                        <li>You will be assigned six family members or friends. A member can make a video call, which will automatically open on the senior's tablet if they wish. The member's picture will appear in the senior's video call view.</li>
                        <li>The member has access to IT support.</li>
                    </ul>
                </>
            ),
            image: "/assets/images/phone/helpot-videopuhelut-senioripuhelimella.webp",
            alt: "Uusi Samsung Galaxy S10 5G ja kosketusnäyttökynä pöydällä. ",
        },
        {
            content: (
                <>
                    <div className="services-info-title">IT support, remote management, security, updates</div>
                    <ul className="services-info-text">
                        <li>Video counselling at the start</li>
                        <li>Continuous IT support, telephone and video conferencing, messaging channel</li>
                        <li>Remote management of your phone, even without the active participation of the senior citizen</li>
                        <li>Security and updates</li>
                    </ul>
                </>
            ),
            image: "/assets/images/phone/5.webp",
        },
    ];

    const [table, openTable] = useState(false);
    const [play, setPlay] = useState(0);

    return (
        <>
            <helmet>
                <title>Senior phone as a service | Best features and IT support</title>
                <meta name="description" content="A senior phone with everything you need, not everything you don't. Easy video calls. Ready to use, it-support.">
            </helmet>
            <banner 56="" backgroundImage="/assets/images/banners/helpot-videopuhelut.webp" label="Nainen pitelee älypuhelinta, jonka näytöllä näkyy yhteystietoluettelo" height="{`calc(100vh" -="" ${="" +="" *="" list?.filter((el)=""> {
                            // @ts-ignore
                            return (!el.start || new Date(el.start) <= currentDate) && (!el.end || new Date(el.end) >= currentDate);
                        })?.length -
                    1
                }px)`}
            >
                <div className="global-banner-text-container">
                    <h1 className="global-banner-title global-white">Senior phone as a service</h1>
                    <p className="global-banner-text global-white">The easiest smartphone for seniors. Everything you need, nothing you don't.</p>
                    <link to="/osta/senioripuhelin" style="{{" marginTop:="" "auto"="" }}="">
                        <button secondary="" emphasized="">
                            BUY A SENIOR PHONE
                        </button>
                    
                </div>
            </banner>
            <container fluid="" className="my-3 services-container services-container-80" style="{{" minWidth:="" "min(900px,="" 100%)"="" }}="">
                <h1 className="services-header">Phone and Digihappy service - We do it all for you</h1>
                <div className="global-box-line-container">
                    {services.map((service, key) => (
                        <div className="global-box-line" key="{key}">
                            {key % 2 === 0 ? (
                                <react.fragment>
                                    <div className="global-box global-background-gray">{service.content}</div>
                                    <div className="global-image-container">
                                        <img src="{service.image}" alt="{service.alt}" className="global-image">
                                    </div>
                                </react.fragment>
                            ) : (
                                <react.fragment>
                                    <div className="global-image-container">
                                        <img src="{service.image}" alt="{service.alt}" className="global-image">
                                    </div>
                                    <div className="global-box global-background-gray">{service.content}</div>
                                </react.fragment>
                            )}
                        </div>
                    ))}
                </div>
            </container>
            <container fluid="" className="my-3 services-container service-table-container" id="palvelut">
                <div className="{styles.serviceContainer}">
                    <h1 600="" className="services-header text-center" style="{{" marginBottom:="" 0,="" fontWeight:="" }}="">
                        Senior smartphone comparison
                    </h1>
                    <p className="{styles.description}" id="best">
                        The Digihappy senior smartphone excels in terms of ease of use, performance and service compared to similar products.
                    </p>
                    <div className="{styles.tableDropdownContainer}" onClick="{()" ==""> openTable((table) => !table)}>
                        <span className="{styles.dropDownText}">Open a senior smartphone comparison</span>
                        <div className="{table" ?="" styles.iconOpen="" :="" styles.icon}="">
                            <img className="{styles.dropDownIcon}" src="/assets/icons/arrow_left.svg" alt="<">
                        </div>
                    </div>
                    <div className="{table" ?="" styles.tableOpen="" :="" styles.tableHidden}="">
                        <comparisontable></comparisontable>
                        <p className="{classNames(styles.bottomText," "global-text-small="" text-center")}="">
                            You will receive your first month of Digihappy IT support free of charge and without any restrictions. After this period, individual IT support is free of charge for 45 minutes/month. The additional support costs €10/10 minutes,
                            minimum charge is €10/call. In the event of a malfunction of a service or device related to us, the resolution of the problem is always free of charge.
                        </p>
                    </div>
                </div>
                <div className="services-button-container">
                    <button onClick="{()" ==""> setPlay(1)}>View video</button>
                    <div className="global-video-wrapper">
                        <iframe 16="" src="{`https://www.youtube.com/embed/ThIlFx8pbn8?autoplay=${play}`}" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; autoplay" allowFullScreen="" title="Digihappy Senior Phone" style="{{" margin:="" "0="" auto",="" width:="" "100%",="" height:="" border:="" "none",="" aspectRatio:="" 9,="" }}=""></iframe>
                    </div>
                </div>
            </container>
        </>
    );
};

const ComparisonTable = () => (
    <div className="palvelut-table-div">
        <table className="{classNames(&quot;palvelut-table&quot;," styles.table)}="">
            <colgroup>
                <col style="{{" width:="" "40%",="" }}="">
                <col style="{{" width:="" "20%",="" }}="">
                <col style="{{" width:="" "20%",="" }}="">
                <col style="{{" width:="" "20%",="" }}="">
            </colgroup>
            <tbody>
                <tr>
                    <td></td>
                    <td className="{styles.header}">
                        <h1 className="accordion-button-header">Digihappy /Samsung</h1>
                    </td>
                    <td className="{styles.header}">
                        <h1 className="accordion-button-header">Samsung</h1>
                    </td>
                    <td className="{styles.header}">
                        <h1 className="accordion-button-header">Doro</h1>
                    </td>
                </tr>
                <tr>
                    <td>Telephone model</td>
                    <td>Samsung Galaxy A15</td>
                    <td>Samsung Galaxy A15</td>
                    <td>Doro 8210</td>
                </tr>
                <tr>
                    <td>Online access</td>
                    <td>5G</td>
                    <td>5G</td>
                    <td>4G</td>
                </tr>
                <tr>
                    <td>Operating system</td>
                    <td>Android 14</td>
                    <td>Android 14</td>
                    <td>Android 12</td>
                </tr>
                <tr>
                    <td>Camera</td>
                    <td>50 MP</td>
                    <td>50 MP</td>
                    <td>16 MP</td>
                </tr>
                <tr>
                    <td>Memory</td>
                    <td>4G/128 GB</td>
                    <td>4G/128 GB</td>
                    <td>4G/64 GB</td>
                </tr>
                <tr>
                    <td>Water resistance</td>
                    <td>Not reported</td>
                    <td>Not reported</td>
                    <td>Splashes</td>
                </tr>
                <tr>
                    <td>Battery life - talk time</td>
                    <td>44 hours</td>
                    <td>44 hours</td>
                    <td>23 hours</td>
                </tr>
                <tr>
                    <td>Quick download</td>
                    <td>1 hour 25 min</td>
                    <td>1 hour 25 min</td>
                    <td>no fast charging</td>
                </tr>
                <tr>
                    <td>Performance - Geekbench - bigger value better</td>
                    <td>684/1831</td>
                    <td>684/1831</td>
                    <td>182/800</td>
                </tr>
                <tr>
                    <td>Emergency call by pressing a button</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                </tr>
                <tr>
                    <td>Customized home view and functions</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td></td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                </tr>
                <tr>
                    <td>Ready to use - optimised settings ready to go</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td></td>
                    <td>
                        <star></star>
                    </td>
                </tr>
                <tr>
                    <td>Ready to use - connection ready when you want it</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Big icons</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td>
                        <div style="{{" display:="" "flex",="" columnGap:="" "2px",="" justifyContent:="" "space-between"="" }}="">
                            <star></star>
                            <span style="{{" textAlign:="" "right"="" }}="">
                                in part <br/>
                                available at
                            </span>
                        </div>
                    </td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                </tr>
                <tr>
                    <td>All apps in one view</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>No unnecessary appeals</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td>
                        <div style="{{" display:="" "flex",="" columnGap:="" "2px",="" justifyContent:="" "space-between"="" }}="">
                            <star></star>
                            <span style="{{" textAlign:="" "right"="" }}="">
                                in part <br/>
                                available at
                            </span>
                        </div>
                    </td>
                    <td>
                        <star></star>
                    </td>
                </tr>
                <tr>
                    <td>Home view works without swapping</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Big home button</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td>
                        <div style="{{" display:="" "flex",="" columnGap:="" "2px",="" justifyContent:="" "space-between",="" flexWrap:="" "wrap",="" alignItems:="" "flex-end"="" }}="">
                            <div>
                                <star></star>
                                <star></star>
                            </div>
                            <span style="{{" textAlign:="" "right",="" marginLeft:="" "auto"="" }}="">available at</span>
                        </div>
                    </td>
                    <td>
                        <star></star>
                    </td>
                </tr>
                <tr>
                    <td>Sound, font, locked orientation</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td>
                        <div style="{{" display:="" "flex",="" columnGap:="" "2px",="" justifyContent:="" "space-between",="" flexWrap:="" "wrap",="" alignItems:="" "flex-end"="" }}="">
                            <div>
                                <star></star>
                                <star></star>
                            </div>
                            <span style="{{" textAlign:="" "right",="" marginLeft:="" "auto"="" }}="">available at</span>
                        </div>
                    </td>
                    <td>
                        <div style="{{" display:="" "flex",="" columnGap:="" "2px",="" justifyContent:="" "space-between",="" flexWrap:="" "wrap",="" alignItems:="" "flex-end"="" }}="">
                            <div>
                                <star></star>
                                <star></star>
                            </div>
                            <span style="{{" textAlign:="" "right",="" marginLeft:="" "auto"="" }}="">available at</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        Easy video calls with your family <br/>
                        (Digihappy app)
                    </td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Video call answering also without touching</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Calling by pressing the large nearby picture</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Initial advice</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Continuous IT support, updates</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Remote management as part of an IT service</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Remote management by a loved one</td>
                    <td>
                        <star></star>
                    </td>
                    <td>
                        <star></star>
                    </td>
                    <td>
                        <star></star>
                    </td>
                </tr>
                <tr>
                    <td>After a senior user, the phone can be easily upgraded to a high-quality smartphone</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td className="{styles.top}">Price July 2024</td>
                    <td className="{styles.top}">165€ phone 35€ installation, initial consultation 11€/month ongoing it support, remote management, video app, custom view and features</td>
                    <td className="{styles.top}">149€- 239 €</td>
                    <td className="{styles.top}">275€-339€</td>
                </tr>
            </tbody>
        </table>
    </div>
);

const Star = ({ style }: { style?: CSSProperties }) => {
    return <img className="{styles.star}" src="/assets/icons/star.svg" alt="*" style="{style}">;
};

export default Phone;
