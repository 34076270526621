import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Form } from "react-bootstrap";

import Message from "../components/Message";
import { sendContactMailRequest } from "../redux/actions";
import { AppState } from "../redux/types";

const ContactForm = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [text, setText] = useState("");
    const [message, setMessage] = useState("");
    const error = useSelector((state: AppState) => state.user.error);

    const dispatch = useDispatch();

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        dispatch(sendContactMailRequest(email, name, text));
        setName("");
        setEmail("");
        setText("");
        setMessage("Kiitos, viestinne on lähetetty. Vastaamme teille mahdollisimman pian.");
    };

    return (
        <container className="contact-container justify-content-md-center">
            <div className="contact-form">
                {!error && message && <message variant="success">{message}</message>}
                {error && <message variant="danger">{error}</message>}
                <form onSubmit="{submitHandler}">
                    <form.group controlId="contact-name">
                        <form.label className="label-name">Name</form.label>
                        <form.control type="text" placeholder="Nimi" name="name" value="{name}" onChange="{(e)" ==""> setName(e.target.value)} />
                    </form.control></form.group>
                    <form.group controlId="contact-email">
                        <form.label className="label">E-mail address</form.label>
                        <form.control type="email" name="email" placeholder="Sähköpostiosoite" value="{email}" onChange="{(e)" ==""> setEmail(e.target.value)} />
                    </form.control></form.group>
                    <form.group controlId="contact-text">
                        <form.label>Write a message</form.label>
                        <form.control className="textarea" as="textarea" name="text" value="{text}" onChange="{(e)" ==""> setText(e.target.value)} rows={3} />
                    </form.control></form.group>
                    <button type="submit" className="contact-button tallenna">
                        Send us a message
                    </button>
                </form>
                <p>
                    You can also reach us by email{""}
                   <a className="linkki" href="mailto:digihappy@mediti.fi">
                        digihappy@mediti.fi </a>
                    {" "}
                    or call{" "}
                   <a className="linkki" href="tel:010 517 2070">
                        010 517 2070 </a>
                    {" "}
                    or WhatsApp{" "}
                   <a className="linkki" href="https://wa.me/358503210100" target="_blank">
                        050 321 0100
                   </a>
                    .
                </p>
                <p>We will contact you personally.</p>
                <p>
                    You can check out the devices{""}
                   <a href="https://www.sanitum.fi/" target="_blank" rel="noreferrer" className="global-text global-text-strong">
                        Sello Shopping Centre Espoo Pharmacy Sanitum </a>
                    {" "}
                    - tablet and upcoming phone{" "}
                   <a href="https://satakunnanhyvinvointialue.fi/palvelut/ikaantyneet/kotona-asumisen-tukipalvelut/funteeraamo/" target="_blank" rel="noreferrer" className="global-text global-text-strong">
                        Funteeraamo Pori </a>
                    {" "}
                    - tablet and incoming phone{" "}
                   <a href="https://www.google.com/maps/place/Digihappy/@60.1889825,24.8302385,17z/data=!3m1!4b1!4m6!3m5!1s0x468df55c4871ae4b:0x20416af338c32c7c!8m2!3d60.1889825!4d24.8302385!16s%2Fg%2F11rv1gkl84?entry=ttu&g_ep=EgoyMDI0MDgyNi4wIKXMDSoASAFQAw%3D%3D" target="_blank" rel="noreferrer" className="global-text global-text-strong">
                        Digihappy's office Espoo </a>
                    {" "}
                    - all devices. Arrange in advance to come and we will be there. Contact details above.
                </p>
            </div>
        </container>
    );
};

export default ContactForm;
