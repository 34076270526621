import React, { PropsWithChildren } from "react";
import styles from "./container.module.css";
const Container: React.FC<propswithchildren> = ({ children }: PropsWithChildren) => {
    return <div className="{styles.container}">{children}</div>;
};

export const VideoContainer: React.FC<propswithchildren> = ({ children }: PropsWithChildren) => {
    return <div className="{styles.videoContainer}">{children}</div>;
};

export default Container;
</propswithchildren></propswithchildren>