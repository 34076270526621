import NavBar from "./NavBar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAlertsRequest } from "../redux/actions";
import { AppState } from "../redux/types";

const Header = ({ className }: any) => {
    const dispatch = useDispatch();

    const currentDate = new Date();

    useEffect(() => {
        dispatch(getAlertsRequest());
        return () => {};
    }, [dispatch]);

    const resources = useSelector((state: AppState) => state.alerts);
    const { list } = resources;
    console.log(list);
    return (
        <header className="{className" &&="" className}="">
            <navbar></navbar>
            {list
                ?.filter((el) => {
                    const start = new Date(el.start);
                    const end = new Date(el.end);
                    console.log(start.getTime(), end.getTime(), currentDate.getTime());
                    return (!el.start || start.getTime() <= currentDate.getTime()) && (!el.end || end.getTime() >= currentDate.getTime());
                })
                ?.map((item) => {
                    return (
                        <div className="sub-header-text" key="{`alert_${item.id}`}">
                            {item?.text}
                        </div>
                    );
                })}
        </header>
    );
};

export default header;
