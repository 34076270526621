import { getSeniorHomesRequest, getUsersRequest, getUserEditFormData, setUserEditFormDataRequest } from "../redux/actions";
import { AppState } from "../redux/types";
import { LinkContainer } from "react-router-bootstrap";
import Autocomplete from "./Autocomplete";
import SwitchButton from "./SwitchButton";
import { getOptions, getDefaultOptions } from "../utils/autocomplete";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Form, Row, InputGroup } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
// import axios from "axios";
// import { toast } from 'react-toastify';

const UserEditForm = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const user = useSelector((state: AppState) => state.user);
    // const resources = useSelector((state: AppState) => state.seniorHomes);
    const nurseStaff: any = useSelector((state: AppState) => state.nurses.list);
    const users: any = useSelector((state: AppState) => state.users);
    const { profile } = users;

    const [formData, setFormData] = useState({
        id: "",
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        mobileNumber: "",
        skypeEmail: "",
        role: "",
        isAdmin: false,
        readOnly: true,
        isSenior: false,
        customerId: "",
        department: "",
        nursingHome: "",
        homeAddress: "",
        homeCity: "",
        homePostalCode: "",
        nursingHomeAddress: profile?.nursingHomeAddress,
        password: "",
        hasItSupport: true,
        hasNurseSupport: true,
        nurses: [],
        chosenService: profile?.chosenService,
        tabletLanguage: profile?.tabletLanguage,
        autoAnswer: profile?.autoAnswer,
        serviceOption: profile?.serviceOption,
        hasNurseCalling: false,
    });
    const [assignedNurses, setAssignedNurses] = useState([]);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (user && (user.isAdmin || user.role === "it_support")) {
            dispatch(getUserEditFormData(id));
            dispatch(getUsersRequest());
            dispatch(getSeniorHomesRequest());
        } else {
            history.push("/kirjaudu");
        }
        return () => {};
    }, [dispatch, history, user, id]);

    useEffect(() => {
        const _nurses = profile.isSenior ? getDefaultOptions(nurseStaff, profile.nurses) : [];
        const _hasItSupport = profile.hasOwnProperty("hasItSupport") ? (profile.hasItSupport === 1 ? true : false) : true;
        const _hasNurseSupport = profile.hasOwnProperty("hasNurseSupport") ? (profile.hasNurseSupport === 1 ? true : false) : true;
        const _hasNurseCalling = profile.hasOwnProperty("hasNurseCalling") ? (profile.hasNurseCalling === 1 ? true : false) : true;

        setFormData((prevValue: any) => {
            return {
                ...prevValue,
                ...profile,
                nurses: _nurses,
                hasItSupport: _hasItSupport,
                hasNurseSupport: _hasNurseSupport,
                hasNurseCalling: _hasNurseCalling,
            };
        });
        setAssignedNurses(_nurses);
    }, [users, profile, nurseStaff]);

    const [showPass, setShowPass] = useState(false);
    const showPassHandler = () => {
        setShowPass((current) => !current);
    };

    const handleChange = (e: React.ChangeEvent<htmlinputelement>) => {
        const { value, name } = e.target;
        setFormData((prevValue: any) => {
            return {
                ...prevValue,
                [name]: value,
            };
        });
    };

    const handleChangeBoolean = (e: React.ChangeEvent<htmlinputelement>) => {
        console.log(e.target.value === "true");
        setFormData((prevValue: any) => {
            return {
                ...prevValue,
                [e.target.name]: e.target.value === "true",
            };
        });
    };

    const handleAssignedNurses = (_nurses: any) => {
        setFormData((prevValue: any) => {
            return {
                ...prevValue,
                nurses: _nurses,
                hasNurseSupport: _nurses.length === 0 ? false : prevValue.hasNurseSupport,
            };
        });
        setAssignedNurses(_nurses);
        if (_nurses.length > 0) {
            setError(false);
        }
    };

    const handleItSupport = () => {
        setFormData((prevValue: any) => {
            return {
                ...prevValue,
                hasItSupport: !formData.hasItSupport,
            };
        });
    };

    const handleToggleButton = (field: string) => {
        if (field === "nurseSupport") {
            setFormData((prevValue: any) => {
                return {
                    ...prevValue,
                    hasNurseSupport: !formData.hasNurseSupport,
                    nurses: !formData.hasNurseSupport === true ? assignedNurses : [],
                    hasNurseCalling: !formData.hasNurseSupport ? prevValue.hasNurseCalling : false,
                };
            });
        } else if (field === "nurseCalling") {
            setFormData((prevValue: any) => {
                return {
                    ...prevValue,
                    hasNurseCalling: !formData.hasNurseCalling,
                };
            });
        }
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        const { hasItSupport, hasNurseSupport, nurses, ...userEditFormData } = formData;
        let _formData: any = {
            id: formData.id,
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            username: formData.username,
            mobileNumber: formData.mobileNumber,
        };
        if (formData.role === "senior" || formData.isSenior === true) {
            _formData = {
                ..._formData,
                nurses: formData.nurses.map((nurse: any) => nurse.value),
                hasItSupport: formData.hasItSupport ? 1 : 0,
                hasNurseSupport: formData.hasNurseSupport ? 1 : 0,
                hasNurseCalling: formData.hasNurseCalling ? 1 : 0,
            };
        }
        if (_formData.hasNurseSupport === 1 && _formData.nurses.length === 0) {
            setError(true);
        } else {
            dispatch(
                setUserEditFormDataRequest({
                    _formData: _formData,
                    userEditFormData: userEditFormData,
                })
            );
            history.push("/admin/users");
        }
    };
    console.log("dept", formData);
    if (profile) {
        return (
            <container className="register-form-container">
                <form onSubmit="{submitHandler}" className="service-form">
                    <form.group controlId="user-id">
                        <form.label>ID</form.label>
                        <form.control type="number" placeholder="ID" value="{formData.id}" readOnly="{true}"></form.control>
                    </form.group>
                    <form.group controlId="user-id">
                        <form.label>R-ID</form.label>
                        <form.control type="number" placeholder="R-ID" value="{profile?.groupId}" readOnly="{true}"></form.control>
                    </form.group>
                    <hr>
                    <form.group controlId="service-role">
                        <form.label>Role</form.label>
                        <form.control as="select" name="role" value="{formData.role}" onChange="{handleChange}" disabled="{user.readOnly" ||="" !user.isAdmin}="">
                            <option value="customer">Customer (customer)</option>
                            <option value="senior">Senior (senior)</option>
                            <option value="member">Member (member)</option>
                        </form.control>
                    </form.group>

                    <form.group controlId="service-isAdmin">
                        <form.label>Admin?</form.label>
                        <form.control as="select" name="isAdmin" value="{typeof" formData.isAdmin="==" "boolean"="" ?="" formData.isAdmin.toString()="" :="" "false"}="" disabled="{user.readOnly" ||="" user.id="==" profile.id="" !user.isAdmin}="" onChange="{handleChangeBoolean}">
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </form.control>
                    </form.group>
                    {formData.isAdmin && (
                        <form.group controlId="service-readOnly">
                            <form.label>readOnly?</form.label>
                            <form.control as="select" name="readOnly" value="{typeof" formData.readOnly="==" "boolean"="" ?="" formData.readOnly.toString()="" :="" "false"}="" disabled="{user.readOnly" ||="" user.id="==" profile.id}="" onChange="{handleChangeBoolean}">
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </form.control>
                        </form.group>
                    )}
                    <hr>
                    <form.group controlId="service-username">
                        <form.label>Username</form.label>
                        <form.control type="text" placeholder="Käyttäjänimi" name="username" value="{formData.username}" onChange="{handleChange}" readOnly="{user.readOnly" ||="" !user.isAdmin}=""></form.control>
                    </form.group>
                    <form.group controlId="service-email">
                        <form.label>E-mail</form.label>
                        <form.control type="text" placeholder="Sähköposti" name="email" value="{formData.email}" onChange="{handleChange}" readOnly="{user.readOnly" ||="" !user.isAdmin}=""></form.control>
                    </form.group>
                    <form.group controlId="service-firstName">
                        <form.label>First name</form.label>
                        <form.control type="text" placeholder="Etunimi" name="firstName" value="{formData.firstName}" onChange="{handleChange}" readOnly="{user.readOnly" ||="" !user.isAdmin}=""></form.control>
                    </form.group>
                    <form.group controlId="service-lastName">
                        <form.label>Surname</form.label>
                        <form.control type="text" placeholder="Sukunimi" name="lastName" value="{formData.lastName}" onChange="{handleChange}" readOnly="{user.readOnly" ||="" !user.isAdmin}=""></form.control>
                    </form.group>
                    <form.group controlId="service-mobileNumber">
                        <form.label>Phone number</form.label>
                        <form.control type="text" placeholder="Matkapuhelinnumero" name="mobileNumber" value="{formData.mobileNumber}" onChange="{handleChange}" readOnly="{user.readOnly" ||="" !user.isAdmin}=""></form.control>
                    </form.group>
                    <form.group controlId="service-skypeEmail">
                        <form.label>Skype</form.label>
                        <form.control type="text" placeholder="Skype" name="skypeEmail" value="{formData.skypeEmail}" onChange="{handleChange}" readOnly="{user.readOnly" ||="" !user.isAdmin}=""></form.control>
                    </form.group>

                    <form.group controlId="service-isSenior">
                        <form.label>The senior citizen himself</form.label>
                        <form.control as="select" name="isSenior" value="{typeof" formData.isSenior="==" "boolean"="" ?="" formData.isSenior.toString()="" :="" "false"}="" onChange="{handleChangeBoolean}" disabled="{user.readOnly" ||="" !user.isAdmin}="">
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </form.control>
                    </form.group>

                    <form.group controlId="service-customerId">
                        <form.label>Customer ID (Stripe)</form.label>
                        <form.control type="text" placeholder="Customer ID (Stripe)" name="customerId" value="{formData.customerId}" onChange="{handleChange}" readOnly="{user.readOnly" ||="" !user.isAdmin}=""></form.control>
                    </form.group>

                    <form.group controlId="service-department">
                        <form.label>Address details</form.label>
                        <form.control as="select" name="department" value="{formData.department}" onChange="{handleChange}" disabled="{user.readOnly" ||="" !user.isAdmin}="">
                            <option value="{undefined}">Not selected</option>
                            <option value="homeAddress">At home</option>
                            <option value="nursingHome">In a retirement home</option>
                        </form.control>
                    </form.group>

                    {formData.department === "homeAddress" && (
                        <div>
                            <form.group>
                                <form.label>Street address</form.label>
                                <form.control type="text" name="homeAddress" placeholder="Katuosoite" value="{formData.homeAddress}" onChange="{handleChange}" readOnly="{user.readOnly" ||="" !user.isAdmin}=""></form.control>
                            </form.group>
                            <form.group controlId="city">
                                <form.label>Postal code</form.label>
                                <form.control type="text" name="homePostalCode" placeholder="Postinumero" value="{formData.homePostalCode}" onChange="{handleChange}" readOnly="{!user.isAdmin}"></form.control>
                            </form.group>
                            <form.group controlId="city">
                                <form.label>City</form.label>
                                <form.control type="text" name="homeCity" placeholder="Kaupunki" value="{formData.homeCity}" onChange="{handleChange}" readOnly="{!user.isAdmin}"></form.control>
                            </form.group>
                        </div>
                    )}
                    {formData.department === "nursingHome" && (
                        <div>
                            <form.group>
                                <form.label>Name of the retirement home</form.label>
                                <form.control type="text" name="nursingHome" placeholder="Vanhainkodin nimi" value="{formData.nursingHome}" onChange="{handleChange}" readOnly="{!user.isAdmin}"></form.control>
                            </form.group>
                            <form.group controlId="city">
                                <form.label>Location</form.label>
                                <form.control type="text" name="nursingHomeAddress" placeholder="Paikkakunta" value="{formData.nursingHomeAddress}" onChange="{handleChange}" readOnly="{!user.isAdmin}"></form.control>
                            </form.group>
                        </div>
                    )}

                    {!user.readOnly && (formData.role === "senior" || formData.isSenior) && (
                        <form.group controlId="service-password">
                            <form.label>Password (Leave blank to avoid resetting the user's password)</form.label>
                            <inputgroup>
                                <form.control type="{showPass" ?="" "text"="" :="" "password"}="" placeholder="Salasana" name="password" value="{formData.password}" onChange="{handleChange}" disabled="{user.isAdmin" false="" true}=""></form.control>
                                <button variant="outline-secondary shadow-none show-password-btn" onClick="{showPassHandler}" disabled="{user.isAdmin" ?="" false="" :="" true}="">
                                    <i className="{showPass" ?="" "fas="" fa-eye-slash"="" :="" fa-eye"}=""></i>
                                </button>
                            </inputgroup>
                            <div className="show-password">Click on the eye icon to see your password</div>
                            <small className="password-length">At least 11 characters.</small>
                        </form.group>
                    )}

                    {(formData.role === "senior" || formData.isSenior) && (
                        <>
                            <form.group controlId="service-nurseSupport">
                                <form.label style="{{" marginRight:="" "5rem"="" }}="">Nurse support</form.label>
                                <switchbutton isChecked="{formData.hasNurseSupport}" handleChecked="{()" ==""> handleToggleButton("nurseSupport")} disabled={user.isAdmin ? false : true} />
                            </switchbutton></form.group>
                            <form.group controlId="service-nurseCalling">
                                <form.label style="{{" marginRight:="" "5rem"="" }}="">Nurse Calling</form.label>
                                <switchbutton isChecked="{formData.hasNurseCalling}" handleChecked="{()" ==""> handleToggleButton("nurseCalling")} disabled={user.isAdmin && formData.hasNurseSupport ? false : true} />
                            </switchbutton></form.group>
                            <form.group controlId="service-addEditNurse">
                                <form.label>Add/Edit nurse</form.label>
                                <autocomplete options="{getOptions(nurseStaff," formData.nurses)}="" handleOptions="{handleAssignedNurses}" value="{formData.nurses}" placeholder="{&quot;Enter" Nurse="" Name="" ID"}="" isDisabled="{!formData.hasNurseSupport" ||="" !user.isAdmin}=""></autocomplete>
                                {error && <p style="{{" color:="" "red"="" }}="">Please select any nurse</p>}
                            </form.group>

                            <form.group controlId="service-itSupport">
                                <form.label style="{{" marginRight:="" "7rem"="" }}="">IT support</form.label>
                                <switchbutton isChecked="{formData.hasItSupport}" handleChecked="{handleItSupport}" disabled="{user.isAdmin" ?="" false="" :="" true}=""></switchbutton>
                            </form.group>
                        </>
                    )}

                    {formData.role === "customer" && (
                        <form.group controlId="service-language">
                            <form.label>Language</form.label>
                            <form.control as="select" name="tabletLanguage" value="{formData.tabletLanguage}" disabled="{user.isAdmin" ?="" false="" :="" true}="">
                                <option value="">-</option>
                                <option value="suomi">Finnish</option>
                                <option value="ruotsi">Swedish</option>
                            </form.control>
                        </form.group>
                    )}

                    {formData.role === "customer" && (
                        <form.group controlId="service-autoanswer">
                            <form.label>Auto answer</form.label>
                            <form.control as="select" name="autoAnswer" value="{formData.autoAnswer}" disabled="{user.isAdmin" ?="" false="" :="" true}="">
                                <option value="">-</option>
                                <option value="true">{`Videopuhelu avautuu automaattisesti ilman kosketusta${formData.chosenService === "mestari" ? " (mahdollinen Samsung tabletissa)" : ""}`}</option>
                                <option value="false">{`Videopuhelu ei avaudu automaattisesti ilman kosketusta${formData.chosenService === "mestari" ? " (Samsung tai Apple)" : ""}`}</option>
                            </form.control>
                        </form.group>
                    )}

                    {formData.role === "customer" && ["easy", "worry-free", "favorite service"].includes(formData?.chosenService) && (
                        <form.group controlId="service-option">
                            <form.label>Applications</form.label>
                            <form.control as="select" name="serviceOption" value="{formData.serviceOption}" disabled="{user.isAdmin" ?="" false="" :="" true}="">
                                <option value="">-</option>
                                <option value="huoleton1">Video calls</option>
                                <option value="huoleton2">Video call, radio</option>
                                <option value="huoleton3">Video call, audio book</option>
                                <option value="huoleton4">Video call, Yle Arena</option>
                                <option value="huoleton5">Video call, radio, audio book, Yle Arena</option>
                                <option value="helppo1">Video calls</option>
                                <option value="helppo2">Video calls, audio books, radio</option>
                                <option value="helppo3">Video calls, audio books, radio, Yle Areena, newspaper</option>
                                <option value="helppo4">Video calls, audio books, radio, Yle Areena, newspapers, games</option>
                                <option value="helppo5">Video calls, audio books, radio, Yle Areena, newspapers, art</option>
                                <option value="helppo6">Video calls, audiobooks, radio, Yle Areena, newspapers, games, art, Spotify</option>
                                <option value="helppo7">Video calls, audiobooks, radio, Yle Areena, newspapers, games, art, Spotify, Whatsapp</option>
                            </form.control>
                        </form.group>
                    )}
                    {formData.role === "customer" && ["phone"].includes(formData?.chosenService) && (
                        <form.group controlId="service-option">
                            <form.label>Applications</form.label>
                            <form.control as="select" name="serviceOption" value="{formData.serviceOption}" disabled="{user.isAdmin" ?="" false="" :="" true}="">
                                <option value="">-</option>
                                <option value="puhelimen1">Video calls</option>
                                <option value="puhelimen2">Video call, calls, contacts, SMS, camera, gallery</option>
                                <option value="puhelimen3">Video calling, calls, contacts, SMS, camera, gallery, Google Chrome, News of the Week, Play Store (add your own apps)</option>
                            </form.control>
                        </form.group>
                    )}
                    <row>
                        <col>
                            <linkcontainer to="{`/admin/users`}">
                                <button className="tallenna paymentmethod-back-button">Return</button>
                            </linkcontainer>
                        
                        {!user.readOnly && user.isAdmin && (
                            <col style="{{" textAlign:="" "right",="" }}="">
                                <button className="create-service-button tallenna" type="submit">
                                    Save
                                </button>
                            
                        )}
                    </row>
                </form>
            </container>
        );
    } else {
        return (
            <container>
                <div>No user found</div>
                <linkcontainer to="{`/admin/users`}">
                    <button>Return</button>
                </linkcontainer>
            </container>
        );
    }
};

export default UserEditForm;
</htmlinputelement></htmlinputelement>