const StepsHeader = () => {
    return (
        <>
                <div className="steps-notification">
                    <div>
                        Problems?
                       <br/>
                        Call
                       <br/>
                       <a className="linkki" href="tel:010 517 2070">010 517 2070</a>
                       <br/>
                        or whatsapp
                       <br/>
                       <a className="linkki" href="https://wa.me/358503210100" target="_blank">050 321 0100</a>
                    </div>
                </div>
        </>
    )
}

export default StepsHeader
