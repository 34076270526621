import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { signUpItSupportRequest } from "../redux/actions/itSupport";
import FormContainer from "./FormContainer";
import SignupForm from "./SignupForm";
import Message from "./Message";
import { AppState } from "../redux/types";

const RegisterItSupport = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [getPath, setPath] = useState("");
  const [token, setToken] = useState("");
  const itSupport: any = useSelector((state: AppState) => state.itSupport);

  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  useEffect(() => {
    const token = query.get("token");

    if (token) {
      const pathname = location.pathname.split("/");
      setPath(pathname[3]);
      setToken(token);
    } else {
      history.push("/kirjaudu");
    }
  }, [query, location, history]);

  const handleCreateAccount = (data: any) => {
    const _data = {
      ...data,
      role: getPath,
      token: token,
    };

    dispatch(signUpItSupportRequest(_data));
  };

  return (
    <div>
      <div className="alert-message">
        {Object.keys(itSupport.message).length !== 0 && (
          <message 200="" variant="{itSupport.message?.status" =="=" ?="" "info"="" :="" "danger"}="">
            <div className="message-text">{itSupport.message?.message}</div>
          </message>
        )}
      </div>
      <h1 className="user-form-header">IT support</h1> {/* IT Support */}
      <formcontainer>
        <h3 className="user-form-header">Create an account</h3> {/* Create an account */}
        <signupform onCreateAccount="{handleCreateAccount}"></signupform>
      </formcontainer>
    </div>
  );
};

export default RegisterItSupport;
