import { Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Banner from "../components/layout/banner/Banner";
import { useSelector } from "react-redux";
import { AppState } from "../redux/types";

const styles = {
    customBody: {
        border: "none",
    },
};

const ForCompanies = () => {
    const resources = useSelector((state: AppState) => state.alerts);
    const { list } = resources;
    const currentDate = Date.now();
    return (
        <>
            <helmet>
                <title>Security bracelets for care services, tablet services, video calls, announcements </title>
                <meta name="description" content="Innovative tablet services and security bracelets for care services. Video calls. Announcements. New activities. Logging.">
            </helmet>
            <banner 56="" backgroundImage="/assets/images/banners/company.webp" height="{`calc(100vh" -="" ${="" +="" *="" list?.filter((el)=""> {
                            // @ts-ignore
                            return (!el.start || new Date(el.start) <= currentDate) && (!el.end || new Date(el.end) >= currentDate);
                        })?.length -
                    1
                }px)`}
            >
                <div className="global-banner-text-container">
                    <h1 className="global-banner-title">For telecare, residential services, municipalities and organisations</h1>
                    <p className="global-banner-text">And you get more from Digihappy at the same time!</p>
                </div>
            </banner>

            <container fluid="" className="my-5 services-container services-container-80" style="{{" minWidth:="" "min(900px,="" 100%)"="" }}="">
                <div>
                    <h2 className="global-section-title global-primary">Tablet service for home care - Telecare</h2>
                    <p className="global-text global-text-normal global-text-dark">Effective telecare at low cost. Video call service with carer and family. At the same time, interesting activities for seniors.</p>
                </div>
                <div>
                    <h2 className="global-section-title global-primary">Tablet Service for Municipalities and Organisations - Supporting the most fragile</h2>
                    <p className="global-text global-text-normal global-text-dark">
                        Support an elderly or disabled person's ability to function and socialise. Ease the burden on the carer. Easy video calls with family, caregiver and volunteer. Plus activating activities.
                    </p>
                </div>
                <div>
                    <h2 className="global-section-title global-primary">Carer's template - New activities and recording</h2>
                    <p className="global-text global-text-normal global-text-dark">
                        Appreciate the nurse with the best tools. For new activities and easy recording. Jumping, music, games, activity management. Medically validated health tests and questionnaires. Education,
                        medical consultation and guidance, and IT support. All ready to go.
                    </p>
                </div>
                <div>
                    <h2 className="global-section-title global-primary">Security bracelet - Location, speech and alarms</h2>
                    <p className="global-text global-text-normal global-text-dark">
                        Sweden's most popular location-based security bracelet from Posifon Digihappy. Speech and accurate gps positioning everywhere. Easy to use as a security bracelet or necklace. Automatic alarm when the senior leaves the area, falls over or when the battery is low.
                        {""}
                        <link to="#" className="global-text">
                            Read more.
                        
                    </p>
                </div>
            </container>

            <h2 className="global-section-title global-primary global-text-strong text-center mt-6 mb-5">Why choose Digihappy?</h2>

            <section className="image-rows">
                <div className="global-grid-container-2">
                    <card className="home-image-card">
                        <card.img src="{&quot;/assets/images/company/1.webp&quot;}" className="global-card-image"></card.img>

                        <card.body className="company-body" style="{styles.customBody}">
                            <card.title as="div" className="image-text">
                                Easy video calls. Answer without touching, call by pressing a picture.
                            </card.title>
                        </card.body>
                    </card>
                    <card className="home-image-card">
                        <card.img src="{&quot;/assets/images/company/2.webp&quot;}" className="global-card-image"></card.img>

                        <card.body className="company-body" style="{styles.customBody}">
                            <card.title as="div" className="image-text">
                                Nice things to do. Ready with the apps you want, the apps you've tested and the best settings. Audiobooks, radios, YleAreena, news and even solitaire.
                            </card.title>
                        </card.body>
                    </card>
                    <card className="home-image-card">
                        <card.img src="/assets/images/company/3.jpg" className="global-card-image"></card.img>

                        <card.body className="company-body" style="{styles.customBody}">
                            <card.title as="div" className="image-text">
                                A "modern central radio", which allows the carer to communicate with video and audio announcements to all residents simultaneously. Easily from your own mobile phone.
                            </card.title>
                        </card.body>
                    </card>
                    <card className="home-image-card">
                        <card.img src="/assets/images/company/hoitaja-käyttää-senioritabletti.webp" width="auto" className="global-card-image" alt="Vihreään paitaan pukeutunut nainen pitelee tablettia."></card.img>

                        <card.body className="company-body" style="{styles.customBody}">
                            <card.title as="div" className="image-text">
                                Make the carer's job easier. New activities and easy recording. All ready to go.
                            </card.title>
                        </card.body>
                    </card>
                </div>
                <container fluid="" className="my-5 services-container services-container-80" style="{{" minWidth:="" "min(900px,="" 100%)",="" textAlign:="" "center"="" }}="">
                    <link className="ota-yhteyttä-linkki text-center" to="/yhteydenotto">
                        Contact us
                    
                    <p className="global-text global-text-normal text-center my-3">The cost of the tablet service starts from 40 € (VAT 0) / month. Samsung tablet, stand, connection, video call service, apps, it support and remote management.</p>
                </container>
            </section>
        </>
    );
};

export default ForCompanies;
