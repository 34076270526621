import { LinkContainer } from "react-router-bootstrap";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import fb from "../images/contacts/fb.jpg";
import IG from "../images/contacts/IG.jpg";
import phone from "../images/contacts/phone.jpg";
import YT from "../images/contacts/YT.jpg";
import React from "react";

const CopyrightPPLink = () => {
    const year = new Date().getFullYear();

    return (
        <navbar>
            <navbar.toggle aria-controls="responsive-navbar-nav"></navbar.toggle>
            <navbar.collapse id="responsive-navbar-nav">
                <nav>
                    <container className="global-footer-center">
                        <row style="{{" width:="" "100%",="" }}="">
                            <col xs="{12}" md="{4}" className="global-footer-copyright">
                                <a className="linkki" href="https://www.facebook.com/digihappy/" target="_blank" rel="noreferrer">
                                    <img src="{fb}" alt="Facebook" style="{{" height:="" "2rem",="" marginBottom:="" "0.2rem",="" marginRight:="" "1rem",="" }}="">
                                </a>
                                <a className="linkki" href="https://www.instagram.com/digihappy.fi/" target="_blank" rel="noreferrer">
                                    <img src="{IG}" alt="Instagram" style="{{" height:="" "2rem",="" marginBottom:="" "0.2rem",="" marginRight:="" "1rem",="" }}="">
                                </a>
                                <a className="linkki" href="https://www.youtube.com/channel/UCgYFspvcO-scKkDss7hIYKw" target="_blank" rel="noreferrer">
                                    <img src="{YT}" alt="Youtube" style="{{" height:="" "2rem",="" marginBottom:="" "0.2rem",="" marginRight:="" "1rem",="" }}="">
                                </a>
                                <a className="linkki" href="tel:+358105172070" target="_blank" rel="noreferrer">
                                    <img src="{phone}" alt="Phone" style="{{" height:="" "2rem",="" marginBottom:="" "0.2rem",="" marginRight:="" "1rem",="" }}="">
                                </a>
                                <span style="{{" color:="" "#4e5180",="" whiteSpace:="" "nowrap",="" }}="">
                                    010 517 2070
                                </span>
                            
                            <col xs="{12}" md="{6}">
                                <linkcontainer to="/palveluehdot" style="{{" background:="" "transparent",="" }}="">
                                    <nav.link className="copyright text-center">
                                        <span className="copyright-link">Copyright © Digihappy {year}</span> | <span className="privacy-policy-link">Privacy policy</span>
                                    </nav.link>
                                </linkcontainer>
                            
                            <col xs="{12}" md="{2}">
                        </row>
                    </container>
                </nav>
            </navbar.collapse>
        </navbar>
    );
};

export default CopyrightPPLink;
