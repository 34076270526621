import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { CookiesProvider } from 'react-cookie';
import App from './App';
import makeStore from './redux/store';
import { SocketProvider } from './context/socketProvider';

const store = makeStore();

function PageToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

// ReactDOM.render(
//   <cookiesprovider>
//     <react.fragment>
//       <router>
//         <provider store="{store}">
//           <pagetotop></pagetotop>
//           <app></app>
//         </provider>
//       </router>
//     </react.fragment>
//   </cookiesprovider>,
// document.getElementById('root')
// );

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <cookiesprovider>
    <react.fragment>
      <router>
        <provider store="{store}">
          <socketprovider>
            <pagetotop></pagetotop>
            <app></app>
          </socketprovider>
        </provider>
      </router>
    </react.fragment>
  </cookiesprovider>
);
