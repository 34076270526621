import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Table} from 'react-bootstrap'
import Loader from '../components/Loader'
import Message from '../components/Message'
import {deleteBlogRequest, getBlogsRequest} from '../redux/actions'
import {AppState} from '../redux/types'
import {useHistory} from "react-router-dom";
import {LinkContainer} from "react-router-bootstrap";

const BlogsList = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const user = useSelector((state: AppState) => state.user)
    useEffect(() => {
        if (user && user.isAdmin) {
            dispatch(getBlogsRequest())
        } else {
            history.push('/kirjaudu')
        }
        return () => {
        }
    }, [dispatch, history, user])

    const resources = useSelector((state: AppState) => state.blogs)
    const {list, loading, error} = resources

    const deleteHandler = (id: any) => {
        console.log('id', id)
        if (window.confirm('Haluatko varmasti poistaa hälytyksen?')) {
            dispatch(deleteBlogRequest(id))
        }
    }

    const addNewBlog = () => {
        history.push('/admin/blog/new')
    }

    return (
        <>
            <h1 className="my-3 ml-3" style="{{color:" '#4e5180',="" fontFamily:="" 'Poppins,="" sanf-serif'}}="">Blog pages</h1>
            <button onClick="{addNewBlog}">Add a new article</button>
            {loading ? (
                <loader></loader>
            ) : error ? (
                <message variant="danger">{error}</message>
            ) : (
                <table striped="" bordered="" hover="" responsive="" className="users-table">
                    <thead className="user-title">
                    <tr className="user">
                        <th className="text-center">ID</th>
                        <th className="text-center">Author</th>
                        <th className="text-center">Title</th>
                        <th className="text-center">Cover</th>
                        <th className="text-center">Topics</th>
                        <th className="text-center">Content preview</th>
                        <th className="text-center">Featured</th>
                        <th className="text-center">{user.readOnly ? 'Katso' : 'Päivitä /Poista'}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {list && list.map((blog: any) => (
                        <tr key="{blog.id}">
                            <td>{blog.id}</td>
                            <td>{blog.author_name}</td>
                            <td>
                                <div>
                                    <div>
                                        {blog.title}
                                    </div>
                                    <div>
                                        <a href="{blog.url" ?="" `${window.location.origin}="" blog="" ${blog.url}`="" :="" ${blog.id}`="" }="" target="_blank" rel="noreferrer" style="{{" padding:="" '8px="" 0',="" color:="" '#666555',="" fontSize:="" '0.8rem',="" }}="">
                                            {blog.url ?
                                                `${window.location.origin}/blog/${blog.url}` :
                                                `${window.location.origin}/blog/${blog.id}`
                                            }
                                        </a>
                                    </div>
                                </div>
                            </td>
                            <td>
                                {blog.cover_type === 'image' && <img style="{{" maxHeight:="" 48,="" }}="" src="{blog.cover}" alt="page cover">
                                }
                                {blog.cover_type === 'youtube_video' && <img style="{{" maxHeight:="" 48,="" }}="" src="{`https://img.youtube.com/vi/${blog.cover}/maxresdefault.jpg`}" alt="page cover">
                                }
                                {blog.cover_type === 'video' && <video style="{{" maxHeight:="" 48,="" }}="" src="{blog.cover}" title="page cover"></video>
                                }
                            </td>
                            <td>{blog.topics && blog.topics?.map(function (i: any) {
                                return i.name
                            })?.join()}</td>
                            <td>{blog.content_preview}</td>
                            <td>
                                {blog.featured ? (
                                    <i className="fas fa-check" style="{{color:" '#4e5180'}}=""></i>
                                ) : (
                                    <i className="fas fa-times" style="{{color:" 'red'}}=""></i>
                                )}
                            </td>
                            <td>
                                <linkcontainer to="{`/admin/blog/${blog.id}`}">
                                    <button className="btn-sm">
                                        <i className="{user.readOnly" ?="" 'fas="" fa-eye'="" :="" fa-edit'}="" style="{{color:" '#fff'}}=""></i>
                                    </button>
                                </linkcontainer>
                                {!user.readOnly &&
                                    <button variant="danger" className="btn-sm" onClick="{()" ==""> deleteHandler(blog.id)}>
                                        <i className="fas fa-trash"></i>
                                    </button>
                                }
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            )}
        </>
    )
}

export default BlogsList
