import { LinkContainer } from "react-router-bootstrap";
import { Navbar, Nav } from "react-bootstrap";

const Links = () => {
    return (
        <navbar className="links">
            <navbar.toggle aria-controls="responsive-navbar-nav"></navbar.toggle>
            <navbar.collapse id="responsive-navbar-nav">
                <nav className="global-links-container">
                    <linkcontainer to="/#">
                        <nav.link className="page-links">Home</nav.link>
                    </linkcontainer>
                    <linkcontainer to="/palvelut">
                        <nav.link className="page-links">Services</nav.link>
                    </linkcontainer>
                    <linkcontainer to="/contact_info">
                        <nav.link className="page-links">Contact</nav.link>
                    </linkcontainer>
                    <linkcontainer to="/yrityksille">
                        <nav.link className="page-links">For businesses</nav.link>
                    </linkcontainer>
                    <linkcontainer to="/kirjaudu">
                        <nav.link className="page-links">Log in</nav.link>
                    </linkcontainer>
                </nav>
            </navbar.collapse>
        </navbar>
    );
};

export default Links;
