import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import PrivacyPolicy from "./PrivacyPolicy";
import { useEffect } from "react";

const TermsOfUse = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <helmet>
                <title>Digihappy Terms of Service and Privacy Policy | Digihappy</title>
                <meta name="description" content="Digihappy terms of service and privacy policy. Good service and data protection are genuinely important to us.">
            </helmet>
            <container fluid="" style="{{" textAlign:="" "justify",="" }}="">
                <section className="privacy-policy-text">
                    <h1 className="global-banner-title">Digihappy</h1>
                    <div className="privacy-policy-dates-container ">
                        <div className="privacy-policy-header privacy-policy-dates-left">Digihappy Terms of Service and Privacy Policy</div>
                    </div>
                    <div className="privacy-policy-header text-for-companies-first">
                        To make your reading a bit easier, we think the following are the most important terms for you:
                        <h2 className="global-privacy-header">SENIOR TABLET AS A SERVICE</h2>
                        <ol>
                            <li>You pay a monthly fee for the full service. The fee is charged in 3-month periods.</li>
                            <li>The service can always be cancelled at least 7 days before the start of the new (3-month) service period.</li>
                            <li>
                                As a rule, the service includes everything: a ready-to-use tablet, stand, long cable, protective film, connection, data. Video calls with Digihappy app. Apps and settings ready. Personal
                                start-up advice, IT support, updates and remote management.
                            </li>
                            <li>The equipment and accessories are from Digihappy. Digihappy is responsible for ensuring that you have a working device.</li>
                            <li>
                                Ongoing IT support and remote management is included. However, the number of hours per month of continuous free personalised IT support may be limited and may vary between different versions of the service. This information
                                are included in the service descriptions on the website. In the event of a malfunction of a device or service related to us, you will always be free of charge.
                            </li>
                        </ol>
                        <h2 className="global-privacy-header">SENIOR PHONE AS A SERVICE</h2>
                        <ol>
                            <li>
                                You pay a phone and a personal installation and initial consultation fee at the beginning. You pay a monthly service fee. You can also get a subscription for a monthly fee from Digihappy, or you can use your own subscription.
                                Digihappy's subscription details are in the service descriptions.
                            </li>
                            <li>You can always cancel your service and subscription at least 7 days before the start of the new (1 month) period.</li>
                            <li>
                                The service includes a ready-to-use phone with all the apps and settings. Video calls with Digihappy app. Ongoing it support, updates and remote management. Personal initial advice at the start,
                                to help you set up your phone.
                            </li>
                            <li>
                                The phone and accessories are yours. If you stop using the Digihappy service, we will remove remote management, custom home screen, features, settings and your Digihappy video calling account. Your phone will work as normal
                                phone.
                            </li>
                            <li>
                                Ongoing IT support and remote management is included. However, the number of hours per month of continuous free personal IT support may be limited. This information can be found in the service descriptions on the website. In the case of
                                a malfunction of a device or service related to us, you will always be free of charge to have it resolved.
                            </li>
                        </ol>
                        <h2 className="global-privacy-header">SECURITY BRACELET AS A SERVICE</h2>
                        <ol>
                            <li>You pay a security bracelet and a personal installation and initial consultation fee at the beginning. You pay a monthly service and subscription fee.</li>
                            <li>You can always cancel your service and subscription at least 7 days before the start of the new (1 month) period.</li>
                            <li>
                                The service includes a connection (needed for the security bracelet to work, i.e. positioning and voice connection), which are not dependent on a mobile phone, plus ongoing IT support and updates. Initially, a personal
                                initial personalised advice to help you set up the bracelet.
                            </li>
                            <li>The safety bracelet and accessories are yours. Digihappy can buy it back from you at a price to be agreed if it is in good condition and has been used for less than 12 months.</li>
                            <li>
                                Ongoing IT support is included in the service. However, the number of hours per month of continuous free personal IT support may be limited. This information can be found in the service descriptions on the website. In the case of a device
                                or service is malfunctioning, you will always be free of charge to have it resolved.
                            </li>
                        </ol>
                    </div>

                    <div className="privacy-policy-header text-for-companies-first">
                        <h2 className="global-privacy-header">DATA PROTECTION</h2>
                        Key information on data protection:
                        <ol>
                            <li>The purpose of the information provided to us by the customer (such as first and last name and contact details) is to contact customers, provide the service ordered and maintain the customer relationship.</li>
                            <li>The data will not be transferred to other parties unless this is necessary for the technical implementation of the service. In such cases, we will ensure an adequate level of data protection as required by law.</li>
                            <li>The databases where our customer information is stored are strictly protected. We keep customer data only for the time necessary.</li>
                            <li>The customer has the right to check what information about him/her has been stored in the register.</li>
                        </ol>
                    </div>

                    <div className="privacy-policy-header">For customers of the service</div>
                    <div className="text-for-companies-first">
                        <p>
                            These Terms of Use and Service are intended for you who purchase our services. The customer is often a person close to the senior, but the customer can also be the senior themselves. You can find the terms of use and service on this website
                            and for more information, please contact our Customer Service team, whose contact details are on our website.
                        </p>
                        <p>If you have a trial or test period, the service is free of charge for the duration of the period and does not bind you to further subscriptions. The trial or test period may be subject to a delivery charge.</p>
                    </div>

                    <div className="privacy-policy-header">Creation of a service contract, customer account and family account</div>

                    <div className="text-for-companies-first">
                        <p>
                            A service contract is created when you register for the Digihappy service on our website and indicate that you accept the terms and conditions or subscribe to our service, for example by email or telephone. We create a customer account for you
                            For tablet and phone services, you also manage a senior account and can add five other family members or friends whose contact details are uploaded to your device. Each loved one has their own account
                            on our service. This package is called a family account and is included in these services.
                        </p>
                        <p>By using the Digihappy Service, you also agree to be bound by these Terms and Conditions. These terms and conditions are valid for the entire duration of the service contract.</p>
                    </div>

                    <div className="privacy-policy-header">Service</div>

                    <div className="text-for-companies-first">
                        <p>Our service is designed to make it easier for the senior you care about to stay in touch with you and other loved ones, and to facilitate their use of digital services.</p>
                        <p>
                            There are different versions of the services to ensure that every senior and family gets the best possible service to meet their needs. Details of the versions are available on our website. Unlimited online access included
                            some of the services. Unlimited data on the SIM card is, of course, only for the senior and only for the senior's device.
                        </p>
                        <p>
                            We may also offer you other additional services or products free of charge or for a fee. For example, our customers can subscribe to the Celia audiobook service, in which case they also commit to Celia's
                            terms of service.
                        </p>
                        <p>
                            The Digihappy service includes easy video calls with the Digihappy app. Video call auto-answer is available on tablets and phones. This feature is optional. The service is available at
                            at the subscription stage of the service, the customer chooses which features, such as auto-answer and language, and applications they want to have in the service. The default option is the most popular choice, i.e. automatic answering on a tablet.
                            on the tablet and one-click response on the phone (non-automatic), Finnish language and the most popular apps in the age group. The subscriber of the service, i.e. the customer, usually a close relative of the senior citizen or the senior citizen, answers
                            that he/she has the right to choose an automated response, if applicable. If the customer wishes to change his or her choice, he or she can do so by informing Digihappy it support, which can change the choice to
                            by means of remote management. If the customer is a care provider, the care provider is responsible for ensuring that they have the permission of the customer or their authorised representative to make an automated answer choice. Digihappy
                            advises service providers.
                        </p>
                    </div>

                    <div className="privacy-policy-header">Delivery of the service</div>

                    <div className="text-for-companies-first">
                        <p>
                            We deliver the physical equipment to the customer's home or directly to the care or service home in the context of joint deliveries, as agreed. The equipment is installed as ready for use as possible, and
                            the initial consultation will include the completion of any essential personal settings, depending on the service. We will also install the necessary applications for the remote management service, so that we can provide IT support and
                            carry out updates.
                        </p>
                    </div>

                    <div className="privacy-policy-header">Service fee and duration of the contract</div>

                    <div className="text-for-companies-first">
                        <p>The service fees and the duration of the contract are set out at the beginning of this text for each service.</p>
                    </div>

                    <div className="privacy-policy-header">Payment, billing and prices</div>

                    <div className="text-for-companies-first">
                        <p>The prices for the service, additional services and additional products are available on our website. Prices are subject to change. However, we will notify you of any price changes before we apply them.</p>
                        <p>
                            If your payment is overdue by more than 14 days, we have the right to suspend the service and charge interest on overdue payments at the statutory rate, as well as a reasonable reminder fee.
                            of the payment reminder.
                        </p>
                        <p>
                            When ordering by card payment, our payment partner is Stripe. Stripe is a secure and reliable payment method.{""}
                           <a className="linkki" href="https://www.forbes.com/advisor/business/software/stripe-review/" target="_blank" rel="noreferrer">
                                Forbes Financial Journal </a>
                            {" "}
                            Stripe was selected as one of the best payment partners for online payments. By ordering by card, you also agree to Stripe's terms of use, which you can read in more detail{" "}
                           <a className="linkki" href="https://stripe.com/en-fi/ssa" target="_blank" rel="noreferrer">
                                here
                           </a>
                            .
                        </p>
                    </div>

                    <div className="privacy-policy-header">Service availability and support</div>

                    <div className="text-for-companies-first">
                        <p>There are several support channels for you and your loved ones, such as email, phone, WhatsApp.</p>
                        <p>
                            We always aim to respond to your support requests as soon as possible and within 24 hours on weekdays at the latest. If there is a delay in our service due to sudden or force majeure, we will endeavour to inform you of this.
                            us by means of a support request response message.
                        </p>
                        <p>
                            If there is a problem with the service that is the result of a previously unknown fault or feature of a third party service provider independent of us, or a disruption, such as a fault in the operator's network services,
                            we will restore the service as soon as possible after the disruption to the third party service provider has passed.
                        </p>
                        <p>Of course, you have consumer protection under Finnish law. We provide you with the service, additional services and additional products as is, without any expressed or implied warranties.</p>
                    </div>

                    <div className="privacy-policy-header">Cancellation of subscription</div>

                    <div className="text-for-companies-first">
                        <p>
                            You can cancel your subscription up to 7 calendar days before the start of the new billing period, in which case you will no longer be charged for the new billing period. For a service where Digihappy owns the equipment, provided that
                            You have returned to us the equipment covered by the Service, with all its accessories. The return will be made by post and you will be responsible for the postage of the return. We will collect the tablet device and stand for return to
                            reuse or, if necessary, recycle them appropriately. This way we save the environment and are a responsible company.
                        </p>
                    </div>

                    <div className="privacy-policy-header">Customer responsibility for equipment and accessories that are DIgihäppyn</div>

                    <div className="text-for-companies-first">
                        <p>
                            For your part, please take the best possible care of your tablet, cradle and SIM card, within your means. You or a loved one should not use the Digihappy service in a way that is contrary to the terms of use, good manners or
                            Finnish law.
                        </p>
                    </div>

                    <div className="privacy-policy-header">Changes to the service</div>

                    <div className="text-for-companies-first">
                        <p>
                            We're constantly improving our service, for example by running new updates or installing or replacing new tried and tested apps on your tablet. So we may add, change or remove
                            features, services or hours of service. We will notify you of material changes to the service. We may make minor changes without notice.
                        </p>
                        <p>If we stop providing the service, which is not our aim, we will give you at least 1 month's notice and refund any unused portion of the service fee.</p>
                    </div>

                    <div className="privacy-policy-header">Changes to the contractual conditions</div>

                    <div className="text-for-companies-first">
                        <p>
                            We will update the terms and conditions as the service evolves. We will publish the latest version on our website. You will be notified of any changes that we consider to be material. By continuing to use the service with the latest
                            terms and conditions, we will take this to mean that you have accepted the changes.
                        </p>
                        <p>We will notify you of any material changes to the terms and conditions 1 month before they come into force. Within 2 weeks of being informed of the change, you have the right to terminate the contract with immediate effect.</p>
                    </div>

                    <div className="privacy-policy-header">Intellectual property rights</div>

                    <div className="text-for-companies-first">
                        <p>
                            All intellectual property rights in the Service, its content, features and functionality, and any subsequent changes thereto, belong to us. For the duration of the agreement, you and your loved ones are granted the right to
                            use the Service in accordance with the Agreement and the terms of the Agreement. Any copying, reproduction, retransmission, distribution or use of the Service or any part or parts of the Service other than in accordance with these terms and conditions is prohibited.
                            in accordance with these terms and conditions is prohibited.
                        </p>
                    </div>

                    <div className="privacy-policy-header">Applicable law and dispute resolution</div>

                    <div className="text-for-companies-first">
                        <p>
                            We will always try to find a common solution with you by mutual agreement. In the unlikely event that we end up in a situation that we cannot agree on together, Finnish law will apply to the terms of the contract. You always have the right to
                            you can always contact the Consumer Advice Centre and the Consumer Disputes Board and, in extreme cases, disputes will be settled by the General Civil Court.
                        </p>
                    </div>
                </section>
            </container>
            <privacypolicy></privacypolicy>
        </>
    );
};

export default TermsOfUse;
