import { Card, Col, Container, Row } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";

import TextOnImage from "../../components/TextOnImage";
import texts from "../../texts.js";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAlertsRequest } from "../../redux/actions";
import { AppState } from "../../redux/types";
import { Helmet } from "react-helmet";
import homepage from "../../images/homepage.jpg";
import "./home.css";
import HomeCarousel from "../../components/carousel/Carousel";
import { VideoContainer } from "../../components/layout/containers/Container";
import Button from "../../components/button/Button";

const styles = {
    customBody: {
        // backgroundColor: 'rgb(247 249 248)',
        border: "none",
    },
};

const Home = () => {
    const dispatch = useDispatch();

    const currentDate = Date.now();

    useEffect(() => {
        dispatch(getAlertsRequest());
        return () => {};
    }, [dispatch]);

    const resources = useSelector((state: AppState) => state.alerts);
    const { list } = resources;

    const [video, setVideo] = useState(false);

    let title = "Digihappy | Senioripuhelin, senioritabletti ja turvaranneke palveluna ";
    let description = "Digihappy on helppo senioritabletti, senioripuhelin tai turvaranneke palveluna. Videopuhelut ilman kosketusta. It-tuki.";
    if (window.location.host.split(".")[0] === "www") {
        title += " | WWW versio";
        description += " - WWW versio.";
    } else {
        description += ".";
    }

    const products = [
        {
            title: "SENIORITABLETTI",
            image: "/assets/icons/tablet.png",
            description: "Helpoin videoyhteys seniorille ja perheelle. Videopuhelut avautuvat myös itsestään. Kivaa tekemistä yksilöllisesti.",
            items: [
                "Muokattu Samsung-tabletti ja kotinäkymä",
                "Liittymä, jalusta ",
                "Videopuhelut myös ilman kosketusta Digihappy apilla",
                "Turvallinen perhetili videopuheluihin",
                "Halutut apit, kuten äänikirjat, uutiset, pasianssi",
                "Kaikki valmiina palveluna ja jatkuva it-tuki!",
            ],
        },
        {
            title: "SENIORIPUHELIN",
            image: "/assets/icons/mobile.png",
            description: "Paras älypuhelin seniorille. Kaikki tarpeellinen, ei turhaa.",
            items: [
                "Muokattu Samsung-puhelin ja kotinäkymä ",
                "Liittymä, jalusta",
                "Videopuhelut myös ilman kosketusta Digihappy apilla",
                "Turvallinen perhetili videopuheluihin",
                "Halutut toiminnot, normaalit puhelut, tekstiviestit, apit",
                "Kaikki valmiina palveluna ja jatkuva it-tuki! ",
            ],
        },
        {
            title: "TURVARANNEKE",
            image: "/assets/icons/watch.png",
            description: "Paras turva vanhukselle. Napin painalluksella hälytys ja puheyhteys. Gps-paikannus.",
            items: ["Turvaranneke tai kaulamalli", "Liittymä. Toimii ilman kännykkää.", "Paikantava turvaranneke ja turvapuhelin.", "Hälytys seniorin poistuessa alueelta tai kaatuessa", "Kaikki valmiina palveluna ja it-tuki!"],
        },
    ];

    const carouselItems = [
        {
            title: "Mitä asiakkaamme sanovat?",
            content: '"Mummu on aina ilahtunut videopuheluista ja hänen ei ole tarvinnut osata tehdä mitään"',
            author: "Tytär, joka asuu Ruotsissa.",
        },
        {
            title: "Mitä asiakkaamme sanovat?",
            content: '"Isäni rauhoittui, kun hän kuunteli äänikirjoja Digihappyn avulla"',
            author: "Poika, jonka isä oli ennen sairastumistaan tottunut lukemaan paljon kirjoja.",
        },
        {
            title: "Mitä asiakkaamme sanovat?",
            content: '"Digihappy helpottaa paljon, kun voimme videopuheluilla varmistaa, että isämme ottaa lääkkeensä"',
            author: "Perhe, jonka jäsenet soittavat videopuhelun vuoropäivinä yksin asuvalle 85- vuotiaalle isälleen.",
        },
        {
            title: "Mitä asiakkaamme sanovat?",
            content: '"Digihappy on tuonut meille paljon iloa. Soitin miehelleni sen avulla videopuhelun kolmesti päivässä. Olen suositellut sitä ystävillenikin!"',
            author: "85- vuotias rouva, joka soitti videopuheluita iäkkäälle miehelleen Digihappylla.",
        },
        {
            title: "Mitä asiakkaamme sanovat?",
            content: '"Olemme etsineet vuoden tällaista palvelua"',
            author: "Tytär, joka soittaa päivittäin Digihappy videopuhelun äidilleen.",
        },
        {
            title: "Mitä asiakkaamme sanovat?",
            content: '"Digihappyn palvelu on ystävällistä ja asiantuntevaa. Apua saa henkilökohtaisesti."',
            author: "Poika, jonka äidille tehtiin henkilökohtaisia asetuksia tablettiin.",
        },
        {
            title: "Mitä asiakkaamme sanovat?",
            content: '"Hankimme iäkkäälle isälleni Digihappyn alkuvuodesta - se on aivan loistava"',
            author: "Tytär, joka puhuu videopuheluja 90- vuotiaan isän kanssa.",
        },
        {
            title: "Mitä asiakkaamme sanovat?",
            content: '"Turvapuhelimen avulla minut pystyttiin tavoittamaan ja paikantamaan helposti, kun olin kaatunut."',
            author: "Mies, joka soitti turvarannekkeella kaaduttuaan.",
        },
    ];
    return (
        <>
            <helmet>
                <title>{title}</title>
                <meta name="description" content="{description}">
            </helmet>
            <textonimage 56="" height="{`calc(100vh" -="" ${="" +="" *="" list?.filter((el)=""> {
                            // @ts-ignore
                            return (!el.start || new Date(el.start) <= currentDate) && (!el.end || new Date(el.end) >= currentDate);
                        })?.length -
                    1
                }px)`}
                width="100vw"
                text1={texts[7].text1}
                text2={texts[7].text2}
                backgroundImage={homepage}
                backgroundSize="cover"
                backgroundPosition="center top"
                backgroundRepeat="no-repeat"
                imgClassName="girlpic"
                className1="girlpic-text"
                className2="girlpic-link"
            />

            <container className="text-container">
                <h5 className="header-under-pic">Digihappy for senior citizens</h5>
                <div className="header-div">
                    <i className="fas fa-users users-ikoni"></i>
                    <h1 className="header-text first-text" style="{{" textAlign:="" "center"="" }}="">
                        Digihappy - the best tablet, phone and security bracelet for seniors. Touchless video calls and fun things to do. Easy alerts. Always ready service and <span style="{{" whiteSpace:="" "nowrap"="" }}="">it support.</span>{""}
                       <br/>
                        Finnish, Swedish, English.
                    </h1>
                </div>
            </container>
            <section className="image-rows">
                <div className="home-grid-container">
                    {products.map((product, key) => (
                        <div className="home-grid-column" key="{key}">
                            <productcard product="{product}"></productcard>
                        </div>
                    ))}
                </div>
            </section>
            <section className="home-carousel-container">
                <homecarousel items="{carouselItems}"></homecarousel>
            </section>
            <section className="image-rows">
                <row noGutters="">
                    <col sm="{12}" md="{12}" lg="{6}" className="home-column">
                        <card className="home-image-card manfamily">
                            <card.img src="{&quot;/assets/images/home/helpot-videopuhelut-senioripuhelimella.webp&quot;}" variant="top" height="100vh" width="auto" alt="Uusi Samsung Galaxy S10 5G ja kosketusnäyttökynä pöydällä. "></card.img>

                            <card.body style="{styles.customBody}">
                                <card.title as="div" className="image-text text-center">
                                    Easy video calling on your phone and tablet. No-touch response if desired. Call by pressing a picture of a nearby person.
                                </card.title>
                                <card.text as="div" className="image-link text-center">
                                    <hashlink to="/senioripuhelin" className="lue-lisää-linkki">
                                        <strong>Read more</strong>
                                    </hashlink>
                                </card.text>
                            </card.body>
                        </card>
                    
                    <col sm="{12}" md="{12}" lg="{6}" className="home-column">
                        <card className="home-image-card woman2">
                            <card.img src="{&quot;/assets/images/home/peli-senoritabletilla.webp&quot;}" variant="top" height="550" width="auto" alt="Seniori pelaa korttipeliä tabletilla"></card.img>

                            <card.body style="{styles.customBody}">
                                <card.title as="div" className="image-text text-center">
                                    Customized home view and features on tablet and phone. Different options for seniors who cannot touch the device and for active seniors.
                                </card.title>

                                <card.text as="div" className="image-link text-center">
                                    <hashlink to="/palvelut" className="lue-lisää-linkki">
                                        <strong>Read more</strong>
                                    </hashlink>
                                </card.text>
                            </card.body>
                        </card>
                    
                </row>
                <section className="between-image-rows"></section>
                <row noGutters="">
                    <col sm="{12}" md="{12}" lg="{6}" className="home-column">
                        <card className="home-image-card it-woman">
                            <div className="global-credit-image-container">
                                <card.img src="{&quot;/assets/images/home/paras-turvaranneke.webp&quot;}" variant="top" height="550" width="auto" alt="Henkilö pitelee ovenkahvaa, ranteessaan musta kello."></card.img>
                                <span className="global-credit-text">PICTURE: : Björn Johansson/Posifon</span>
                            </div>
                            <card.body style="{styles.customBody}">
                                <card.title as="div" className="image-text text-center">
                                    At the touch of a button, an alarm and voice contact with your loved ones. Gps tracking, not tied to the senior's mobile phone. Area-loss and battery depletion alarm.
                                </card.title>
                                <card.text as="div" className="image-link text-center">
                                    <hashlink to="/turvaranneke" className="lue-lisää-linkki">
                                        <strong>Read more</strong>
                                    </hashlink>
                                </card.text>
                            </card.body>
                        </card>
                    
                    <col sm="{12}" md="{12}" lg="{6}" className="home-column">
                        <card className="home-image-card doctor1">
                            <card.img src="{&quot;/assets/images/home/4.jpg&quot;}" variant="top" height="550" width="auto"></card.img>

                            <card.body style="{styles.customBody}">
                                <card.title as="div" className="image-text text-center">
                                    A service with ready-made settings, contacts, home view, apps and subscription. Initial advice, ongoing IT support, remote management and security.
                                </card.title>
                            </card.body>
                        </card>
                    
                </row>
                <section className="between-image-rows"></section>
            </section>

            {/*<textonimage* }="" {="" *="" text1="{texts[0].text1}*/}" text2="{texts[0].text2}*/}" text3="{texts[0].text3}*/}" link="{texts[0].link}*/}" height="120vh" width="100vw" backgroundImage="{*/}" 'https:="" senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com="" bottompic.jpg'*="" }*="" backgroundSize="cover" backgroundPosition="center center" backgroundRepeat="no-repeat" backgroundAttachment="" imgClassName="bottompic" className1="bottompic-text" className2="bottompic-link" className3="bottom-link"></textonimage*>*/}

            <section 48="" className="image-rows" style="{{" marginBottom:="" }}="">
                <row className="justify-content-md-center">
                    <col sm="{12}" md="{12}" lg="{8}" className="home-column">
                        <card className="home-image-card doctor1">
                            <card.body style="{styles.customBody}">
                                <card.title as="div" className="image-text text-center" style="{{" marginBottom:="" "1.6rem",="" }}="">
                                    <button onClick="{()" ==""> {
                                            if (!video) {
                                                setVideo(true);
                                            }
                                        }}
                                    >
                                        Katso video
                                    </button>
                                </card.title>
                                <videocontainer>
                                    {video && (
                                        <iframe src="{`https://www.youtube.com/embed/bg_ORIbrHCg?autoplay=1&rel=0&color=white&modestbranding=1`}" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" title="Digihappy Video" className="global-youtube-video"></iframe>
                                    )}
                                    <div 0="" className="global-video-overlay" style="{{" opacity:="" video="" ?="" :="" 1,="" pointerEvents:="" "none"="" "auto"="" }}="">
                                        <img id="video-cover" src="{&quot;/assets/images/home/digihappy" videon="" pikkukuva.png"}="" alt="Digihappy Video Snapshot" style="{{" height:="" "auto",="" width:="" "100%",="" }}="">
                                        <button id="play" className="play-btn" onClick="{()" ==""> {
                                                setVideo(true);
                                            }}
                                        >
                                            <i className="fas fa-play global-hover-zoom" style="{{" color:="" "#ffffff",="" fontSize:="" "min(6rem,="" 15vw)",="" }}=""></i>
                                        </button>
                                    </div>
                                </videocontainer>
                            </card.body>
                        </card>
                    
                </row>
            </section>
        </>
    );
};

const ProductCard = ({ product }: { product: { title: string; image: string; description: string; items: string[] } }) => {
    return (
        <card className="home-card-2024">
            <card.title className="image-text text-center home-card-title">{product.title}</card.title>
            <img src="{product.image}" alt="T" className="home-card-image">
            <card.text className="image-text text-center home-card-text home-card-description">{product.description}</card.text>
            <card.text as="div" className="image-text text-center home-card-text home-card-list">
                {product.items.map((item, key) => (
                    <div className="home-card-list-item" key="{key}">
                        <i className="fas fa-check-circle"></i>
                        <span className="image-text home-card-text">{item}</span>
                    </div>
                ))}
            </card.text>
        </card>
    );
};

export default Home;
</textonimage>