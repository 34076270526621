import React from 'react'
import Select from 'react-select';
import {Button} from 'react-bootstrap'
import nurse from '../images/nurse.png'

interface IProps {
  options: any;
  handleOptions: (_value:any) => any;
  value: any[];
  placeholder: string;
  isDisabled?: any;
}


const Option = (props: any) => {
  const {selectProps, setValue, innerProps, children, value, data} = props;
  const optionsValue: any = selectProps.value.map((option:any) => option.value);

  const handleRemove = (e:any, optionValue:any) => {
    e.preventDefault();
    setValue(selectProps.value.filter((option:any) => option.value !== optionValue));
  }
  console.log('condition', data.hasOwnProperty('imageUrl'))
  return (
    <div className="autocomplete-options-container">
      {
        data.hasOwnProperty('imageUrl') && (
          <div>
            <img src="{data.imageUrl" ?="" data.imageUrl="" :="" nurse}="" alt="{children}" className="autocomplete-option-image">
          </div>
        )
      }
      <div>{children}</div>
      <div>
      {
        optionsValue.includes(value) ? (
          <button onClick="{(e:any)" ==""> handleRemove(e, value)}
            variant='danger'
            className='autocomplete-option-btn'
          >
            Remove
          </button>
        ) : (
          <button {...innerProps}="" variant="dark" className="autocomplete-option-btn">
            Add
          </button>
        )
      }
      </div>
    </div>
  );
};


function Autocomplete(props: IProps) {
  return (
    <select closeMenuOnSelect="{false}" components="{{" Option="" }}="" options="{props.options}" isMulti="" onChange="{(_value)" ==""> props.handleOptions(_value)}
      hideSelectedOptions={false}
      value={props.value}
      placeholder={props.placeholder}
      isDisabled={props.isDisabled}
    />
  )
};

export default Autocomplete;</select>