import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Col, Container, Form, Row} from 'react-bootstrap'

import {updateUserRequest,} from '../redux/actions'
import Message from './Message'
import Loader from './Loader'
import {AppState} from '../redux/types'

const MemberForm = ({onSelect, key}: any) => {
    const member = useSelector((state: AppState) => state.user)
    const {error, loading} = member;
    const [chosenService, setChosenService] = useState(member?.chosenService || '');
    const [serviceLang, setServiceLang] = useState(member?.tabletLanguage || '');
    const [autoAnswer, setAutoAnswer] = useState(member?.autoAnswer || '');
    const [option, setOption] = useState(member?.serviceOption || '');
    const relativeDoneEmailId = useSelector((state: AppState) => state.categories.category?.attributes.RelativeAccountDone);

    const dispatch = useDispatch()

    useEffect(() => {
        console.log(member?.chosenService)
        console.log(chosenService)
        if (!member?.chosenService || member?.chosenService !== chosenService) {
            if (chosenService === 'huoleton') {
                setServiceLang('suomi');
                setAutoAnswer('true');
                setOption('huoleton1');
            } else if (chosenService === 'helppo') {
                setServiceLang('suomi');
                setAutoAnswer('true');
                setOption('helppo4');
            } else if (chosenService === 'mestari') {
                setServiceLang('suomi');
                setAutoAnswer('false');
            } else if (chosenService === 'suosikkipalvelu') {
                setServiceLang('suomi');
                setAutoAnswer('true');
                setOption('helppo4');
            } else if (chosenService === 'peruspalvelu') {
                setServiceLang('suomi');
                setAutoAnswer('true');
            }
        } else {
            setServiceLang(member?.tabletLanguage);
            setAutoAnswer(member?.autoAnswer);
        }
    }, [chosenService, member])

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault()

        dispatch(updateUserRequest({
            ...member,
            password: '',
            chosenService: chosenService,
            tabletLanguage: serviceLang,
            autoAnswer: autoAnswer,
            serviceOption: option,
            relativeDoneEmailId
        }));

        setTimeout(function () {
            onSelect('tilaus');
        }, 3000);
    }

    const auto_answer = <form.group className="feature_choices-form">
        <label>Opening a video call on a senior's tablet when a family member or carer calls:</label>
        <div style="{{" marginLeft:="" 127,="" }}="">
            <form.check className="radio-button" type="radio" label="{`Videopuhelu" avautuu="" automaattisesti="" ilman="" kosketusta${chosenService="==" 'mestari'="" ?="" '="" (mahdollinen="" Samsung="" tabletissa)'="" :="" ''}`}="" id="true" name="autoAnswer" value="{'true'}" checked="{autoAnswer" =="=" 'true'}="" onChange="{()"> setAutoAnswer('true')}
            />
            <form.check className="radio-button" type="radio" label="{`Videopuhelu" ei="" avaudu="" automaattisesti="" ilman="" kosketusta${chosenService="==" 'mestari'="" ?="" '="" (Samsung="" tai="" Apple)'="" :="" ''}`}="" id="false" name="autoAnswer" value="{'false'}" checked="{autoAnswer" =="=" 'false'}="" onChange="{()"> setAutoAnswer('false')}
            />
        </form.check></form.check></div>
    </form.group>

    const lang = <form.group className="feature_choices-form">
        <label>The language of the tablet:</label>
        <form.check style="{{" display:="" 'inline-block',="" margin:="" 8,="" }}="" className="radio-button" type="radio" label="Suomi" id="suomi" name="serviceLang" value="{'suomi'}" checked="{serviceLang" =="=" 'suomi'}="" onChange="{()"> setServiceLang('suomi')}
        />
        <form.check style="{{" display:="" 'inline-block',="" margin:="" 8,="" }}="" className="radio-button" type="radio" label="Ruotsi" id="ruotsi" name="serviceLang" value="{'ruotsi'}" checked="{serviceLang" =="=" 'ruotsi'}="" onChange="{()"> setServiceLang('swedish')}
        />
    </form.check></form.check></form.group>

    const helppo_options = <form.group className="feature_choices-form">
        <label>Choice of apps:</label>
        <div style="{{" marginLeft:="" 127,="" }}="">
            <form.check className="radio-button" type="radio" label="Videopuhelu" id="helppo1" name="option" value="{'helppo1'}" checked="{option" =="=" 'helppo1'}="" onChange="{()"> setOption('easy1')}
            />
            <form.check className="radio-button" type="radio" label="Videopuhelu, äänikirja, radio" id="helppo2" name="option" value="{'helppo2'}" checked="{option" =="=" 'helppo2'}="" onChange="{()"> setOption('easy2')}
            />
            <form.check className="radio-button" type="radio" label="Videopuhelu, äänikirja, radio, Yle Areena, lehdet" id="helppo3" name="option" value="{'helppo3'}" checked="{option" =="=" 'helppo3'}="" onChange="{()"> setOption('easy3')}
            />
            <form.check className="radio-button" type="radio" label="Videopuhelu, äänikirja, radio, Yle Areena, lehdet, pelit" id="helppo4" name="option" value="{'helppo4'}" checked="{option" =="=" 'helppo4'}="" onChange="{()"> setOption('easy4')}
            />
            <form.check className="radio-button" type="radio" label="Videopuhelu, äänikirja, radio, Yle Areena, lehdet, taide" id="helppo5" name="option" value="{'helppo5'}" checked="{option" =="=" 'helppo5'}="" onChange="{()"> setOption('easy5')}
            />
            <form.check className="radio-button" type="radio" label="Videopuhelu, äänikirja, radio, Yle Areena, lehdet, pelit, taide, Spotify" id="helppo6" name="option" value="{'helppo6'}" checked="{option" =="=" 'helppo6'}="" onChange="{()"> setOption('easy6')}
            />
            <form.check className="radio-button" type="radio" label="Videopuhelu, äänikirja, radio, Yle Areena, lehdet, pelit, taide, Spotify, Whatsapp" id="helppo7" name="option" value="{'helppo7'}" checked="{option" =="=" 'helppo7'}="" onChange="{()"> setOption('easy7')}
            />
        </form.check></form.check></form.check></form.check></form.check></form.check></form.check></div>
    </form.group>

    return (
        <container fluid="">
            <row className="justify-content-md-left">
                <col xs="{12}">
                    {error && <message variant="danger">{error.message}</message>}
                    {loading && <loader></loader>}
                    <h1 className="member-form-header">4 Customise your tablet's features</h1>
                    {member?.id && <div className="feature_choices">
                        <div className="text-for-companies-second">
                            We recommend you fill in this section, but it is not required. The default settings are
                            Finnish, automatic opening of the video call and the service selected (Careless, Easy,
                            Master), the most popular choice of applications. Continue by selecting the service you have purchased or go to
                            go ahead.
                        </div>
                        <form onSubmit="{submitHandler}">
                            <form.group className="feature_choices">
                                <form.check className="radio-button" type="radio" label="Huoleton - palvelu" id="huoleton" name="chosenService" value="huoleton" checked="{chosenService" =="=" 'huoleton'}="" onChange="{()"> setChosenService('huoleton')}
                                />
                                {chosenService === 'carefree' && <div className="feature_choices-option">
                                    {lang}
                                    {auto_response}
                                    <form.group className="feature_choices-form">
                                        <label>Choice of apps:</label>
                                        <div style="{{" marginLeft:="" 127,="" }}="">
                                            <form.check className="radio-button" type="radio" label="Videopuhelu" id="huoleton1" name="option" value="{'huoleton1'}" checked="{option" =="=" 'huoleton1'}="" onChange="{()"> setOption('huoleton1')}
                                            />
                                            <form.check className="radio-button" type="radio" label="Videopuhelu, radio" id="huoleton2" name="option" value="{'huoleton2'}" checked="{option" =="=" 'huoleton2'}="" onChange="{()"> setOption('huoleton2')}
                                            />
                                            <form.check className="radio-button" type="radio" label="Videopuhelu, äänikirja" id="huoleton3" name="option" value="{'huoleton3'}" checked="{option" =="=" 'huoleton3'}="" onChange="{()"> setOption('huoleton3')}
                                            />
                                            <form.check className="radio-button" type="radio" label="Videopuhelu, Yle Areena" id="huoleton4" name="option" value="{'huoleton4'}" checked="{option" =="=" 'huoleton4'}="" onChange="{()"> setOption('huoleton4')}
                                            />
                                            <form.check className="radio-button" type="radio" label="Videopuhelu, radio, äänikirja, Yle Areena" id="huoleton5" name="option" value="{'huoleton5'}" checked="{option" =="=" 'huoleton5'}="" onChange="{()"> setOption('huoleton5')}
                                            />
                                        </form.check></form.check></form.check></form.check></form.check></div>
                                    </form.group>
                                    <div className="feature_choices-text">
                                        <p>
                                            A carefree tablet is the smallest and most affordable. We recommend it only for
                                            applications where you don't need to type on the tablet keyboard.
                                            We carefully select applications for the tablet that are popular, easy to use,
                                            safe and free. We are constantly developing and adding to our range
                                            and make changes where necessary.
                                        </p>
                                        <p>
                                            The free audiobook app is Celian. We can advise you on how to get the service up and running
                                            and get your own login from the library. The service is for older people with
                                            for example, impaired vision, muscle, function or memory.
                                        </p>
                                    </div>
                                </div>}
                                <form.check className="radio-button" type="radio" label="Helppo - palvelu" id="helppo" name="chosenService" value="helppo" checked="{chosenService" =="=" 'helppo'}="" onChange="{()"> setChosenService('easy')}
                                />
                                {chosenService === 'easy' && <div className="feature_choices-option">
                                    {lang}
                                    {auto_response}
                                    {helppo_options}
                                    <div className="feature_choices-text">
                                        <p>
                                            We carefully select apps for your tablet that are popular, easy to use,
                                            safe and free of charge. Examples of games include solitaire, memory games and
                                            chess and magazines HS and IS. We are constantly developing our range and making
                                            on them
                                            and make changes where necessary.
                                        </p>
                                        <p>
                                            The free audiobook app is Celian. We can advise you on how to get the service up and running
                                            and
                                            your own login from the library. The service is for older people with conditions such as
                                            visual, muscular, functional or memory impairments. If you wish to
                                            a single additional application or a paid version of an application, our IT support will
                                            will help. The senior cannot add apps to a lightweight tablet by themselves.
                                        </p>
                                    </div>
                                </div>}
                                <form.check className="radio-button" type="radio" label="Mestari - palvelu" id="mestari" name="chosenService" value="mestari" checked="{chosenService" =="=" 'mestari'}="" onChange="{()"> setChosenService('master')}
                                />
                                {chosenService === 'mestari' && <div className="feature_choices-option">
                                    {lang}
                                    {auto_response}
                                    <div className="feature_choices-text">
                                        <p>
                                            </p><div>
                                                <b>The range of applications on your tablet</b>
                                            </div>
                                            The Master-tablet has carefully selected and pre-installed popular apps for seniors. The tablet is ready to use, including Digihappy video calls, Celia's free audio books, Chrome, radio, camera, Yle Areena, newspapers like HS and IS, games like solitaire, memory and chess, art, Spotify, Whatsapp, Facebook, Gmail and other Google apps. We are constantly developing our options and will make changes where necessary. In a personal initial consultation, we will install the apps you wish and advice how to set up personal accounts such as email and social media profiles. We also advise on how seniors can download apps themselves.
                                        <p></p>
                                        <p>
                                            The free audiobook app is Celian. We can advise you on how to get the service up and running
                                            and get your own login from the library. The service is for older people with
                                            for example, impaired vision, muscle, function or memory.
                                        </p>
                                    </div>
                                </div>}
                                <form.check className="radio-button" type="radio" label="Kotisairaanhoito/Palveluasunto Suosikkipalvelu" id="suosikkipalvelu" name="chosenService" value="suosikkipalvelu" checked="{chosenService" =="=" 'suosikkipalvelu'}="" onChange="{()"> setChosenService('favourite service')}
                                />
                                {chosenService === 'favourite service' && <div className="feature_choices-option">
                                    {lang}
                                    {auto_response}
                                    {helppo_options}
                                </div>}
                                <form.check className="radio-button" type="radio" label="Kotisairaanhoito/Palveluasunto Peruspalvelu" id="peruspalvelu" name="chosenService" value="peruspalvelu" checked="{chosenService" =="=" 'peruspalvelu'}="" onChange="{()"> setChosenService('basic service')}
                                />
                                {chosenService === 'basic service' && <div className="feature_choices-option">
                                    {lang}
                                    {auto_response}
                                </div>}
                            </form.check></form.check></form.check></form.check></form.check></form.group>
                        </form>
                        <div className="feature_choices-text">
                            If you subscribed exclusively to the Digihappy video calling app for senior and family on your own
                            or hospital shared device, this section does not apply to you.
                        </div>

                        <button type="button" className="tallenna" onClick="{submitHandler}">Done</button>
                    </div>}
                
            </row>
        </container>
    )
}
export default MemberForm
