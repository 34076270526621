import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { createServiceRequest, getServicesRequest } from "../redux/actions";
import { AppState } from "../redux/types";
import { LinkContainer } from "react-router-bootstrap";

const ServiceForm = () => {
  const { id } = useParams<{ id: string }>();
  const resources = useSelector((state: AppState) => state.resources);
  const { services } = resources;
  let service = undefined;
  try {
    service = services?.find((o) => o.id === parseInt(id));
  } catch (error) {}
  const [formData, setFormData] = useState({
    id: service ? parseInt(service.id) : -1,
    sortNumber: service?.sortNumber || "",
    name: service?.name || "",
    descriptionFirst: service?.descriptionFirst || "",
    descriptionSecond: service?.descriptionSecond || "",
    price: service?.price || 0,
    category: service?.category || "main",
    shippingCategory: service?.shippingCategory || "",
    isActive: service?.isActive ? 1 : service?.isActive === undefined ? 1 : 0,
    stripeProductId: service?.stripeProductId || "",
    stripeShippingRateId: service?.stripeShippingRateId || "",
    stripeShippingRateIdFree: service?.stripeShippingRateIdFree || "",
    priceType: service?.priceType || "one_time",
    period: service?.period,
    photoShow: service?.photoShow ? 1 : 0,
    photoLink: service?.photoLink || "",
    photoDescription: service?.photoDescription || ""
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state: AppState) => state.user);
  useEffect(() => {
    if (user && (user.isAdmin || user.role === "it_support")) {
      dispatch(getServicesRequest());
    } else {
      history.push("/kirjaudu");
    }
    return () => {};
  }, [dispatch, history, user]);

  const handleChange = (e: React.ChangeEvent<htmlinputelement>) => {
    const { value, name } = e.target;
    console.log(name)
    console.log(value)
    setFormData((prevValue: any) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(
      createServiceRequest({
        id: formData.id === -1 ? undefined : formData.id,
        sortNumber: formData.sortNumber,
        name: formData.name,
        descriptionFirst: formData.descriptionFirst,
        descriptionSecond: formData.descriptionSecond,
        price: formData.price,
        category: formData.category,
        isActive: !!+formData.isActive,
        stripeProductId: formData.stripeProductId,
        shippingCategory: formData.shippingCategory,
        stripeShippingRateId: formData.stripeShippingRateId,
        stripeShippingRateIdFree: formData.stripeShippingRateIdFree,
        priceType: formData.priceType,
        period: formData.period,
        photoShow: !!formData.photoShow,
        photoLink: formData.photoLink,
        photoDescription: formData.photoDescription
      })
    );
    dispatch(getServicesRequest());
    history.push("/admin/services");
  };

  return (
    <container className="register-form-container">
      {/* {error && <message variant="danger">{error}</message>}
          {!error && message && <message variant="success">{message}</message>} */}
      <form onSubmit="{submitHandler}" className="service-form">
        <form.group controlId="service-name">
          <form.label>Sort by number</form.label>
          <form.control type="number" placeholder="Lajittele numero" name="sortNumber" value="{formData.sortNumber}" onChange="{handleChange}" readOnly="{user.readOnly" ||="" !user.isAdmin}=""></form.control>
        </form.group>
        <form.group controlId="service-name">
          <form.label>Service name</form.label>
          <form.control type="text" placeholder="Palvelun nimi" name="name" value="{formData.name}" onChange="{handleChange}" readOnly="{user.readOnly" ||="" !user.isAdmin}=""></form.control>
        </form.group>
        <form.group controlId="service-descriptionFirst">
          <form.label>Service description left column</form.label>
          <form.control as="textarea" rows="{3}" type="text" placeholder="Palvelun kuvaus vasen sarake" name="descriptionFirst" value="{formData.descriptionFirst}" onChange="{handleChange}" readOnly="{user.readOnly" ||="" !user.isAdmin}=""></form.control>
        </form.group>
        <form.group controlId="servie-descriptionSecond">
          <form.label>Service description right column</form.label>
          <form.control as="textarea" rows="{3}" type="text" placeholder="Palvelun kuvaus oikea sarake" name="descriptionSecond" value="{formData.descriptionSecond}" onChange="{handleChange}" readOnly="{user.readOnly" ||="" !user.isAdmin}=""></form.control>
        </form.group>
        <form.group controlId="service-three-month-price">
          <form.label>Three-month price</form.label>
          <form.control type="text" placeholder="Kolmen kuukauden hinta" name="price" value="{formData.price}" onChange="{handleChange}" readOnly="{user.readOnly" ||="" !user.isAdmin}=""></form.control>
        </form.group>
        <form.group controlId="service-category">
          <form.label>Category</form.label>
          <form.control as="select" name="category" value="{formData.category}" onChange="{handleChange}" disabled="{user.readOnly" ||="" !user.isAdmin}="">
            <option value="main">Main</option>
            <option value="main2">Main2</option>
            <option value="additional">Additional</option>
            <option value="shipping">Shipping</option>
          </form.control>
        </form.group>
        {formData.category === "shipping" && (
          <form.group controlId="service-shipping-category">
            <form.label>Shipping category</form.label>
            <form.control as="select" name="shippingCategory" value="{formData.shippingCategory}" onChange="{handleChange}" disabled="{user.readOnly" ||="" !user.isAdmin}="">
              <option value="">None</option>
              <option value="direct">Direct</option>
              <option value="posti">Mail to</option>
            </form.control>
          </form.group>
        )}
        <form.group controlId="service-isactive">
          <form.label>Active</form.label>
          <form.control as="select" name="isActive" value="{formData.isActive}" onChange="{handleChange}" disabled="{user.readOnly" ||="" !user.isAdmin}="">
            <option value="{1}">Yes</option>
            <option value="{0}">No</option>
          </form.control>
        </form.group>
        {formData.category !== "shipping" && (
          <form.group controlId="service-stripeproductid">
            <form.label>Stripe product ID</form.label>
            <form.control type="text" placeholder="Stripe product ID" name="stripeProductId" value="{formData.stripeProductId}" onChange="{handleChange}" readOnly="{user.readOnly" ||="" !user.isAdmin}=""></form.control>
          </form.group>
        )}
        {formData.category === "shipping" && (
          <form.group controlId="service-stripeshippingrateid">
            <form.label>Stripe shipping rate ID (paid)</form.label>
            <form.control type="text" placeholder="Stripe shipping rate ID (paid)" name="stripeShippingRateId" value="{formData.stripeShippingRateId}" onChange="{handleChange}" readOnly="{user.readOnly" ||="" !user.isAdmin}=""></form.control>
          </form.group>
        )}
        {formData.category === "shipping" && (
          <form.group controlId="service-stripeshippingrateidfree">
            <form.label>Stripe shipping rate ID (free)</form.label>
            <form.control type="text" placeholder="Stripe shipping rate ID (free)" name="stripeShippingRateIdFree" value="{formData.stripeShippingRateIdFree}" onChange="{handleChange}" readOnly="{user.readOnly" ||="" !user.isAdmin}=""></form.control>
          </form.group>
        )}
        {formData.category !== "shipping" && (
          <form.group controlId="service-pricetype">
            <form.label>Price type</form.label>
            <form.control as="select" name="priceType" value="{formData.priceType}" onChange="{handleChange}" disabled="{user.readOnly" ||="" !user.isAdmin}="">
              <option value="one_time">One-time</option>
              <option value="recurring">recurring</option>
            </form.control>
          </form.group>
        )}
        {formData.priceType === "recurring" && (
            <form.group controlId="service-period">
              <form.label>Period (month)</form.label>
              <form.control type="number" as="input" name="period" min="{1}" max="{60}" value="{formData.period" ||="" 1}="" onChange="{handleChange}" disabled="{user.readOnly" !user.isAdmin}=""></form.control>
            </form.group>
        )}
        <form.group controlId="service-photoshow">
          <form.label>Show photo</form.label>
          <form.control as="select" name="photoShow" value="{formData.photoShow}" onChange="{handleChange}" disabled="{user.readOnly" ||="" !user.isAdmin}="">
            <option value="{1}">Yes</option>
            <option value="{0}">No</option>
          </form.control>
        </form.group>
        {!!+formData.photoShow && (
            <form.group controlId="service-photolink">
              <form.label>Photo link</form.label>
              <form.control type="text" placeholder="https://..." name="photoLink" value="{formData.photoLink}" onChange="{handleChange}" readOnly="{user.readOnly" ||="" !user.isAdmin}=""></form.control>
            </form.group>
        )}
        {!!+formData.photoShow && (
            <form.group controlId="service-photodescription">
              <form.label>Photo description</form.label>
              <form.control type="text" placeholder="Specify an alternate text for an image" name="photoDescription" value="{formData.photoDescription}" onChange="{handleChange}" readOnly="{user.readOnly" ||="" !user.isAdmin}=""></form.control>
            </form.group>
        )}
        <row>
          <col>
            <linkcontainer to="{`/admin/services`}">
              <button className="tallenna paymentmethod-back-button">
                Return
              </button>
            </linkcontainer>
          
          {!user.readOnly && user.isAdmin && (
            <col style="{{" textAlign:="" "right",="" }}="">
              <button className="create-service-button tallenna" type="submit">
                Save
              </button>
            
          )}
        </row>
      </form>
    </container>
  );
};

export default ServiceForm;
</htmlinputelement>