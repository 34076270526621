const Relatives = ({ member, index, role }: any) => {
  return (
    <div className="relative-members">
      {role === 'member' && (
        <>
          <p className="role">
            <strong>Relatives and friends</strong>
          </p>
          <li className="member">{member}</li>
        </>
      )}

    </div>
  )
}

export default Relatives
