import {useDispatch, useSelector} from 'react-redux'
import {Alert, Col, Row} from 'react-bootstrap'

import Group from './Group'
import Intro from './Intro'
import OrderInfo from './OrderInfo'
import {AppState} from '../redux/types'
import {ResetShoppingcart} from "../redux/actions";

const Order = ({onSelect}: any) => {
    const dispatch = useDispatch()
    const user = useSelector((state: AppState) => state.user)
    const selectedServices = useSelector(
        (state: AppState) => state.cart.inCart
    )
    //const orders = useSelector((state: AppState) => state.user.orders)
    //const isPaid = orders?.map((o: any) => o.isPaid)
    // @ts-ignore
    const order_id = new URLSearchParams(window.location.search).get("order_id")
    // console.log(order_id)
    if (!!order_id && selectedServices?.length) {
        dispatch(ResetShoppingcart())
    }
    const {orders} = user;
    const last_order = orders?.length ? orders.sort((a, b) => b.id - a.id)[0] : null;

    return (
        <row>
          <col sm="{12}" className="order-col">
            {last_order?.ready &&
              <alert style="{{" margin:="" "0="" 35px",="" textAlign:="" "center",="" fontWeight:="" "bold",="" color:="" "white",="" fontSize:="" "1.2rem",="" backgroundColor:="" "#8184a7",="" }}="">
                Your order was successful - follow your email
              </alert>
            }
          
            <col md="{6}" sm="{12}" className="order-col">
                <orderinfo></orderinfo>
            

            <col md="{6}" sm="{12}" className="variation-col">
                {user && user.group && user.group.members && user.group.members.length > 1 ? (
                    <group></group>
                ) : (
                    <intro onSelect="{onSelect}/">)}
            
        </intro></row>
    )
}

export default Order
