import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Col, Container, Form, Row} from 'react-bootstrap'
import {useHistory, useParams} from "react-router-dom";
import {createAlertRequest, getAlertsRequest} from '../redux/actions'
import {AppState} from "../redux/types";
import {LinkContainer} from "react-router-bootstrap";

const AlertForm = () => {
    const {id} = useParams<{ id: string }>()
    console.log(id)
    const resources = useSelector((state: AppState) => state.alerts)
    const {list} = resources
    console.log(list)
    let alert = undefined;
    try {
        alert = list?.find(o => o.id === parseInt(id));
    } catch (error) {
    }
    console.log(alert)
    const [formData, setFormData] = useState({
        id: alert ? parseInt(alert.id) : -1,
        text: alert?.text || '',
        start: alert?.end ? (new Date(alert?.start)).toISOString().split('.')[0] : undefined,
        end: alert?.end ? (new Date(alert?.end)).toISOString().split('.')[0] : undefined,
        createdAt: undefined,
        updatedAt: undefined
    })

    const dispatch = useDispatch()
    const history = useHistory()

    const user = useSelector((state: AppState) => state.user)
    useEffect(() => {
        if (user && user.isAdmin) {
            dispatch(getAlertsRequest())
        } else {
            history.push('/kirjaudu')
        }
        return () => {
        }
    }, [dispatch, history, user])

    const handleChange = (e: React.ChangeEvent<htmlinputelement>) => {
        const {value, name} = e.target
        console.log(value)
        console.log(name)
        setFormData((prevValue: any) => {
            return {
                ...prevValue,
                [name]: value,
            }
        })
    }

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault()
        dispatch(createAlertRequest({
            id: formData.id === -1 ? undefined : formData.id,
            text: formData.text,
            start: formData.start,
            end: formData.end,
            createdAt: formData.createdAt,
            updatedAt: formData.updatedAt
        }))
        dispatch(getAlertsRequest())
        history.push('/admin/alerts')
    }

    return (
        <container className="register-form-container">
            <form onSubmit="{submitHandler}" className="service-form">
                <form.group controlId="alert-text">
                    <form.label>Text</form.label>
                    <form.control placeholder="Text" name="text" value="{formData.text}" onChange="{handleChange}" readOnly="{user.readOnly}"></form.control>
                </form.group>
                <form.group controlId="alert-start">
                    <form.label>Start</form.label>
                    <form.control type="datetime-local" step="{1}" placeholder="Start" name="start" value="{formData.start}" onChange="{handleChange}" readOnly="{user.readOnly}"></form.control>
                </form.group>
                <form.group controlId="alert-end">
                    <form.label>End</form.label>
                    <form.control type="datetime-local" step="{1}" placeholder="End" name="end" value="{formData.end}" onChange="{handleChange}" readOnly="{user.readOnly}"></form.control>
                </form.group>
                <row>
                    <col>
                        <linkcontainer to="{`/admin/alerts`}">
                            <button className="tallenna paymentmethod-back-button">
                                Return
                            </button>
                        </linkcontainer>
                    
                    {!user.readOnly &&
                        <col style="{{" textAlign:="" 'right',="" }}="">
                            <button className="create-service-button tallenna" type="submit">
                                Save
                            </button>
                        
                    }
                </row>
            </form>
        </container>
    )
}

export default AlertForm
</htmlinputelement>