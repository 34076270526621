import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

interface IProps {
  onCreateAccount: (data: any) => void;
}

const SignupForm = (props: IProps) => {
  let initialState = {
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    password: "",
    confirmPassword: "",
    tAndc: false,
  };
  const [formData, setFormData] = useState(initialState);
  const [error, setError] = useState<any>({});

  const checkValidation = () => {
    const {
      firstName,
      lastName,
      email,
      mobileNumber,
      password,
      confirmPassword,
    } = formData;
    const formError: any = {};

    if (firstName === "") formError.firstName = "This is required field!";
    if (lastName === "") formError.lastName = "This is required field!";
    if (email === "") formError.email = "This is required field!";
    if (mobileNumber === "") formError.mobileNumber = "This is required field!";
    if (password === "") formError.password = "This is required field!";
    if (password.length < 11)
      formError.password = "Password should have minimum 11 characters!";
    if (password !== confirmPassword)
      formError.confirmPassword = "Password does not match!";
    return formError;
  };

  const resetError = (name: string) => {
    delete error[name];
    setError(error);
  };

  const handleChange = (e: React.ChangeEvent<htmlinputelement>) => {
    const { value, name } = e.target;

    resetError(name);
    setFormData((prevValue: any) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const handleCheck = (e: React.ChangeEvent<htmlinputelement>) => {
    const { checked, name } = e.target;

    setFormData((prevValue: any) => {
      return {
        ...prevValue,
        [name]: checked,
      };
    });
  };

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    const formError = checkValidation();
    if (Object.keys(formError).length > 0) {
      setError(formError);
    } else {
      const data = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        password: formData.password,
        mobileNumber: formData.mobileNumber,
      };
      props.onCreateAccount(data);
      setFormData(initialState);
    }
  };

  return (
    <form className="signup-form" onSubmit="{submitHandler}">
      <form.group controlId="firstname">
        <form.label>First name</form.label> {/* First Name */}
        <form.control type="text" placeholder="Etunimi" name="firstName" value="{formData.firstName}" onChange="{handleChange}" isInvalid="{error.firstName}"></form.control>
        <form.control.feedback type="invalid">
          {error.firstName}
        </form.control.feedback>
      </form.group>

      <form.group controlId="lastname">
        <form.label>Surname</form.label> {/* Last Name */}
        <form.control type="text" placeholder="Sukunimi" name="lastName" value="{formData.lastName}" onChange="{handleChange}" isInvalid="{error.lastName}"></form.control>
        <form.control.feedback type="invalid">
          {error.lastName}
        </form.control.feedback>
      </form.group>

      <form.group controlId="email">
        <form.label>E-mail</form.label> {/* Email */}
        <form.control type="email" placeholder="Sähköposti" name="email" value="{formData.email}" onChange="{handleChange}" isInvalid="{error.email}"></form.control>
        <form.control.feedback type="invalid">
          {error.email}
        </form.control.feedback>
      </form.group>

      <form.group controlId="mobileNumber">
        <form.label>Phone number</form.label> {/* Phone Number */}
        <form.control type="number" placeholder="Puhelinnumero" name="mobileNumber" value="{formData.mobileNumber}" onChange="{handleChange}" isInvalid="{error.mobileNumber}"></form.control>
        <form.control.feedback type="invalid">
          {error.mobileNumber}
        </form.control.feedback>
      </form.group>

      <form.group controlId="password">
        <form.label>Password</form.label>
        <form.control type="password" placeholder="Uusi salasana" name="password" value="{formData.password}" onChange="{handleChange}" isInvalid="{error.password}"></form.control>
        <small className="password-length">At least 11 characters</small>{" "}
        {/* At least 11 characters */}
        <form.control.feedback type="invalid">
          {error.password}
        </form.control.feedback>
      </form.group>

      <form.group controlId="confirmPassword">
        <form.control type="password" placeholder="Vahvista salasana" name="confirmPassword" value="{formData.confirmPassword}" onChange="{handleChange}" isInvalid="{error.confirmPassword}"></form.control>
        <form.control.feedback type="invalid">
          {error.confirmPassword}
        </form.control.feedback>
      </form.group>

      <form.group controlId="termsCondition" className="termscondition">
        <form.control type="checkbox" name="tAndc" onChange="{handleCheck}" checked="{formData.tAndc}"></form.control>
        <form.label className="termsLabel">
          {/* I agree to Terms and Conditions of Digihappy */}
          I accept Digihappy{" "}
          <link to="/palveluehdot" target="_blank">
            Terms of use
          
        </form.label>
      </form.group>

      <button style="{{" float:="" "right",="" }}="" type="submit" className="tallenna user-form-button" disabled="{!formData.tAndc}">
        CREATE USER ACCOUNT {/* Create account */}
      </button>
    </form>
  );
};

export default SignupForm;
</htmlinputelement></htmlinputelement></any>