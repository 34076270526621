import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Col, Form, Row } from 'react-bootstrap'

import { getItSupportProfileRequest, updateItSupportProfileRequest } from '../redux/actions'
import Message from './Message'
import Loader from './Loader'
import FormContainer from './FormContainer'
import { AppState } from '../redux/types'

const ITSupportEditForm = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch()
    const history = useHistory()
    const user = useSelector((state: AppState) => state.user)
    const [formData, setFormData] = useState({
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        mobileNumber: '',
    })
    const [isDisabled, setIsDisabled] = useState(true);
    const itSupport: any = useSelector((state: AppState) => state.itSupport)

    useEffect(() => {
        dispatch(getItSupportProfileRequest(id));
    }, [dispatch, id])

    useEffect(() => {
        if (user && user.isAdmin) {
            setFormData((prevValue: any) => {
                return {
                    ...prevValue,
                    id: itSupport.itSupportProfile?.id || '',
                    firstName: itSupport.itSupportProfile?.firstName || '',
                    lastName: itSupport.itSupportProfile?.lastName || '',
                    email: itSupport.itSupportProfile?.email || '',
                    mobileNumber: itSupport.itSupportProfile?.mobileNumber || '',
                }
            })
        } else {
            history.push('/kirjaudu')
        }
    }, [dispatch, history, user, itSupport]);

    const handleChange = (e: React.ChangeEvent<htmlinputelement>) => {
        const { value, name } = e.target

        setIsDisabled(false);
        setFormData((prevValue: any) => {
            return {
                ...prevValue,
                [name]: value,
            }
        })
    }

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault()

        let data = {
            id: formData.id,
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            mobileNumber: formData.mobileNumber,
        }
        dispatch(updateItSupportProfileRequest(data))
        history.push('/admin/it_support');
    }

    return (
        <formcontainer>
            <h1 className="user-form-header">Edit IT Support Profile</h1>

            {
                itSupport.loading ? (
                    <loader></loader>
                ) : itSupport.error ? (
                    <message variant="danger">{itSupport.error.message}</message>
                ) : (
                    <form onSubmit="{submitHandler}" className="user-form">
                        <form.group controlId="firstname-nurse">
                            <form.label>*First Name</form.label>
                            <form.control type="text" placeholder="First Name" name="firstName" value="{formData.firstName}" onChange="{handleChange}"></form.control>
                        </form.group>

                        <form.group controlId="lastname-nurse">
                            <form.label>*Last Name</form.label>
                            <form.control type="text" placeholder="Last Name" name="lastName" value="{formData.lastName}" onChange="{handleChange}"></form.control>
                        </form.group>

                        <form.group controlId="email-nurse">
                            <form.label>*Email</form.label>
                            <form.control type="email" placeholder="Email" name="email" value="{formData.email}" onChange="{handleChange}"></form.control>
                        </form.group>

                        <form.group controlId="mobileNumber-nurse">
                            <form.label>*Phone Number</form.label>
                            <form.control type="number" placeholder="0" name="mobileNumber" value="{formData.mobileNumber}" onChange="{handleChange}"></form.control>
                        </form.group>

                        <row 100="" className="justify-content-md-center" style="{{" minHeight:="" }}="">
                            <col xs="{6}">
                            
                            <col xs="{6}">
                                <button style="{{float:" 'right'}}="" type="submit" className="tallenna user-form-button" disabled="{isDisabled}">
                                    Submit
                                </button>
                            
                        </row>
                    </form>
                )
            }
        </formcontainer>
    )
}
export default ITSupportEditForm</htmlinputelement>