const Senior = ({
  member,
  mobileNumber,
  lastName,
  nurseringHome,
  homeAddress,
  department,
}: any) => {
  return (
    <>
      <p className="role">
        <strong>Senior</strong>
      </p>
      <li className="membership">{member} </li>
    </>
  )
}

export default Senior
