import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAlertsRequest } from "../../redux/actions";
import { AppState } from "../../redux/types";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Button from "../../components/button/Button";
import Banner from "../../components/layout/banner/Banner";
import { ServicesType } from "../phone/Phone";
import { classNames } from "../../utils/classnames";
import styles from "./watch.module.css";

const Watch = () => {
    const dispatch = useDispatch();

    const currentDate = Date.now();

    useEffect(() => {
        dispatch(getAlertsRequest());
        return () => {};
    }, [dispatch]);

    const resources = useSelector((state: AppState) => state.alerts);
    const { list } = resources;

    const services: ServicesType[] = [
        {
            content: (
                <>
                    <div className="services-info-title">Posifo portable security bracelet</div>
                    <ul className="services-info-text">
                        <li>Gps 4G positioning, waterproof IP67</li>
                        <li>Security bracelet, lightweight 23 g, silicone</li>
                        <li>Lightweight neckwear 21 g</li>
                        <li>Optional extras according to choice</li>
                        <li>
                            <a href="https://www.posifon.care/" className="global-link" target="_blank" rel="noreferrer">
                                Sweden's most popular </a>
                            {" "}
                            locating security alarm
                        </li>
                        <li>Digihappy will buy back a device that has been in good condition for less than a year</li>
                    </ul>
                </>
            ),
            image: "/assets/images/watch/seniori-turvaranneke.png",
            alt: "Musta turvaranneke seniorin ranteessa",
        },
        {
            content: (
                <>
                    <div className="services-info-title">Reliable security alerts</div>
                    <ul className="services-info-text">
                        <li>A senior alerts help at the touch of a button</li>
                        <li>The alert goes to the mobile phones of people close to you</li>
                        <li>Voice connection opens immediately and positioning</li>
                        <li>Automatic alerts when a senior leaves the area, falls or runs out of battery</li>
                    </ul>
                </>
            ),
            image: "/assets/images/watch/turvaranneke-seniorille-kaulamalli.webp",
            alt: "Käsi pitelee kaulamalli-turvahälytintä",
        },
        {
            content: (
                <>
                    <div className="services-info-title">Interface</div>
                    <ul className="services-info-text">
                        <li>The connection is from Digihappy and ready on the device.</li>
                        <li>Alarms, positioning and voice communication work everywhere thanks to the connection.</li>
                        <li>The operation of the device is NOT tied to the senior's mobile phone.</li>
                    </ul>
                </>
            ),
            image: "/assets/images/watch/paras-senioriturvaranneke.jpg",
            alt: "Valkoinen kissa nauttii vanhemman henkilön silittelystä, henkilöllä on turvahälytin ranteessaan",
            credit: "BILD: : Björn Johansson/Posifon",
        },
        {
            content: (
                <>
                    <div className="services-info-title">Posifon Care app for loved ones</div>
                    <ul className="services-info-text">
                        <li>A loved one uses the easy Posifo app on their mobile phone</li>
                        <li>
                            Notifications and alerts go to the mobile phones of people close to you
                            <ul>
                                <li>Widely customisable order, recipients and times</li>
                            </ul>
                        </li>
                    </ul>
                </>
            ),
            image: "/assets/images/watch/helppo-senioripuhelin.webp",
            alt: "Mies, jolla on silmälasit ja parta, katsoo puhelintaan.",
        },
    ];

    const [play, setPlay] = useState(0);
    return (
        <>
            <helmet>
                <title>Security bracelet for elderly I Gps tracking, alarm. IT support</title>
                <meta name="description" content="A fixed restraint is the best protection for the elderly. Easy alarm and voice communication. Installation and it-support.">
            </helmet>
            <banner 56="" backgroundImage="/assets/images/banners/turvaranneke-senorille.jpg" label="Iäkäs nainen vilkaisee rannekelloaan ollessaan ulkona puistossa" height="{`calc(100vh" -="" ${="" +="" *="" list?.filter((el)=""> {
                            // @ts-ignore
                            return (!el.start || new Date(el.start) <= currentDate) && (!el.end || new Date(el.end) >= currentDate);
                        })?.length -
                    1
                }px)`}
            >
                <div className="global-banner-text-container">
                    <h1 className="{classNames(&quot;global-banner-title" global-white",="" styles.bannerHeading)}="">Security bracelet</h1>
                    <p className="global-banner-text global-white">At the touch of a button, alarm, voice and positioning.</p>
                    <link to="/osta/turvaranneke" style="{{" marginTop:="" "auto"="" }}="">
                        <button secondary="" emphasized="">
                            BUY AN AIRBAG
                        </button>
                    
                </div>
            </banner>
            <container fluid="" className="my-3 services-container services-container-80" style="{{" minWidth:="" "min(900px,="" 100%)"="" }}="">
                <h1 className="services-header">Security Bracelet and Digihappy service - We do it all for you</h1>
                <div className="global-box-line-container">
                    {services.map((service, key) => (
                        <div className="global-box-line" key="{key}">
                            {key % 2 === 0 ? (
                                <react.fragment>
                                    <div className="global-box global-background-gray">{service.content}</div>
                                    <div className="{classNames(&quot;global-image-container&quot;," "global-credit-image-container")}="">
                                        <img src="{service.image}" alt="{service.alt}" className="global-image">
                                        {service.credit && <span className="global-credit-text-right">{service.credit}</span>}
                                    </div>
                                </react.fragment>
                            ) : (
                                <react.fragment>
                                    <div className="{classNames(&quot;global-image-container&quot;," "global-credit-image-container")}="">
                                        <img src="{service.image}" alt="H" className="global-image" about="{service.alt}">
                                        {service.credit && <span className="global-credit-text">{service.credit}</span>}
                                    </div>
                                    <div className="global-box global-background-gray">{service.content}</div>
                                </react.fragment>
                            )}
                        </div>
                    ))}
                </div>
            </container>
            <container fluid="" className="my-3 services-container service-table-container" id="palvelut">
                <div className="services-button-container">
                    <button onClick="{()" ==""> setPlay(1)}>View video</button>
                    <div className="global-video-wrapper">
                        <iframe 16="" src="{`https://www.youtube.com/embed/PFzWQqfrVac?autoplay=${play}`}" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; autoplay" allowFullScreen="" title="Digihappy Senior Tablets" style="{{" margin:="" "0="" auto",="" width:="" "100%",="" height:="" border:="" "none",="" aspectRatio:="" 9,="" }}=""></iframe>
                    </div>
                </div>
            </container>
        </>
    );
};

export default Watch;
