const ServicesAccordionTable = () => {
    return (
        <div className="palvelut-table-div">
            <table className="palvelut-table">
                <colgroup>
                    <col style="{{" width:="" "40%",="" }}="">
                    <col style="{{" width:="" "20%",="" }}="">
                    <col style="{{" width:="" "20%",="" }}="">
                    <col style="{{" width:="" "20%",="" }}="">
                </colgroup>
                <tbody>
                    <tr>
                        <td></td>
                        <td>
                            <h1 className="accordion-button-header">Effortless</h1>
                            <div className="accordion-button-text">when using a tablet is difficult and you mainly want to make video calls. The cheapest and smallest option.</div>
                        </td>
                        <td>
                            <h1 className="accordion-button-header">Easy</h1>
                            <div className="accordion-button-text">when using a tablet is difficult and only partially successful. The most popular option.</div>
                        </td>
                        <td>
                            <h1 className="accordion-button-header">Expert</h1>
                            <div className="accordion-button-text">when using a tablet is effortless. For the active senior.</div>
                        </td>
                    </tr>
                    <tr>
                        <td>Size</td>
                        <td>8,7”</td>
                        <td>10,5" / 11,0"</td>
                        <td>10,5" / 11,0"</td>
                    </tr>
                    <tr>
                        <td>Apps ready on your tablet</td>
                        <td>Only Digihappy video calls or also radios, audio books</td>
                        <td>Only Digihappy video calls or your choice of radios, audiobooks, Chrome, YleAreena, magazines, solitaire, memory game, chess, art, medicine, Whatsapp</td>
                        <td>Comprehensive range of applications</td>
                    </tr>
                    <tr>
                        <td>You can add your own applications</td>
                        <td></td>
                        <td>
                            <i className="icon fas fa-check-circle fa-4x"></i>
                        </td>
                        <td>
                            <i className="icon fas fa-check-circle fa-4x"></i> <i className="icon fas fa-check-circle fa-4x"></i>
                        </td>
                    </tr>
                    <tr>
                        <td>Homepage</td>
                        <td>Adapted for ease of use</td>
                        <td>Adapted for ease of use</td>
                        <td>Samsung view</td>
                    </tr>
                    <tr>
                        <td>Tablet</td>
                        <td>Samsung Galaxy Tab A7 Lite LTE/A9 5G</td>
                        <td>Samsung Galaxy Tab A8 lte/A9+ 5G</td>
                        <td>Samsung Galaxy Tab A8 lte/A9+ 5G</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default ServicesAccordionTable;
