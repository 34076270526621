import { spinner } from 'react-bootstrap'

const Loader = () => {
  return (
    <>
      <spinner animation="border" role="status" style="{{" width:="" "100px",="" height:="" margin:="" "auto",="" display:="" "block"}}="">
        <span className="sr-only">Loading...</span>
      </spinner>
    </>
  )
}

export default Loader
