import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";

import FormContainer from "../components/FormContainer";
import StepsHeader from "../components/StepsHeader";

import { saveShippingAddress } from "../redux/actions";
import { AppState } from "../redux/types";
import api from "../utils/api-helper/api";
import StepsNotification from "../components/StepsNotification";

const ShippingScreen = () => {
    const cart = useSelector((state: AppState) => state.cart);
    const user = useSelector((state: AppState) => state.user);
    const shippingMethods = useSelector((state: AppState) => state.shippingMethods.shipping);
    const shipping_direct = shippingMethods?.find((el) => el.shippingMethod === "direct");
    const shipping_posti = shippingMethods?.find((el) => el.shippingMethod === "posti");
    const category = useSelector((state: AppState) => state.categories.category?.attributes.slug);
    const [shippingMethod, setShippingMethod] = useState(cart.shippingMethod);
    const [name, setName] = useState(cart.name || `${user.firstName} ${user.lastName}`);
    const [address, setAddress] = useState(cart.address);
    const [postalCode, setPostalCode] = useState(cart.postalCode);
    const [city, setCity] = useState(cart.city);
    const [country, setCountry] = useState("Suomi");
    const [email, setEmail] = useState(cart.email || user.email);
    const [mobile, setMobile] = useState(cart.mobile || user.mobileNumber);
    const [pickupPoints, setPickupPoints] = useState([]);
    const [pickupPoint, setPickupPoint] = useState(cart.pickupPoint);
    const [usePickupPoint, setUsePickupPoint] = useState(true);
    const [pickupPointLoading, setPickupPointLoading] = useState(false);
    // const [freeTierCode, setFreeTierCode] = useState('')
    // const [isFree, setIsFree] = useState(false)
    const [btnDisabled, setBtnDisabled] = useState((shippingMethod !== "direct" && shippingMethod !== "posti") || (shippingMethod === "posti" && (!name || !address || !postalCode || !city || !country || !pickupPoint)));
    const { list } = useSelector((state: AppState) => state.seniorHomes);
    const [nursingHome, setNursingHome] = useState(cart.nursingHome || list?.filter((el) => el.show)?.[0]?.reference);

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if ((shippingMethod !== "direct" && shippingMethod !== "posti") || (shippingMethod === "posti" && (!name || !address || !postalCode || !city || !country || !pickupPoint?.id))) {
            setBtnDisabled(true);
        } else {
            setBtnDisabled(false);
        }
    }, [shippingMethod, name, address, postalCode, city, country, pickupPoint]);

    const checkPostalCode = useCallback(() => {
        if (country === "Suomi") {
            console.log(postalCode?.length);
            return postalCode?.length === 5;
        }

        return true;
    }, [postalCode, country]);

    const fetchPickupPoints = useCallback(() => {
        setPickupPointLoading(true);
        api.get(`/user/orders/points?zipCode=${postalCode}`)
            .then((resp) => {
                console.log(resp.data);
                if (resp.status === 200) {
                    setPickupPoints(resp.data.payload);
                } else {
                    setPickupPoints([]);
                }
            })
            .catch((err) => {
                setPickupPoints([]);
            })
            .finally(() => {
                setPickupPointLoading(false);
            });
    }, [postalCode]);

    useEffect(() => {
        if (!checkPostalCode()) return;
        fetchPickupPoints();
    }, [fetchPickupPoints, checkPostalCode]);

    useEffect(() => {
        if (postalCode === cart.postalCode) {
            setPickupPoint(cart.pickupPoint);
        } else {
            setPickupPoint({
                address1: null,
                address2: null,
                city: null,
                contact: null,
                countryCode: null,
                email: null,
                fax: null,
                id: null,
                name: null,
                openingHours: null,
                phone: null,
                serviceCode: null,
                serviceType: null,
                sms: null,
                state: null,
                zipcode: null,
            });
        }
    }, [setPostalCode, cart.pickupPoint, cart.postalCode, postalCode]);

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();

        dispatch(
            saveShippingAddress({
                shippingMethod,
                name,
                address,
                postalCode,
                city,
                country,
                email,
                mobile,
                pickupPoint,
                usePickupPoint,
                nursingHome,
            })
        );
        history.push("/maksutapa");
    };

    console.log(cart.shippingMethod);
    return (
        <>
            <stepsheader step1="" step2="" step3="" user="{2}" payment="{1}" account="{1}"></stepsheader>
            <formcontainer>
                <h1 className="shipping-header">Choose your delivery method</h1>
                <form className="shipping-form" onSubmit="{submitHandler}">
                    <form.group controlId="shippingMethod">
                        {/*<form.label>Enter the code if you have a free trial code</form.label>*/}
                        <form.check className="radio-button" type="radio" label="{" <div="">
                                    <row>
                                        <col xs="{10}">Delivery to the customer at a Post Office pick-up point
                                        <col xs="{2}">{(shipping_post?.price || 0).toFixed(2)} €
                                    </row>
                                </form.check></form.group></form></formcontainer>