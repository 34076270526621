const Customer = ({ member, isSenior }: any) => {
  return (
    <>
      <p className="role">
        <strong>Client {isSenior && `(${'Senior'})`}</strong>
      </p>

      <li className="membership">{member}</li>
    </>
  )
}

export default Customer
