import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { createCouponRequest, getCouponsRequest } from "../redux/actions";
import { AppState } from "../redux/types";
import { LinkContainer } from "react-router-bootstrap";

const CouponForm = () => {
  const { id } = useParams<{ id: string }>();
  const resources = useSelector((state: AppState) => state.coupons);
  const { list } = resources;
  let coupon = undefined;
  try {
    coupon = list?.find((o) => o.id === parseInt(id));
  } catch (error) {}

  const [formData, setFormData] = useState({
    id: coupon ? parseInt(coupon.id) : -1,
    code: coupon?.code || "",
    free_shipping: coupon?.data?.free_shipping ? "true" : "false",
    period: coupon?.data?.period || 0,
    createdAt: undefined,
    updatedAt: undefined,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state: AppState) => state.user);
  useEffect(() => {
    if (user && (user.isAdmin || user.role === "it_support")) {
      dispatch(getCouponsRequest());
    } else {
      history.push("/kirjaudu");
    }
    return () => {};
  }, [dispatch, history, user]);

  const handleChange = (e: React.ChangeEvent<htmlinputelement>) => {
    const { value, name } = e.target;
    setFormData((prevValue: any) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(
      createCouponRequest({
        id: formData.id === -1 ? undefined : formData.id,
        code: formData.code,
        data: {
          free_shipping: formData.free_shipping === "true",
          period: formData.period ? parseInt(formData.period) : 0,
        },
        createdAt: formData.createdAt,
        updatedAt: formData.updatedAt,
      })
    );
    dispatch(getCouponsRequest());
    history.push("/admin/coupons");
  };

  return (
    <container className="register-form-container">
      <form onSubmit="{submitHandler}" className="service-form">
        <form.group controlId="coupon-code">
          <form.label>Code</form.label>
          <form.control placeholder="Code" name="code" value="{formData.code}" onChange="{handleChange}" readOnly="{user.readOnly" ||="" !user.isAdmin}=""></form.control>
        </form.group>
        <form.group controlId="coupon-shipping">
          <form.label>Free shipping</form.label>
          <form.control as="select" name="free_shipping" value="{formData.free_shipping}" onChange="{handleChange}" disabled="{user.readOnly" ||="" !user.isAdmin}="">
            <option value="false">No</option>
            <option value="true">Yes</option>
          </form.control>
        </form.group>
        <form.group controlId="alert-end">
          <form.label>Period</form.label>
          <form.control type="number" step="{1}" min="{0}" placeholder="Trial period" name="period" value="{formData.period}" onChange="{handleChange}" readOnly="{user.readOnly" ||="" !user.isAdmin}=""></form.control>
        </form.group>
        <row>
          <col>
            <linkcontainer to="{`/admin/coupons`}">
              <button className="tallenna paymentmethod-back-button">
                Return
              </button>
            </linkcontainer>
          
          {!user.readOnly && user.isAdmin && (
            <col style="{{" textAlign:="" "right",="" }}="">
              <button className="create-service-button tallenna" type="submit">
                Save
              </button>
            
          )}
        </row>
      </form>
    </container>
  );
};

export default CouponForm;
</htmlinputelement>