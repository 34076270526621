import { HashLink as Link } from "react-router-hash-link";

const youtubeLink = "https://www.youtube.com/watch?v=8GAQYE12YXQ";
const ServicesInfo2 = () => {
    return (
        <>
            <div className="global-box-line-container">
                <div className="global-box-line">
                    <div className="global-box global-background-gray">
                        <div className="services-info-title">Tablet, interface, stand</div>
                        <ul className="services-info-text">
                            <li>Samsung Galaxy Tab (two sizes) or Apple iPad </li>
                            <li>Access, unlimited data </li>
                            <li>
                                Pedestal -{""}
                                <a href="{youtubeLink}" className="lue-lisää-linkki" target="_blank" rel="noreferrer">
                                    <strong>see video</strong>
                                </a>
                            </li>
                        </ul>
                        <p className="options">
                            <link 500="" className="linkki global-text-dark" to="/palvelut#palvelut" style="{{" fontWeight:="" }}="">
                                Depending on your senior's ability, choose a tablet from Carefree, Easy or Master.
                            
                        </p>
                    </div>
                    <div className="global-image-container">
                        <img src="/assets/images/services/tabletti-senorille.webp" alt="The person is holding a tablet in his or her hand." className="global-image">
                    </div>
                </div>
                <div className="global-box-line">
                    <div className="global-image-container">
                        <img src="/assets/images/services/senioritabletti.webp" alt="The person is using a tablet and a touch screen pen." className="global-image">
                    </div>
                    <div className="global-box global-background-gray">
                        <div className="services-info-title">A customised tablet and Digihappy home view</div>
                        <ul className="services-info-text">
                            <li>Senior-friendly settings, apps and contacts all set and ready </li>
                            <li>For seniors who don't touch the tablet themselves, you can choose only the video call view</li>
                            <li>
                                For active seniors, also radios, audio books, magazines,{""}
                               <a href="https://areena.yle.fi/" className="linkki" target="_blank" rel="noreferrer">
                                    Yle Arena
                               </a>
                                , games or email - and the possibility to add your own apps
                            </li>
                            <a href="{youtubeLink}" className="lue-lisää-linkki" target="_blank" rel="noreferrer">
                                <strong>Watch the video</strong>
                            </a>
                        </ul>
                    </div>
                </div>
                <div className="global-box-line">
                    <div className="global-box global-background-gray">
                        <div className="services-info-title">Easy and secure video calling</div>

                        <ul className="services-info-text">
                            <li>Digihappy video app is on a senior's tablet and a loved one's mobile phone</li>
                            <li>When a relative or friend calls, the call opens on the senior's tablet without touching if desired</li>
                            <li>The senior calls by touching the photo of their relative or friend</li>
                            <li>Secure, only family account members can call</li>
                        </ul>
                    </div>
                    <div className="global-image-container">
                        <img src="/assets/images/services/hyvä-senioritabletti.webp" alt="Woman uses a tablet while lying in bed" className="global-image">
                    </div>
                </div>
                <div className="global-box-line">
                    <div className="global-image-container">
                        <img src="/assets/images/services/4.png" alt="H" className="global-image">
                    </div>
                    <div className="global-box global-background-gray">
                        <div className="services-info-title">Family account for video calls</div>
                        <ul className="services-info-text">
                            <li>You will be assigned six family members or friends. A member can make a video call, which will automatically open on the senior's tablet if they wish. The member's picture will appear in the senior's video call view.</li>
                            <li>The member has access to IT support.</li>
                        </ul>
                    </div>
                </div>
                <div className="global-box-line">
                    <div className="global-box global-background-gray">
                        <div className="services-info-title">IT support, remote management, security, updates</div>
                        <ul className="services-info-text">
                            <li>Start-up advice</li>
                            <li>Continuous IT support by phone or by messages</li>
                            <li>Remote management of your tablet, even without the active participation of the senior </li>
                            <li>Security and updates</li>
                        </ul>
                    </div>
                    <div className="global-image-container">
                        <img src="/assets/images/services/ittuki-seniorille.webp" alt="Two women are sitting on a sofa, focused on a tablet screen. They are making a call to an IT number." className="global-image">
                    </div>
                </div>
            </div>
        </>
    );
};

export default ServicesInfo2;
