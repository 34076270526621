import SubNavBar from '../components/SubNavBar'

const Video = () => {
    return (
        <>
            <subnavbar></subnavbar>
        </>
    )
}

export default Video
