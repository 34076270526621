import Routes from './Routes';
import Header from './components/Header';
import Footer from './components/Footer';
import useLogin from './hooks/useLogin';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import { useCookies } from 'react-cookie';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useEffect } from 'react';
import '@livekit/components-styles';

const App = () => {
  const [cookies, setCookie, removeCookie] = useCookies();

  useEffect(() => {
    if (cookies.consent !== 'all') {
      removeCookie('_ga');
      removeCookie('_gat');
      removeCookie('_gid');
    } else {
      const script_ga1 = document.createElement('script');
      script_ga1.async = true;
      script_ga1.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_TAG}`;
      const script_ga2 = document.createElement('script');
      script_ga2.textContent = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${process.env.REACT_APP_GA_TAG}');`;
      document.head.appendChild(script_ga1);
      document.head.appendChild(script_ga2);

      const script_mp = document.createElement('script');
      script_mp.textContent = `!function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '${process.env.REACT_APP_META_PIXEL_ID}');
                    fbq('track', 'PageView');`;
      document.head.appendChild(script_mp);
    }
  }, [cookies.consent, removeCookie]);

  const onChange = (name: any) => {
    console.log(name);
    setCookie('consent', name, { path: '/' });
  };
  useLogin();

  let description =
    'Digihappy Senioritabletti yhdistää perheen ja tuo digin jokaiselle toimintakyvystä riippumatta';
  if (window.location.host.split('.')[0] === 'www') {
    description += ' - WWW versio.';
  } else {
    description += '.';
  }

  return (
    <>
      <helmet>
        <title>Digihappy Senior Tablet</title>
        <meta name="description" content="{description}">
      </helmet>
      <header className="{" window.location.pathname.split('="" ')[1]="==" 'meet'="" ?="" 'prejoin-room'="" :="" ''="" }=""></header>
      <routes></routes>
      <footer className="{" window.location.pathname.split('="" ')[1]="==" 'meet'="" ?="" 'prejoin-room'="" :="" ''="" }=""></footer>
      <toastcontainer></toastcontainer>
      {cookies.consent === 'all' && (
        <img src="https://secure.adnxs.com/seg?member_id=7458&add_code=799995B&t=2" width="1" height="1" style="{{" display:="" 'none',="" }}="" alt="newspaper">
      )}
      {!['all', 'required'].includes(cookies.consent) && (
        <container className="{`consent_banner" ${="" window.location.pathname.split('="" ')[1]="==" 'meet'="" ?="" 'prejoin-room'="" :="" ''="" }`}="">
          <row className="align-items-center viewport-height" style="{{" minHeight:="" 64,="" }}="">
            <col xs="{12}" sm="{4}">
              <div style="{{" textAlign:="" 'center',="" }}="">
                This site uses cookies
              </div>
            
            <col xs="{12}" sm="{4}">
              <button className="consent_button" onClick="{()" ==""> {
                  onChange('required');
                }}
              >
                Hyväksyn välttämättömät
              </button>
            
            <col xs="{12}" sm="{4}">
              <button className="consent_button reverse" onClick="{()" ==""> {
                  onChange('all');
                }}
              >
                Hyväksyn kaikki
              </button>
            
          </row>
        </container>
      )}
    </>
  );
};

export default App;
