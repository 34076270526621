import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { AppState } from "../redux/types";

const StepsHeader = ({ step1, step2, step3, step4, step5 }: any) => {
    const category = useSelector((state: AppState) => state.categories.category?.attributes.slug);
    return (
        <>
            <row>
                <col sm="{12}" lg="{12}" md="{12}">
                    <section className="justify-content-center my-4 steps-section">
                        <ul className="circles-row">
                            {step1 ? (
                                <li className="step active">
                                    <i className="icon-in-circle active fas fa-shopping-cart"></i>
                                    Shopping cart
                                </li>
                            ) : (
                                <li className="step">
                                    <i className="icon-in-circle fas fa-shopping-cart"></i>
                                    Shopping cart
                                </li>
                            )}
                            {category !== "alarm" && (
                                <>
                                    <li className="step-line"></li>
                                    {step2 ? (
                                        <li className="step active">
                                            <i className="icon-in-circle active fas fa-user-circle"></i>
                                            Subscribe and create an account
                                        </li>
                                    ) : (
                                        <li className="step">
                                            <i className="icon-in-circle fas fa-user-circle"></i>
                                            Subscribe and create an account
                                        </li>
                                    )}
                                </>
                            )}
                            <li className="step-line"></li>
                            {step3 ? (
                                <li className="step active">
                                    <i className="icon-in-circle active fas fa-truck"></i>
                                    Contact information, delivery
                                </li>
                            ) : (
                                <li className="step">
                                    <i className="icon-in-circle fas fa-truck"></i>
                                    Contact information, delivery
                                </li>
                            )}
                            <li className="step-line"></li>
                            {step4 ? (
                                <li className="step active">
                                    <i className="icon-in-circle far fa-credit-card active"></i>
                                    Payment
                                </li>
                            ) : (
                                <li className="step">
                                    <i className="icon-in-circle far fa-credit-card"></i>
                                    Payment
                                </li>
                            )}
                            <li className="step-line"></li>
                            {step5 ? (
                                <li className="step active">
                                    <i className="icon-in-circle fas fa-address-card active"></i>
                                    {category !== "alarm" ? "Luo perhetili" : "Yhteenveto"}
                                </li>
                            ) : (
                                <li className="step">
                                    <i className="icon-in-circle fas fa-address-card"></i>
                                    {category !== "alarm" ? "Luo perhetili" : "Yhteenveto"}
                                </li>
                            )}
                        </ul>
                    </section>
                    {/* <section className="justify-content-center my-4 step-texts-section">
        <ul className="steps-row">
          {step1 ? (
            <li className="steps-name active service-step-text">Service</li>
          ) : (
            <li className="steps-name service-step-text">Service</li>
          )}
          {step2 ? (
            <li className="steps-name active register-step-text">Create an account and sign in</li>
          ) : (
            <li className="steps-name account-step-text">Create an account and sign in</li>
          )}
          {step3 ? (
            <li className="steps-name active register-step-text">
              Delivery address
            </li>
          ) : (
            <li className="steps-name address-step-text">Delivery address</li>
          )}
          {step4 ? (
            <li id="payment-step-text" className="steps-name active">Payment method and summary <br/><br/>Pay</li>
          ) : (
            <li className="steps-name payment-step-text">Payment method and summary<br/><br/> Pay</li>
          )}
          {step5 ? (
            <li className="steps-name active info-step-text">
              My personal data <br/><br/> Senior and
              personal data of your family and friends
            </li>
          ) : (
            <li className="steps-name info-step-text">
              My personal information <br/><br/> Senior and
              personal data of your family and friends
            </li>
          )}
        </ul>
      </section> */}
                
            </row>
        </>
    );
};

export default StepsHeader;
