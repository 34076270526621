import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";

import Message from "./Message";
import Loader from "./Loader";
import { AppState } from "../redux/types";
import { getSeniorHomesRequest, getUserRequest, updateGroupRequest, updateUserRequest } from "../redux/actions";

const SeniorDetail = ({ onSelect }: any) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state: AppState) => state.user);
    // console.log(user)
    const { error, loading, isLoggedIn } = user;
    const relativeCreateEmailId = useSelector((state: AppState) => state.categories.category?.attributes.RelativeCreateAccount);

    useEffect(() => {
        if (user.id) {
            dispatch(getSeniorHomesRequest());
        } else {
            history.push("/kirjaudu");
        }
        return () => {};
    }, [dispatch, history, user]);

    const senior =
        user.role === "senior"
            ? user
            : user.group?.members?.filter((obj) => {
                  return obj.role === "senior";
              })[0];

    // console.log(senior)

    const [message, setMessage] = useState("");
    const [saved, setSaved] = useState(false);
    const [formData, setFormData] = useState({
        // @ts-ignore
        id: senior?.id,
        username: senior?.username || "",
        password: "",
        confirmPassword: "",
        firstName: senior?.firstName || "",
        lastName: senior?.lastName || "",
        mobileNumber: senior?.mobileNumber || "",
        nursingHome: senior?.nursingHome || "",
        department: senior?.department || "",
        homeAddress: senior?.homeAddress || "",
        homeCity: senior?.homeCity || "",
        homePostalCode: senior?.homePostalCode || "",
        nursingHomeAddress: senior?.nursingHomeAddress || "",
        role: "senior",
    });
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [showPass, setShowPass] = useState(false);
    const [showPass2, setShowPass2] = useState(false);
    const showPassHandler = () => {
        setShowPass((current) => !current);
    };
    const showPassHandler2 = () => {
        setShowPass2((current) => !current);
    };
    // console.log(formData)

    if (!isLoggedIn) <redirect to="/kirjaudu"></redirect>;

    // back button
    const back = () => {
        onSelect("asiakas");
    };

    useEffect(() => {
        if (!isLoggedIn) {
            history.push("/kirjaudu");
        } else {
            if (!user.firstName && user.id !== null) {
                dispatch(getUserRequest(user.id));
            } else {
                // user data from db shown in the form
                setFormData((prevValue: any) => {
                    return {
                        ...prevValue,
                        username: formData.username,
                        password: formData.password,
                        email: "",
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        mobileNumber: formData.mobileNumber,
                        nursingHome: formData.nursingHome,
                        department: formData.department,
                        homeAddress: formData.homeAddress,
                        homeCity: formData.homeCity,
                        homePostalCode: formData.homePostalCode,
                        nursingHomeAddress: formData.nursingHomeAddress,
                    };
                });
            }
        }
        return () => {};
    }, [
        dispatch,
        history,
        user,
        isLoggedIn,
        formData.department,
        formData.firstName,
        formData.username,
        formData.lastName,
        formData.mobileNumber,
        formData.nursingHome,
        formData.homeAddress,
        formData.homeCity,
        formData.homePostalCode,
        formData.nursingHomeAddress,
        formData.password,
    ]);

    useEffect(() => {
        // console.log(formData)
        if (
            !formData.firstName ||
            !formData.lastName ||
            !formData.department.length ||
            (formData.department === "nursingHome" && !formData.nursingHome && !formData.nursingHomeAddress) ||
            (formData.department === "homeAddress" && !formData.homeAddress && !formData.homeCity && !formData.homePostalCode)
        ) {
            setBtnDisabled(true);
        } else {
            setBtnDisabled(false);
        }
        if (!saved) {
            let msg = "";
            if (formData.password.length > 0 && formData.password.length < 11) {
                msg += "Salasana on liian lyhyt";
            } else if (formData.password.length > 0 && formData.confirmPassword.length > 0 && formData.password !== formData.confirmPassword) {
                msg += "Salasanat eivät täsmää\n";
            }
            setMessage(msg);
        }
    }, [formData, saved]);

    const handleChange = (e: React.ChangeEvent<htmlinputelement>) => {
        const { value, name } = e.target;
        // console.log(name)
        // console.log(value)
        setSaved(false);

        setFormData((prevValue: any) => {
            return {
                ...prevValue,
                [name]: value,
            };
        });
    };

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();

        if (user.role !== "senior") {
            // console.log(formData)
            dispatch(
                updateGroupRequest({
                    id: formData.id,
                    username: formData.username,
                    password: formData.password,
                    email: formData.username,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    mobileNumber: formData.mobileNumber,
                    nursingHome: formData.nursingHome,
                    department: formData.department,
                    homeAddress: formData.homeAddress,
                    homeCity: formData.homeCity,
                    homePostalCode: formData.homePostalCode,
                    nursingHomeAddress: formData.nursingHomeAddress,
                    role: "senior",
                    relativeCreateEmailId,
                })
            );
            // console.log(user)
            setSaved(true);
            onSelect("laheinen");
        }

        if (user.role === "senior") {
            if (!formData.password || formData.password !== formData.confirmPassword) {
                // console.log(formData.password)
                // console.log(formData.confirmPassword)
                setMessage("Salasanat eivät täsmää");
            } else {
                setMessage("");
            }
            if (user.password || formData.password === formData.confirmPassword) {
                dispatch(
                    updateUserRequest({
                        id: formData.id,
                        username: formData.username,
                        password: formData.password,
                        email: formData.username,
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        mobileNumber: formData.mobileNumber,
                        nursingHome: formData.nursingHome,
                        department: formData.department,
                        homeAddress: formData.homeAddress,
                        homePostalCode: formData.homePostalCode,
                        homeCity: formData.homeCity,
                        role: "senior",
                    })
                );
            }
        }
    };

    return (
        <container fluid="">
            <row className="justify-content-md-left">
                <col xs="{12}" md="{6}">
                    {loading && <loader></loader>}
                    <h1 className="senior-detail-header">{user.role === "senior" ? "Seniorin tiedot" : "2 Lisää seniori"}</h1>
                    <form onSubmit="{submitHandler}" className="user-form">
                        <form.group as="{Row}">
                            <col sm="{6}">
                                <form.label>
                                    {/* {formData.firstName === '' && <fchighpriority></fchighpriority>} */}
                                    *First name
                                </form.label>
                                <form.control required="" className="text-field" type="text" placeholder="Etunimi" name="firstName" value="{formData.firstName}" onChange="{handleChange}"></form.control>
                            
                            <col sm="{6}">
                                <form.label>
                                    {/* {formData.lastName === '' && <fchighpriority></fchighpriority>} */}
                                    *Family name
                                </form.label>
                                <form.control required="" className="text-field" type="text" placeholder="Sukunimi" name="lastName" value="{formData.lastName}" onChange="{handleChange}"></form.control>
                            
                        </form.group>
                        {/*<form.group controlId="username-senior">*/}
                        {/*    <form.label>*/}
                        {/* /!* {formData.username === '' && <fchighpriority></fchighpriority>} *!/*/}
                        {/* *Senior username*/}
                        {/*    </form.label>*/}
                        {/*    <form.control* }="" {="" *="" required*="" type="text" placeholder="Seniorin käyttäjänimi" name="username" value="{formData.username}*/}" onChange="{handleChange}*/}"></form.control*>*/}
                        {/*</form.group>*/}
                        {user.role === "senior" && (
                            <form.group controlId="senior-password">
                                <form.label>
                                    {/* {formData.password === '' && <fchighpriority></fchighpriority>} */}
                                    *Salasana
                                </form.label>
                                <inputgroup>
                                    <form.control type="{showPass" ?="" "text"="" :="" "password"}="" placeholder="Salasana" name="password" value="{formData.password}" onChange="{handleChange}"></form.control>
                                    <button variant="outline-secondary shadow-none show-password-btn" onClick="{showPassHandler}">
                                        <i className="{showPass" ?="" "fas="" fa-eye-slash"="" :="" fa-eye"}=""></i>
                                    </button>
                                </inputgroup>
                                <div className="show-password">Click on the eye icon to see your password</div>
                                <small className="password-length">You can change your password by entering a new password in both fields.</small>
                                <br>
                                <small className="password-length">At least 11 characters</small>
                            </form.group>
                        )}
                        {user.role === "senior" && (
                            <form.group controlId="confirm-password-senior">
                                <form.label>*Remove password</form.label>
                                <inputgroup>
                                    <form.control type="{showPass2" ?="" "text"="" :="" "password"}="" placeholder="Toista salasana" name="confirmPassword" value="{formData.confirmPassword}" onChange="{handleChange}"></form.control>
                                    <button variant="outline-secondary shadow-none show-password-btn" onClick="{showPassHandler2}">
                                        <i className="{showPass2" ?="" "fas="" fa-eye-slash"="" :="" fa-eye"}=""></i>
                                    </button>
                                </inputgroup>
                                <div className="show-password">Click on the eye icon to see your password</div>
                                {user?.role === "senior" && <small className="for-example">To change the password, enter the new password in both fields. If you do not want to change your password, you can ignore this.</small>}
                            </form.group>
                        )}
                        <form.group controlId="senior-mobile">
                            <form.label>Mobile phone number (optional)</form.label>
                            <form.control className="text-field" type="text" name="mobileNumber" placeholder="Matkapuhelinnumero" value="{formData.mobileNumber}" onChange="{handleChange}"></form.control>
                            <small>For example +358 40 123456</small>
                        </form.group>

                        <form.label>
                            {/* {formData.nursingHome === '' &&
            formData.homeAddress === '' && <fchighpriority></fchighpriority>} */}
                            *Application details
                        </form.label>
                        <form.group>
                            <form.check className="radio-button" type="radio" label="Asuu kotona" id="homeAddress" name="department" value="{&quot;homeAddress&quot;}" checked="{formData.department" =="=" "homeAddress"}="" onChange="{handleChange}"></form.check>
                            <form.check className="radio-button" type="radio" label="Asuu vanhainkodissa" id="nursingHome" name="department" value="{&quot;nursingHome&quot;}" checked="{formData.department" =="=" "nursingHome"}="" onChange="{handleChange}"></form.check>
                        </form.group>
                        {formData.department === "homeAddress" && (
                            <div>
                                <form.group>
                                    <form.label>Street address</form.label>
                                    <form.control className="text-field lives-at-home-input" type="text" name="homeAddress" placeholder="Katuosoite" value="{formData.homeAddress}" onChange="{handleChange}"></form.control>
                                </form.group>
                                <form.group controlId="city">
                                    <form.label>Postal code</form.label>
                                    <form.control className="text-field lives-at-home-input" type="text" name="homePostalCode" placeholder="Postinumero" value="{formData.homePostalCode}" onChange="{handleChange}"></form.control>
                                </form.group>
                                <form.group controlId="city">
                                    <form.label>City</form.label>
                                    <form.control className="text-field lives-at-home-input" type="text" name="homeCity" placeholder="Kaupunki" value="{formData.homeCity}" onChange="{handleChange}"></form.control>
                                </form.group>
                            </div>
                        )}
                        {formData.department === "nursingHome" && (
                            <div>
                                <form.group>
                                    <form.label>Name of the retirement home</form.label>
                                    <form.control className="text-field lives-at-home-input" type="text" name="nursingHome" placeholder="Vanhainkodin nimi" value="{formData.nursingHome}" onChange="{handleChange}"></form.control>
                                </form.group>
                                <form.group>
                                    <form.label>Location</form.label>
                                    <form.control className="text-field lives-at-home-input" type="text" name="nursingHomeAddress" placeholder="Paikkakunta" value="{formData.nursingHomeAddress}" onChange="{handleChange}"></form.control>
                                </form.group>
                            </div>
                        )}

                        <row 100="" className="justify-content-md-center" style="{{" minHeight:="" }}="">
                            <col xs="{6}">
                                {user.role === "customer" && (
                                    <button style="{{" float:="" "left",="" }}="" className="tallenna user-form-button" onClick="{back}">
                                        {`◄ Previous`}
                                    </button>
                                )}
                            
                            <col xs="{6}">
                                <button style="{{" float:="" "right",="" }}="" type="submit" className="tallenna senior-button" disabled="{btnDisabled}">
                                    {user.role === "senior" ? "Tallenna" : "Seuraava Lisää läheiset ►"}
                                </button>
                                <div style="{{" float:="" "right",="" }}="">
                                    {error && <message variant="danger">{error.message}</message>}
                                    {!error && message && <message variant="danger">{message}</message>}
                                </div>
                            
                        </row>
                    </form>
                
            </row>
        </container>
    );
};
export default SeniorDetail;
</htmlinputelement>