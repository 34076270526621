import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { getNurseProfileRequest, updateNursesRequest } from "../redux/actions";
import Message from "./Message";
import Loader from "./Loader";
import FormContainer from "./FormContainer";
import { AppState } from "../redux/types";
import AutoComplete from "./Autocomplete";
import { getOptions, getDefaultOptions } from "../utils/autocomplete";

const NurseEditForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const user = useSelector((state: AppState) => state.user);
  const nurses: any = useSelector((state: any) => state.nurses);
  const seniorHomes = useSelector((state: AppState) => state.seniorHomes.list);
  const { error, loading } = nurses;
  const [formData, setFormData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    updatedAt: "",
    seniors: [],
    seniorHomeIds: "",
  });
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    dispatch(getNurseProfileRequest(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (user && (user.isAdmin || user.role === "it_support")) {
      const _seniors =
        nurses.seniorList &&
        nurses.profile &&
        getDefaultOptions(nurses.seniorList, nurses.profile.seniors);

      setFormData((prevValue: any) => {
        return {
          ...prevValue,
          id: nurses.profile?.id || "",
          firstName: nurses.profile?.firstName || "",
          lastName: nurses.profile?.lastName || "",
          email: nurses.profile?.email || "",
          mobileNumber: nurses.profile?.mobileNumber || "",
          updatedAt: nurses.profile?.updatedAt || "",
          seniorHomeIds: String(nurses.profile?.seniorHomeIds) || "",
          seniors: _seniors || [],
        };
      });
    } else {
      history.push("/kirjaudu");
    }
  }, [dispatch, history, user, nurses]);

  const handleChange = (e: React.ChangeEvent<htmlinputelement>) => {
    const { value, name } = e.target;

    setIsDisabled(false);
    setFormData((prevValue: any) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const handleAssignedSenior = (seniors: any) => {
    setIsDisabled(false);
    setFormData((prevValue: any) => {
      return {
        ...prevValue,
        seniors: seniors,
      };
    });
  };

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    let nurseData = {
      id: formData.id,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      mobileNumber: formData.mobileNumber,
      seniorHomeIds:
        formData.seniorHomeIds === "" ? null : Number(formData.seniorHomeIds),
      seniors: formData.seniors.map((senior: any) => senior.value),
    };
    // dispatch(updateNursesRequest({nurseData}));
    dispatch(updateNursesRequest({ nurseData: nurseData, history: history }));
  };

  return (
    <formcontainer>
      <h1 className="user-form-header">Edit Nurse Profile</h1>
      {loading ? (
        <loader></loader>
      ) : error ? (
        <message variant="danger">{error.message}</message>
      ) : (
        <form onSubmit="{submitHandler}" className="user-form">
          <form.group controlId="firstname-nurse">
            <form.label>*First Name</form.label>
            <form.control type="text" placeholder="First Name" name="firstName" value="{formData.firstName}" onChange="{handleChange}" disabled="{user.isAdmin" ?="" false="" :="" true}=""></form.control>
          </form.group>

          <form.group controlId="lastname-nurse">
            <form.label>*Last Name</form.label>
            <form.control type="text" placeholder="Last Name" name="lastName" value="{formData.lastName}" onChange="{handleChange}" disabled="{user.isAdmin" ?="" false="" :="" true}=""></form.control>
          </form.group>

          <form.group controlId="email-nurse">
            <form.label>*Email</form.label>
            <form.control type="email" placeholder="Email" name="email" value="{formData.email}" onChange="{handleChange}" disabled="{user.isAdmin" ?="" false="" :="" true}=""></form.control>
          </form.group>

          <form.group controlId="mobileNumber-nurse">
            <form.label>*Phone Number</form.label>
            <form.control type="number" placeholder="0" name="mobileNumber" value="{formData.mobileNumber}" onChange="{handleChange}" disabled="{user.isAdmin" ?="" false="" :="" true}=""></form.control>
          </form.group>

          <form.group controlId="assignedSenior-nurse">
            <form.label>Senior Assigned</form.label>
            <autocomplete options="{getOptions(nurses.seniorList," formData.seniors)}="" value="{formData.seniors}" handleOptions="{handleAssignedSenior}" placeholder="{&quot;Enter" Senior="" Name="" ID"}="" isDisabled="{user.isAdmin" ?="" false="" :="" true}=""></autocomplete>
          </form.group>

          <form.group controlId="seniorHome">
            <form.label>Care home</form.label>
            <form.control as="select" name="seniorHomeIds" onChange="{handleChange}" value="{String(formData.seniorHomeIds)}" disabled="{user.isAdmin" ?="" false="" :="" true}="">
              <option value="">Choose a nursing home</option>
              {seniorHomes.map((seniorHome) => {
                return (
                  <option value="{seniorHome.id}" key="{seniorHome.id}">
                    {seniorHome.name}
                  </option>
                );
              })}
            </form.control>
          </form.group>

          {user.isAdmin && (
            <button style="{{" float:="" "right"="" }}="" type="submit" className="tallenna user-form-button" disabled="{isDisabled}">
              Submit
            </button>
          )}
        </form>
      )}
    </formcontainer>
  );
};
export default NurseEditForm;
</htmlinputelement>