import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Col, Container, Form, Row, InputGroup} from 'react-bootstrap'
import {Link, useHistory} from 'react-router-dom'

import Message from './Message'
import {clearUserError, registerCustomerRequest} from '../redux/actions'
import {AppState} from '../redux/types'

const RegisterCustomerForm = () => {
    const [username, setUserName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [role, setRole] = useState('customer')
    const [message, setMessage] = useState('')
    const [btnDisabled, setBtnDisabled] = useState(true)
    let error = useSelector((state: AppState) => state.user.error)
    const selectedServices = useSelector((state: AppState) => state.cart.inCart)
    const history = useHistory()

    const [showPass, setShowPass] = useState(false)
    const showPassHandler = () => {
        setShowPass(current => !current)
    }

    const dispatch = useDispatch()

    useEffect(() => {
        // console.log(username);
        // console.log(email);
        // console.log(password);
        // console.log(firstName);
        // console.log(lastName);
        if (!username || !email || !password || !firstName || !lastName) {
            setBtnDisabled(true)
        } else {
            setBtnDisabled(false)
        }
        let msg = ''
        if (password.length > 0 && password.length < 11) {
            msg += 'Salasana on liian lyhyt'
        }
        if (username.length > 0 && email.length > 0 && email !== username) {
            msg += 'Sähköpostit eivät täsmää\n'
        }
        setMessage(msg)
    }, [username, email, password, firstName, lastName])

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault()
        if (password.length >= 11) {
            setRole(role)
            const redirect = selectedServices.length > 0 ? '/toimitus' : null;
            dispatch(
                registerCustomerRequest(
                    username,
                    email,
                    password,
                    firstName,
                    lastName,
                    role,
                    history,
                    redirect
                )
            )
            // history.push('/kirjaudu')
            setUserName('')
            setEmail('')
            setPassword('')
            setFirstName('')
            setLastName('')
            setMessage('Kiitos, rekisteröinti onnistui.')
            setTimeout(() => {
                setMessage('');
                dispatch(
                    clearUserError()
                )
            }, 3000)
        }
    }

    return (
        <container className="register-form-container">
            {error && <message variant="danger">{error}</message>}
            {message && <message className="message" variant="danger">{message}</message>}
            <form onSubmit="{submitHandler}" className="register-form">
                <h1 className="register-header mb-4 text-center">Create an account</h1>
                <form.group controlId="username-register">
                    <form.label>E-mail address</form.label>
                    <form.control type="email" name="email" placeholder="Sähköpostiosoite" value="{username}" onChange="{(e)" ==""> {
                            setUserName(e.target.value)
                        }}
                    />
                </form.control></form.group>
                <form.group controlId="email-register">
                    <form.label>Repeat e-mail address</form.label>
                    <form.control name="email" type="email" placeholder="Toista sähköpostiosoite" value="{email}" onChange="{(e)" ==""> setEmail(e.target.value)}
                    />
                </form.control></form.group>
                <form.group controlId="password-register">
                    <form.label>Password</form.label>
                    <inputgroup>
                    <form.control name="password" type="{showPass" ?="" 'text'="" :="" 'password'}="" placeholder="Salasana" value="{password}" onChange="{(e)" ==""> setPassword(e.target.value)}
                    />
                        <button variant="outline-secondary shadow-none show-password-btn" onClick="{showPassHandler}">
                            <i className="{showPass" ?="" 'fas="" fa-eye-slash'="" :="" fa-eye'}=""></i>
                        </button>
                    </form.control></inputgroup>
                    <small className="password-length">At least 11 characters. You can see your password by clicking on the eye icon</small>
                </form.group>
                <form.group as="{Row}">
                    <col sm="{6}">
                        <form.label>First name</form.label>
                        <form.control required="" type="text" name="firstName" placeholder="Etunimi" value="{firstName}" onChange="{(e)" ==""> setFirstName(e.target.value)}
                        />
                    
                    <col sm="{6}">
                        <form.label>Surname</form.label>
                        <form.control required="" name="lastName" type="text" placeholder="Sukunimi" value="{lastName}" onChange="{(e)" ==""> setLastName(e.target.value)}
                        />
                    
                </form.control></form.control></form.group>
                <small className="terms-and-conditions">
                    By pressing 'Save' I confirm my acceptance <link to="terms" target="_blank" className="conditions">service
                    terms and conditions of use and <link to="copyright" target="_blank" className="conditions">privacy policy.
                </small><br>
                <button className="register-button tallenna" type="submit" disabled="{btnDisabled}">
                    Save
                </button>
                <row>
                    <col className="forgot-password">
                        <link className="forgot-password" to="/kirjaudu">Already registered? Sign in
                    
                </row>
            </form>
        </container>
    )
}

export default RegisterCustomerForm
