import {Col, Container, Row} from 'react-bootstrap'

const ContactInfo = () => {
    return (
        <>
            <container fluid="" style="{{" height:="" `calc(100vh="" -="" 66px)`,="" width:="" '100vw',="" backgroundRepeat:="" 'no-repeat',="" backgroundImage:="" `url(https:="" senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com="" connect2.jpg)`,="" backgroundSize:="" 'cover',="" backgroundPosition:="" 'right="" top'="" }}="" className="img-fluid contact-pic">
                <row>
                    <col xs="{12}" md="{6}" lg="{7}" xl="{8}" className="contact-texts-column">
                        <p className="ota-yhteyttä">Contact us</p>
                        <p className="apu">and ask for more!</p>
                    
                    <col xs="{12}" md="{6}" lg="{5}" xl="{4}" className="contact-texts-column">
                        <container className="contact-container justify-content-md-center">
                            <div className="contact-form ">
                                <div className="texts-for-companies">
                                    Digihappy Mediti Oy
                                   <br/>
                                    Otakaari 5, 02150 Espoo
                                   <br/>
                                    tel <a className="linkki" href="tel:010 517 2070">010 517 2070</a>
                                   <br/>
                                    WhatsApp <a className="linkki" href="https://wa.me/358503210100" target="_blank">050
                                    321 0100</a>
                                   <br/>
                                   <a className="linkki" href="mailto:digihappy@mediti.fi">digihappy@mediti.fi</a>
                                </div>
                            </div>
                        </container>
                    
                </row>
            </container>
        </>
    )
}

export default ContactInfo
