import React, { useState } from "react";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { saveStripePaymentMethod } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { loadStripe, StripeCardElement } from "@stripe/stripe-js";
import { AppState } from "../redux/types";

export default function CheckoutForm(params: any) {
    const [stripePromise] = useState(loadStripe(params.stripePublicKey, { locale: "fi" }));
    return (
        <elements stripe="{stripePromise}">
            <checkoutform2 setSPM="{params.setSPM}" handleForm="{params.handleForm}" handleBack="{params.handleBack}"></checkoutform2>
        </elements>
    );
}

const ELEMENT_OPTIONS = {
    style: {
        base: {
            fontSize: "18px",
            color: "#424770",
            letterSpacing: "0.025em",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#9e2146",
        },
    },
    hidePostalCode: true,
};

function CheckoutForm2(params: any) {
    const stripe = useStripe();
    const elements = useElements();
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState("");
    const user = useSelector((state: AppState) => state.user);
    const dispatch = useDispatch();

    function stripePaymentMethodHandler(result: any, event: any) {
        if (result.error) {
            console.log(result.error.message);
            setError(result.error.message);
            // params.reload_subscriptions();
            setProcessing(false);
        } else {
            // Otherwise send paymentMethod.id to your server
            console.log("save payment method");
            setProcessing(false);
            dispatch(saveStripePaymentMethod(result.paymentMethod.id));
            params.setSPM(result.paymentMethod.id);
            params.handleForm(event);
        }
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setError("");
        setProcessing(true);
        if (!stripe || !elements) {
            return;
        }
        console.log(params);
        try {
            let result = await stripe.createPaymentMethod({
                type: "card",
                card: elements.getElement(CardElement) as StripeCardElement,
                billing_details: {
                    name: user.id ? `${user.firstName} ${user.lastName}` : "guest",
                    email: user.email || "digihappy@mediti.fi",
                },
            });
            console.log(result);
            stripePaymentMethodHandler(result, event);
        } catch (err: any) {
            console.log("stripe error:", err);
        }
    };

    return (
        <form>
            <div>
                <cardelement options="{ELEMENT_OPTIONS}"></cardelement>
            </div>
            {error && <alert variant="danger">{error}</alert>}
            <row className="justify-content-md-center">
                <col xs="{6}">
                    <button type="button" className="tallenna paymentmethod-back-button" onClick="{params.handleBack}">
                        Return
                    </button>
                
                <col xs="{6}">
                    <button className="tallenna" type="button" disabled="{!stripe" ||="" processing}="" onClick="{handleSubmit}" style="{{" position:="" "absolute",="" right:="" 0,="" }}="">
                        Next
                    </button>
                
            </row>
        </form>
    );
}
