import React from "react";
import { Container } from "react-bootstrap";
import "./banner.css";
interface BannerProps {
    backgroundImage?: string;
    label?: string;
    height: string;
    children: React.ReactNode;
}
const Banner: React.FC<bannerprops> = ({ backgroundImage, height, label, children }) => {
    return (
        <container fluid="" style="{{" height:="" height,="" backgroundImage:="" `url('${backgroundImage}')`,="" }}="" aria-label="{label}" className="digi-banner-container">
            {children}
        </container>
    );
};

export default Banner;
</bannerprops>