import { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import api from "../utils/api-helper/api";
import FormContainer from "../components/FormContainer";

class RecoverPassword extends Component {
    state = {
        username: "",
        submitted: false,
    };

    handleChange = (e: React.ChangeEvent<htmlinputelement>) => {
        this.setState({ username: e.target.value });
    };

    sendPasswordResetEmail = (e: React.FormEvent) => {
        e.preventDefault();
        const { username } = this.state;
        console.log("click");
        console.log("email", username);
        api.post(`/email/reset-password/user/${username}`);
        this.setState({ username: "", submitted: true });
    };
    render() {
        const { username, submitted } = this.state;
        return (
            <formcontainer>
                <h2 className="my-4 text-center">Change your password</h2>
                {submitted ? (
                    <div className="reset-password-form">
                        <p>If your account is in our system, we will send you an email with a link to change your password.</p>
                        <link to="/kirjaudu" className="tallenna">
                            Log in
                        
                    </div>
                ) : (
                    <div className="reset-password-form-wrapper">
                        <p className="my-3">Enter your email address and we will send you instructions on how to change your password.</p>
                        <form onSubmit="{this.sendPasswordResetEmail}">
                            <form.label htmlFor="email" className="form-label">
                                E-mail address
                            </form.label>
                            <form.group controlId="recover-password-email">
                                <form.control required="" onChange="{this.handleChange}" value="{username}" placeholder="Sähköpostiosoite"></form.control>
                            </form.group>
                            <button className="password-reset-btn tallenna" type="submit" style="{{" width:="" "100%"="" }}="">
                                Send a request to change your password
                            </button>
                        </form>
                        <link to="/kirjaudu" className="muistan-salasanan">
                            I remember my password
                        
                    </div>
                )}
            </formcontainer>
        );
    }
}
export default RecoverPassword;
</htmlinputelement>