import { Link, RouteComponentProps, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Alert, Button, Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import StepsHeader from "../components/StepsHeader";
import CartItem from "../components/CartItem";
import { AppState, ProductType } from "../redux/types";
import StepsNotification from "../components/StepsNotification";
import React from "react";

const Cart: React.FC<routecomponentprops> = ({ history }) => {
    const location = useLocation();
    const category = new URLSearchParams(location.search).get("category");
    const user = useSelector((state: AppState) => state.user);
    const selectedServices = useSelector((state: AppState) => state.cart.inCart);

    const submitHandler = () => {
        if (user.isLoggedIn || category === "alarm") {
            history.push("/toimitus");
        } else {
            history.push("/luo_tili");
        }
    };

    // back button
    const handleClick = () => {
        history.goBack();
    };

    const totalPrice = selectedServices.reduce((total, item) => total + (item.price || 0), 0);

    return (
        <>
            <stepsheader step1="" user="{1}" payment="{1}" account="{1}"></stepsheader>
            <container className="shoppingcart-container">
                <row>
                    <col className="shoppingcart-services-col" md="{8}">
                        <h1 className="shoppingcart-header">Shopping cart</h1>
                    
                </row>
                <row>
                    <col className="shoppingcart-services-col" md="{8}">
                        {selectedServices.length === 0 ? (
                            <alert style="{{" background:="" "rgba(78,81,128,0.8)",="" color:="" "#ffffff",="" marginBottom:="" 42,="" }}="">
                                Your shopping cart is empty{" "}
                                <link to="/osta" style="{{" color:="" "#ffffff",="" }}="">
                                    Select a service
                                
                            </alert>
                        ) : (
                            <listgroup variant="flush">
                                {selectedServices.map((service: ProductType, key) => (
                                    <cartitem key="{key}" service="{service}"></cartitem>
                                ))}
                            </listgroup>
                        )}
                        <button onClick="{handleClick}" className="btn-block shoppingcart-back-button">
                            Previous
                        </button>
                    
                    <col md="{4}" className="price-sum-col">
                        <card>
                            <listgroup variant="flush">
                                <listgroup.item className="text-center">
                                    <h2>{totalPrice} €</h2>
                                </listgroup.item>
                                <listgroup.item>
                                    <button type="button" className="btn-block tallenna" onClick="{submitHandler}" disabled="{!selectedServices.length}">
                                        Next
                                    </button>
                                </listgroup.item>
                            </listgroup>
                        </card>
                    
                </row>
            </container>
            <stepsnotification></stepsnotification>
        </>
    );
};

export default Cart;
</routecomponentprops>