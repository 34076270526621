import {Container} from 'react-bootstrap'

const PrivacyPolicy = () => {
    return (
        <>
            <container fluid="">
                <section className="privacy-policy-text">
                    <div className="privacy-policy-dates-container ">
                        {/*<div className="privacy-policy-dates-left">*/}
                        {/*    Laatimispäivämäärä: 14.6.2021*/}
                        {/*</div>*/}
                        {/*<div className="privacy-policy-dates-right">*/}
                        {/*    Viimeisin muutos: 14.6.2021*/}
                        {/*</div>*/}
                    </div>
                    <div className="privacy-policy-header">
                        Privacy policy
                    </div>
                    <div className="text-for-companies-first">
                        <p>
                            This is a privacy and data protection notice in accordance with the EU General Data Protection Regulation (GDPR).
                            The date of drafting is 14.6.2021. <i>The last modification was made on 12.7.2024.</i>
                        </p>
                        <p>
                            Keeper of the register
                        </p>
                        <p>
                            The company controls the information you provide and is responsible for the personal data in accordance with the Data Protection Act.
                        </p>
                        <p>
                            Company information
                        </p>
                        Digihappy Oy
                        <br>
                        Otakaari 5 A
                        <br>
                        02150 Espoo
                        <br>
                        Tel. 010 517 2070
                    </div>

                    <div className="privacy-policy-header2">
                        Name of the register
                       <br/>
                        Digihappy Oy's customer register
                       <br/>
                        <br/>
                        Legal basis and purpose of use of personal data
                    </div>

                    <div className="text-for-companies-first">
                        <p>
                            Under the EU General Data Protection Regulation, an individual's informed consent, such as a contract, is
                            a legal basis for the processing of personal data. The service also records the actual
                            of the customer's close relatives, and the customer must have the consent of the persons concerned to disclose this information.
                            consent of those persons.
                        </p>
                        <p>
                            The purpose of the processing of personal data is to contact customers, to provide the service ordered.
                            and to maintain the customer relationship. The data will not be used for automated decision-making.
                            or profiling.
                        </p>
                    </div>

                    <div className="privacy-policy-header">
                        Data content of the register
                    </div>
                    <div className="text-for-companies-first">
                        <p>
                            The register may process the following categories of data, which are relevant to the purpose of the register.
                            necessary for the purposes of the register:
                            </p><ul>
                                <li>Name</li>
                                <li>Contact information (such as addresses, phone numbers, email addresses)</li>
                                <li>Language</li>
                            </ul>
                        <p></p>
                        <p>
                            Information relating to the customer and other relevant contacts, such as:
                            </p><ol>
                                <li>
                                    Start and end time of the relevant contact
                                </li>
                                <li>
                                    Identifying information related to the use of the Services (e.g. Customer Number,
                                    usernames and passwords) and electronic communication identification data (e.g.
                                    IP address) and service options
                                </li>
                                <li>
                                    Information on purchases made as a known customer and the different stages of the purchasing process, and
                                    transactions
                                </li>
                                <li>
                                    Information related to billing and collection
                                </li>
                                <li>
                                    Contact and communication with customers and other relevant contacts in the different
                                    channels and media (e.g. feedback and recordings of customer service calls)
                                </li>
                                <li>
                                    Direct marketing authorisations and prohibitions and marketing targeting
                                    information
                                </li>
                                <li>
                                    Data produced by or relating to the data subject
                                </li>
                                <li>
                                    Information on notifications published by the data subject
                                </li>
                                <li>
                                    Event and user analysis data
                                </li>
                                <li>
                                    Changes to the information identified above
                                </li>
                            </ol>
                        <p></p>
                    </div>

                    <div className="privacy-policy-header">
                        Regular sources of information
                    </div>

                    <div className="text-for-companies-first">
                        <p>
                            Personal data are collected from the data subject and from the controller's systems when.
                            the data subject registers for and uses the services.
                           <br/>
                            Personal data may be collected and updated by the controller and by the data controller's associated companies.
                            registers of the controller and of companies belonging to the same group, the population information system,
                            credit information registers, the registers of the ASML Finnish Association for the Marketing of Clients
                            and other similar public or private registers providing similar information services.
                            registers and data sources, as well as from the controller's partners.
                        </p>
                    </div>

                    <div className="privacy-policy-header">
                        Disclosure and transfer of data
                    </div>

                    <div className="text-for-companies-first">
                        <p>
                            Data will not be transferred outside the European Union or the European Economic Area unless it is.
                            necessary for the technical implementation of the service. In such cases, the controller shall ensure that
                            an adequate level of data protection as required by law.
                        </p>
                    </div>

                    <div className="privacy-policy-header">
                        Protection of the register
                    </div>

                    <div className="text-for-companies-first">
                        <p>
                            Digihappy Oy's customer database is located in the European Economic Area Amazon Web Services (AWS)
                            cloud service provider and is therefore highly technically secure.
                            to prevent unauthorised external access. Cloud service providers protect their customers
                            only from external technical threats and Digihappy Oy is, according to the terms of use, personally responsible for
                            for its own environment. To the extent that Digihappy is responsible for its own technical
                            infrastructure, we maintain and secure our own infrastructure.
                        </p>
                        <p>
                            The register is used only by persons trained for the task for whose performance the register is
                            the use of the data is necessary. All access rights to the register are work-related and
                            each user has only the rights necessary for his or her task. The register is protected by
                            technical controls, which are under the control of Digihappy Oy and are subject to continuous monitoring.
                            subject to continuous monitoring.
                        </p>
                    </div>

                    <div className="privacy-policy-header">
                        Data retention period
                    </div>

                    <div className="text-for-companies-first">
                        <p>
                            The personal data of the data subject will be kept only for the time necessary to.
                            fulfil the purposes of use described in this notice. In addition, some data may be
                            longer, to the extent necessary to fulfil legal obligations, such as
                            accounting and consumer responsibilities, and for the proper performance of those responsibilities.
                            demonstrate proper implementation.
                        </p>
                    </div>

                    <div className="privacy-policy-header">
                        Right of inspection, prohibition and correction
                    </div>

                    <div className="text-for-companies-first">
                        <p>
                            The data subject has the right to check what data concerning him or her has been entered in the register.
                            The request for inspection must be sent in writing, signed and addressed to
                            person responsible for the register. The request for inspection may also be made in person
                            at the premises of the controller at the above address. A request for inspection may be made
                            free of charge once a year. Requests for inspection will be answered at the latest one month after
                            after the request has been submitted.
                        </p>
                        <p>
                            Data subjects have the right to object to the use of their data for direct marketing, distance selling or.
                            other direct marketing, opinion polls and market research. The data subject also has the right to
                            have the right to obtain the rectification of inaccurate data or the erasure of all their data
                            by informing the controller's customer service department at <a href="mailto:digihappy@mediti.fi">digihappy@mediti.fi</a>.
                        </p>
                    </div>
                </section>

            </container>
        </>
    )
}

export default PrivacyPolicy
