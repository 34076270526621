import React, { ButtonHTMLAttributes } from "react";
import "./button.css";
import { classNames } from "../../utils/classnames";

interface ButtonProps extends ButtonHTMLAttributes<htmlbuttonelement> {
    primary?: boolean;
    secondary?: boolean;
    children: React.ReactNode;
    emphasized?: boolean;
    className?: string;
}

const Button: React.FC<buttonprops> = ({ primary = false, secondary = false, emphasized = false, className, children, ...props }) => {
    return (
        <button className="{classNames(&quot;digi-btn&quot;," {="" "digi-btn-primary":="" primary,="" "digi-btn-secondary":="" secondary,="" "digi-btn-emphasized":="" emphasized="" },="" className)}="" {...props}="">
            {children}
        </button>
    );
};

export default Button;
</buttonprops></htmlbuttonelement>