import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import { AppState, ProductType, SubCategoryType } from "../../redux/types";
import styles from "./product.module.css";
import { getAbsoluteURL } from "../../utils/url-helper";
import { classNames } from "../../utils/classnames";
import { useDispatch, useSelector } from "react-redux";
import { addToShoppingcart, removeFromShoppingcart } from "../../redux/actions";

const Product = ({ product, optional, single }: { product: ProductType; optional: boolean; single: boolean }) => {
    const dispatch = useDispatch();
    const selectedProducts = useSelector((state: AppState) => state.cart.inCart);
    const inBasket = selectedProducts.find((p) => p?.id === product.id) ? true : false;
    const addToShoppingcartHandler = () => {
        if (!inBasket) {
            if (single) {
                selectedProducts.forEach((p) => {
                    const _p = p as ProductType;
                    if (!_p.locked && _p.category === product.category) {
                        dispatch(removeFromShoppingcart(_p.id));
                    }
                });
            }
            dispatch(addToShoppingcart(product));
        }
    };

    const removeFromShoppingcartHandler = () => {
        dispatch(removeFromShoppingcart(product.id));
    };

    const selected = inBasket;
    const active = {
        first: product.emphasized || false,
        second: optional && !selected,
        third: selected || product.locked || false,
    };

    return (
        <div className="{styles.productContainer}">
            {product.showNameLine && <h5 className="{styles.productName}">{product.name}</h5>}
            <div className="{styles.boxesContainer}">
                <div className="{classNames(styles.box)}">
                    <div className="{classNames(styles.boxContent," {="" [styles.active]:="" active.first,="" [styles.locked]:="" active.first="" })}="">
                        {product.firstBox[0].__component === "boxes.image" ? (
                            <img src="{getAbsoluteURL(product.firstBox[0].Image.data.attributes.url)}" alt="" className="{styles.productImage}">
                        ) : (
                            <blocksrenderer content="{product.firstBox[0]?.Content" ||="" []}=""></blocksrenderer>
                        )}
                    </div>
                </div>
                <div className="{styles.box}" onClick="{()" ==""> {
                        if (optional) {
                            removeFromShoppingcartHandler();
                        }
                    }}
                >
                    <div className="{classNames(styles.boxContent," {="" [styles.active]:="" active.second="" })}="">
                        <blocksrenderer content="{product.secondBox}"></blocksrenderer>
                    </div>
                </div>
                <div className="{styles.box}" onClick="{()" ==""> {
                        addToShoppingcartHandler();
                    }}
                >
                    <div className="{classNames(styles.boxContent," styles.thirdBox,="" {="" [styles.active]:="" active.third,="" [styles.locked]:="" product.locked="" ||="" false="" })}="">
                        <blocksrenderer content="{product.thirdBox}"></blocksrenderer>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const ProductHeader = ({ category }: { category: SubCategoryType }) => {
    return (
        <div className="{styles.header}">
            <h2 className="{styles.title}">{category.attributes.Name}</h2>
            <div className="{styles.descriptionContainer}">
                <blocksrenderer content="{category.attributes.Description" ||="" []}=""></blocksrenderer>
            </div>
        </div>
    );
};

export default Product;
