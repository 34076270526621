import {Button, Container} from 'react-bootstrap'
import React from "react";

const Intro = ({onSelect}: any) => {
    const handleClick = () => {
        onSelect('asiakas')
    }

    return (
        <container className="introduction">
            <h1 className="introduction-header">Fill in your customer account contact details</h1>
            <h4 className="welcome-greeting">
                Thank you for your order and welcome to Digihappy!
            </h4>
            <p className="instructions">
                By filling in your own, the senior's and the relatives' contact details, you can access the service.
            </p>
            <p style="{{textAlign:" 'right'}}="">
                <button onClick="{handleClick}" className="tallenna">
                    Next
                </button>
            </p>
            <p>
                Note! We will send you 2 emails regarding your order and your account. If you use a Gmail address,
                please also check the "Promotions" folder.
            </p>
        </container>
    )
}

export default Intro
