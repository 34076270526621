import styles from "./thanks.module.css";
import StepsHeader from "../../components/StepsHeader";

export default function Thanks() {
    return (
        <div>
            <stepsheader step1="" step2="" step3="" step4="" step5=""></stepsheader>
            <div className="{styles.container}">
                <h2 className="{styles.header}">Your order is ready!</h2>
                <p className="{styles.message}">You will receive an email with a confirmation of your subscription and information on how to start the service. Delivery time is usually 1-2 weeks.</p>
                <p className="{styles.note}">Note! If you are using a Gmail address, please also check the "Promotions" folder.</p>
            </div>
        </div>
    );
}
