import TextOnImage from "../components/TextOnImage";
import React, { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import celia from "../images/partners/celia.png";
import villa_tapiola from "../images/partners/villa_tapiola.png";
import ainola from "../images/partners/ainola.png";
import loistohoiva from "../images/partners/loistohoiva.png";
import elsa from "../images/partners/elsa.png";
import lehmuskartano from "../images/partners/lehmuskartano.png";
import uvky from "../images/partners/uvky.jpg";
import domacare from "../images/partners/domacare.png";
import helykodit from "../images/partners/helykodit.png";
import hyvan_mielen_kotihoito from "../images/partners/hyvan_mielen_kotihoito.png";
import tunaberg from "../images/partners/tunaberg.jpg";
import linkedin from "../images/linkedin.png";
import aboutus1 from "../images/aboutus1.jpg";

const AboutUs = () => {
    const [video, setVideo] = useState(false);
    const [frameHeight, setFrameHeight] = useState(550);
    const myRef = useRef<htmlheadingelement>(null);

    return (
        <>
            <textonimage height="{`calc(100vh" -="" 66px)`}="" width="100vw" text1="{&quot;Meistä&quot;}" backgroundImage="{&quot;https://senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com/about_us_cover.jpg&quot;}" backgroundSize="cover" backgroundPosition="center center" backgroundRepeat="no-repeat" imgClassName="forcompaniespic" className1="aboutus-text-on-image col-md-8 col-12"></textonimage>
            <h2 id="products" className="subscribe-header subscribe-header-blue">
                Why choose Digihappy?
            </h2>
            <section className="texts-for-companies">
                <div className="text-div-for-companies">
                    <p className="text-for-companies-first">
                        A happy old age means active relationships, meaningful activities and security. With Digihappy services, you can always connect with a loved one. You can choose a ready-made service according to the senior's ability to function and the needs of a relative or carer.
                        or carer does not have to act as an IT support.
                    </p>
                </div>
                <div className="text-div-for-companies">
                    <p className="text-for-companies-first">
                        Watch the video to see how the Effortless Senior Tablet makes it easier for Rail to keep in touch with her daughter, who lives in a different city. And how Asta Martin, manager of the Ainola Senior Hotel, has experienced the Digihappy service.
                    </p>
                </div>
            </section>
            <section className="texts-for-companies">
                {video ? (
                    <iframe height="{frameHeight}" src="{`https://www.youtube.com/embed/fprEcs7sQKc?autoplay=1&rel=0&color=white&modestbranding=1`}" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" title="Digihappy Senior Tablets" style="{{" margin:="" "0="" auto",="" width:="" "100%",="" }}=""></iframe>
                ) : (
                    <div style="{{" position:="" "relative",="" }}="" ref="{myRef}">
                        <img id="video-cover" src="https://senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com/fprEcs7sQKc_maxresdefault.jpg" alt="Digihappy Senior Tablets" style="{{" height:="" "auto",="" width:="" "100%",="" }}="">
                        <button id="play" className="play-btn" onClick="{()" ==""> {
                                // @ts-ignore
                                setFrameHeight(myRef?.current?.clientHeight);
                                setVideo(true);
                            }}
                        >
                            <i className="fas fa-play" style="{{" color:="" "#ffffff",="" fontSize:="" "6rem",="" }}=""></i>
                        </button>
                    </div>
                )}
            </section>
            <h2 id="products" className="subscribe-header subscribe-header-blue">
                The story of digihappy
            </h2>
            <section className="texts-for-companies">
                <div className="text-div-for-companies">
                    <p className="text-for-companies-first">
                        Digihappy is a Finnish company that Tiina founded after discovering how difficult it was to keep in touch with her father who lived in a nursing home. Tiina is a doctor, an MD and an entrepreneur{""}
                        <a className="linkki" href="https://www.linkedin.com/in/tiinaleivomd/?originalSubdomain=fi" target="_blank" rel="noreferrer">
                            <img src="{linkedin}" alt="LinkedIn" style="{{" height:="" "1.5rem",="" marginBottom:="" "0.2rem",="" }}="">
                        </a>
                        , which has gathered a skilled core team and talented Finnish and international partners. We provide services to seniors, families and care providers. Our office is located in Otaniemi, Espoo.
                    </p>
                </div>
            </section>

            <section className="" style="{{" margin:="" "2rem",="" }}="">
                <row className="align-items-end justify-content-center">
                    <col className="col-md-auto aboutus-photo-box">
                        <div className="aboutus-photo">
                            <div style="{{" backgroundImage:="" "url(https:="" senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com="" Tiina%2C+new.png)",="" }}="" className="aboutus-photo-img-1"></div>
                        </div>
                        <div className="aboutus-photo-caption">
                            <b>Tiina</b>
                           <br/>
                            Founder of Digihappy
                        </div>
                    
                    <col className="col-md-auto aboutus-photo-box">
                        <div className="aboutus-photo">
                            <div style="{{" backgroundImage:="" "url(https:="" senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com="" johanna.jpeg)",="" }}="" className="aboutus-photo-img-1"></div>
                        </div>
                        <div className="aboutus-photo-caption">
                            <b>Johanna</b>
                           <br/>
                            UX, COO, Customer Experience and Operations
                        </div>
                    
                    <col className="col-md-auto aboutus-photo-box" xl="4" lg="6" md="6">
                        <div className="aboutus-photo">
                            <div style="{{" backgroundImage:="" `url(${aboutus1})`,="" width:="" "100%",="" }}="" className="aboutus-photo-img-1"></div>
                        </div>
                        <div className="aboutus-photo-caption">CTO Joel, Johanna and Tiina</div>
                    
                </row>
                <row className="align-items-end justify-content-center">
                    <col className="col-md-auto aboutus-photo-box" xl="4" lg="6" md="6">
                        <div className="aboutus-photo">
                            <div style="{{" backgroundImage:="" "url('="" assets="" images="" about="" digihappy-team.webp')",="" width:="" "100%",="" }}="" className="aboutus-photo-img-2" aria-label="Kolme ihmistä kypärät päässä ajamassa polkupyörillä järven rannalla."></div>
                        </div>
                        <div className="aboutus-photo-caption" style="{{" maxWidth:="" "100%"="" }}="">
                            Digihappy mountain biking On the right Rebecka Swedish-speaking service and, social media manager
                        </div>
                    
                    <col className="col-md-auto aboutus-photo-box">
                        <div className="aboutus-photo">
                            <div style="{{" backgroundImage:="" "url('="" assets="" images="" about="" digihappy-programmer.webp')",="" }}="" className="aboutus-photo-img-1" aria-label="Mies harmaassa paidassa istuu kivellä veden äärellä"></div>
                        </div>
                        <div className="aboutus-photo-caption">
                            Brian
                           <br/>
                            Devops
                        </div>
                    
                    <col className="col-md-auto aboutus-photo-box">
                        <div className="aboutus-photo">
                            <div style="{{" backgroundImage:="" "url(https:="" senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com="" Creole.png)",="" }}="" className="aboutus-photo-img-2"></div>
                        </div>
                        <div className="aboutus-photo-caption">Creole studios, Pavle A, Peter, Orest Full-stack development</div>
                    
                </row>
            </section>

            <h2 id="products" className="subscribe-header subscribe-header-blue">
                Digihappy is a finalist for Digital Act of the Year
            </h2>
            <section className="texts-for-companies">
                <div className="text-div-for-companies">
                    <p className="text-for-companies-first">
                        Digihappy valittiin vuoden Digitaalinen teko 2022 finaaliin kolmen parhaan joukkoon. Tuomaristo perusteli Digihappyn valintaa seuraavasti: ”Suomi ikääntyy ja vanhukset ovat usein heikoimmilla digitalisaatiossa.
                        Digihappy on kehitetty juuri ikäihmisten lähtökohdista ja räätälöity heidän tarpeisiinsa. Yhteydenpitopalvelun kokonaisuus laitteineen ja ohjelmistoineen sekä sen käytön helppous ja selkeys vakuuttavat.” Lue lisää{" "}
                        <a target="_blank" className="linkki" rel="noreferrer" href="https://www.atea.fi/vuoden-digitaalisin-teko-2022/">
                            https://www.atea.fi/vuoden-digitaalisin-teko-2022/
                        </a>
                    </p>
                </div>
            </section>

            <h2 id="products" className="subscribe-header subscribe-header-blue">
                Responsibility is about sustainable products, customer focus and data security
            </h2>
            <section className="texts-for-companies">
                <div className="text-div-for-companies">
                    <p className="text-for-companies-first">
                        For us, being responsible means using only high-quality equipment and supplies that have a long lifespan and can be maintained and recycled. We don't sell directly to an elderly person who doesn't understand what he or she
                        what they are buying. We don't tie the customer down with long contracts. Data security is genuinely important to us in everything we do. Phone sellers cannot make Digihappy video calls. to a tablet. And our goal is to do so
                        cost-effective service so that as many people as possible can get it.
                    </p>
                </div>
            </section>

            <h2 id="products" className="subscribe-header subscribe-header-blue">
                Cooperation is important to us
            </h2>
            <section className="texts-for-companies">
                <div className="text-div-for-companies">
                    <p className="text-for-companies-first">
                        Digihappy works with care service providers, public authorities and associations. We develop services taking into account the wishes of users. We have deep expertise in research, which we also do together
                        with our partners. We are grateful for the opportunity to work with many pioneering organisations.
                    </p>
                </div>
            </section>

            <section className="texts-for-companies">
                <row className="align-items-baseline justify-content-around">
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://villatapiola.fi/villatapiola/index.php" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{villa_tapiola}" alt="Villa Tapiola" className="aboutus-partners">
                        </a>
                    
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://villatapiola.fi/ainola/ainola/index.php" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{ainola}" alt="Ainola Senior Hotel" className="aboutus-partners">
                        </a>
                    
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://loistohoiva.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{loistohoiva}" alt="Great outdoors" className="aboutus-partners">
                        </a>
                    
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://hoivakodit.luvn.fi/fi-FI/hoivakodit/d19f24f84b" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{lehmuskartano}" alt="Clay cartouche" className="aboutus-partners">
                        </a>
                    
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.celia.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{celia}" alt="Celia" className="aboutus-partners">
                        </a>
                    
                </row>
                <row className="align-items-baseline justify-content-around">
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.uvky.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{uvky}" alt="UVKY" className="aboutus-partners">
                        </a>
                    
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://domacare.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{domacare}" alt="Domacare" className="aboutus-partners">
                        </a>
                    
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.helykodit.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{helykodit}" alt="Your local code" className="aboutus-partners">
                        </a>
                    
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://hyvanmielenkotihoito.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{hyvan_mielen_kotihoito}" alt="Good Mind Home Care" className="aboutus-partners">
                        </a>
                    
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.elsa.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{elsa}" alt="Elsa" className="aboutus-partners">
                        </a>
                    
                </row>
                <row className="align-items-baseline justify-content-around">
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.hoitajasi.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{&quot;/assets/images/about/logos/hoitajasi.png&quot;}" alt="Your carer" className="aboutus-partners">
                        </a>
                    
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.hoivapalveluanne.com/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{&quot;/assets/images/about/logos/hoivapalveluanne.webp&quot;}" alt="Your care service" className="aboutus-partners">
                        </a>
                    
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.seniorhem.fi/tunaberg-seniorhem-och-tunaro-vardhem/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{tunaberg}" alt="Tunaberg seniorhem" className="aboutus-partners">
                        </a>
                    
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://posifon.se/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{&quot;/assets/images/about/logos/posifon.png&quot;}" alt="Posifon" className="aboutus-partners">
                        </a>
                    
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.sanitum.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{&quot;/assets/images/about/logos/Sanitum.jpg&quot;}" alt="Sanitum" className="aboutus-partners">
                        </a>
                    
                </row>
                <row className="align-items-baseline justify-content-around">
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.omegayksi.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{&quot;/assets/images/about/logos/omegayksi.png&quot;}" alt="Omegayksi" className="aboutus-partners">
                        </a>
                    
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://satakunnanhyvinvointialue.fi/palvelut/ikaantyneet/kotona-asumisen-tukipalvelut/funteeraamo/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{&quot;/assets/images/about/logos/Funteeraamo.png&quot;}" alt="Funteeraamo" className="aboutus-partners">
                        </a>
                    
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.jomala.ax/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="/assets/images/about/logos/jomala.png" alt="Jomala" className="aboutus-partners">
                        </a>
                    
                </row>
            </section>
        </>
    );
};

export default AboutUs;
</htmlheadingelement>