import { Container } from "react-bootstrap";
import ServicesAccordionTable from "../components/ServicesAccordionTable";
import ServicesInfo2 from "../components/ServicesInfo2";
import texts from "../texts";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAlertsRequest } from "../redux/actions";
import { AppState } from "../redux/types";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Button from "../components/button/Button";

const Services = () => {
    const dispatch = useDispatch();

    const currentDate = Date.now();

    useEffect(() => {
        dispatch(getAlertsRequest());
        return () => {};
    }, [dispatch]);

    const resources = useSelector((state: AppState) => state.alerts);
    const { list } = resources;

    const [play, setPlay] = useState(0);
    return (
        <>
            <helmet>
                <title>Senior tablet as a service | Easy video calls and IT support</title>
                <meta name="description" content="The Senior Tablet is an easy and ready-to-use service. No-touch video calls, fun things to do, it support.">
            </helmet>
            <container 56="" fluid="" style="{{" height:="" `calc(100vh="" -="" ${="" +="" *="" list?.filter((el)=""> {
                                // @ts-ignore
                                return (!el.start || new Date(el.start) <= currentDate) && (!el.end || new Date(el.end) >= currentDate);
                            })?.length -
                        1
                    }px)`,
                    width: "100vw",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url('/assets/images/banners/paras-tabletti-senorille.webp')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    position: "relative",
                    minHeight: 640,
                }}
                aria-label="Henkilö käyttää tablettia, jonka näytöllä näkyy erilaisia sovelluksia."
                className="img-fluid contact-pic"
            >
                <div className="global-banner-text-container">
                    <h1 className="global-banner-title global-white">{texts[9].text1}</h1>
                    <p className="global-banner-text global-white">
                        {texts[9].text2}
                       <br/>
                        {texts[9].text3}
                    </p>
                    <link to="/osta/senioritabletti" style="{{" marginTop:="" "auto"="" }}="">
                        <button secondary="" emphasized="">
                            Buy a senior tablet
                        </button>
                    
                </div>
            </container>
            <container fluid="" className="my-3 services-container services-container-80" style="{{" minWidth:="" "min(900px,="" 100%)"="" }}="">
                <h1 className="services-header">
                    <strong>The monthly fee</strong> for the tablet service <strong data-wg-splitted>includes all</strong>
                </h1>
                <servicesinfo2></servicesinfo2>
            </container>
            <container fluid="" className="my-3 services-container service-table-container" id="palvelut">
                <div>
                    <h1 600="" className="services-header" style="{{" marginBottom:="" 0,="" fontWeight:="" }}="">
                        Which service is best for the senior?
                    </h1>
                    <p className="what-is-best" id="best">
                        All services include tablet, stand, long cable, protective film, tablet and home screen editing, sim, unlimited data, video call service, initial consultation, it support , remote management, updates, security,
                        free audio books
                    </p>
                </div>
                <servicesaccordiontable></servicesaccordiontable>
                <div className="services-button-container">
                    <link to="/osta/senioritabletti">
                        <button>Buy a senior tablet</button>
                    
                    <p className="global-text text-normal text-center global-text-dark">
                        You will receive your first month of Digihappy IT support free of charge and without any restrictions. After this period, individual IT support is free of charge for 45 minutes/month Additional support costs 10 €/10 minutes, minimum charge
                        is 10 €/call. In case of a malfunction of a service or equipment related to us, the resolution is always free of charge for you. The senior's tablet comes with a free audiobook app, provided by Celia.
                        The service is for people who have a reading disability, such as a senior with impaired vision, muscle function, functional ability or memory. Digihappy will advise you on how to access the service and get your own ID from the library.
                    </p>
                    <button onClick="{()" ==""> setPlay(1)}>View video</button>
                    <div className="global-video-wrapper">
                        <iframe 16="" src="{`https://www.youtube.com/embed/U3AGXnauUQg?autoplay=${play}`}" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; autoplay" allowFullScreen="" title="Digihappy Senior Tablets" style="{{" margin:="" "0="" auto",="" width:="" "100%",="" height:="" border:="" "none",="" aspectRatio:="" 9,="" }}=""></iframe>
                    </div>
                </div>
            </container>
        </>
    );
};

export default Services;
