import { Link } from 'react-router-dom';
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import Loader from './Loader';
import { toast } from 'react-toastify';
import { useState } from 'react';

const LoginForm = ({
  username,
  setUserName,
  password,
  setPassword,
  error,
  loading,
  submitHandler,
}: any) => {
  const [showPass, setShowPass] = useState(false);
  const showPassHandler = () => {
    setShowPass((current) => !current);
  };
  if (error) {
    toast.error(error, {
      hideProgressBar: true,
      position: 'bottom-center',
      theme: 'colored',
      autoClose: 2000,
    });
  }

  return (
    <>
      <container className="login-container">
        <row className="justify-content-md-right login-row">
          <col 6="" md="{{" span:="" 6,="" offset:="" }}="" className="login-col">
            {/*{error && <message variant="danger">{error}</message>}*/}
            {loading && <loader></loader>}
            <form onSubmit="{submitHandler}" className="login-form">
              <h1 className="signin">Log in</h1>
              <form.group controlId="login-username">
                <form.label className="label">Username</form.label>
                <form.control type="text" placeholder="Käyttäjänimi/sähköpostiosoite" value="{username}" onChange="{(e)" ==""> setUserName(e.target.value)}
                />
              </form.control></form.group>
              <form.group controlId="login-password">
                <form.label className="label">Password</form.label>
                <inputgroup>
                  <form.control type="{showPass" ?="" 'text'="" :="" 'password'}="" placeholder="Salasana" value="{password}" onChange="{(e)" ==""> setPassword(e.target.value)}
                  />
                  <button variant="outline-secondary shadow-none show-password-btn" onClick="{showPassHandler}">
                    <i className="{showPass" ?="" 'fas="" fa-eye-slash'="" :="" fa-eye'}=""></i>
                  </button>
                </form.control></inputgroup>
                <div className="show-password">
                  Click on the eye icon to see your password
                </div>
              </form.group>

              <button type="submit" className="login-button tallenna">
                Log in
              </button>
              <row className="forgot-password py-3">
                <col className="forgot-password">
                  <link className="forgot-password" to="/salasana/vaihda">
                    Forgot your password?
                  
                
              </row>

              {/*<row>*/}
              {/*    <col className="forgot-password">*/}
              {/*        <link className="forgot-password" to="/luo_tili">Not registered yet? Create*/}
              {/* account*/}
              {/* */}
              {/*</row>*/}
            </form>
          
        </row>
      </container>
    </>
  );
};

export default LoginForm;
